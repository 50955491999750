import Loader from 'components/Loader';
import { showImageError } from 'containers/blog/helper';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormFeedback,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import confirm from 'reactstrap-confirm';
import { api } from 'utils/axios';
import { API_BASE_URL } from 'utils/constants';
import { handleSaveAttachement, showImage, urlSlugify } from 'utils/helper';

export const AttachmentImageUploadModal = ({
  isOpen,
  toggle,
  onUpload,
  forType,
  renameOriginal = true,
  renameOriginalRaw = true,
}) => {
  const [showLoading, setShowLoading] = useState(false);
  const [imageUploadError, setImageUploadError] = useState(false);

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const filename = file.name;
      const validFilenameRegex = /^[a-zA-Z0-9-_]+$/;

      const [baseFilename, ...extensionParts] = filename.split('.');
      const extension = extensionParts.length > 0 ? '.' + extensionParts.join('.') : '';

      if (!validFilenameRegex.test(baseFilename)) {
        const updatedBaseFilename = baseFilename
          .toLowerCase()
          .replace(/[^a-zA-Z0-9-_]/g, '_')
          .replace(/^[-_]+|[-_]+$/g, '');

        const updatedFileName = updatedBaseFilename + extension;
        alert(`Invalid filename. Use this filename: ${updatedFileName}`);
        setImageUploadError(true);
        return;
      }

      setFile(file);
    }
  };

  const handleUpload = () => {
    if (file) {
      setShowLoading(true);
      const payload = {
        isAsset: true,
        renameOriginal,
        renameOriginalRaw,
      };

      const formData = new FormData();
      formData.append('file', file);

      api({
        method: 'POST',
        url: `${API_BASE_URL}/attachment/upload?config=${btoa(JSON.stringify(payload))}`,
        data: formData,
      })
        .then(async (file) => {
          const assetSavedFile = await handleSaveAttachement(file, forType);
          onUpload(assetSavedFile);
          toggle();
        })
        .catch((err) => {
          console.warn('Error uploading file:', err);
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  return (
    <>
      <Loader isActive={showLoading} />
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Upload Image</ModalHeader>
        <ModalBody className="p-4">
          <input type="file" onChange={handleFileChange} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpload} disabled={!file || imageUploadError}>
            Upload
          </Button>
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const AttachmentImageUploader = ({ formik, fieldName, label, forType }) => {
  const [imageUploadedModal, setImageUploadedModal] = useState(false);
  toast.configure();

  const fieldObj = formik?.values[fieldName];
  const IMAGE_TYPE = 'thumbnailMedium';

  const imageURL = fieldObj?.[IMAGE_TYPE]
    ? showImage(fieldObj?.[IMAGE_TYPE])
    : fieldObj?.original
    ? showImage(fieldObj?.original)
    : fieldObj?.url;

  const toggleImageUploadedModal = () => {
    setImageUploadedModal(!imageUploadedModal);
  };

  const handleRemove = async () => {
    const result = await confirm({
      title: 'Remove Image',
      message: 'Are you sure, you want to remove this image?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      formik.setFieldValue(fieldName, '');
    }
  };

  return (
    <Card className="border">
      <CardBody>
        <div>
          <CardTitle>{label}</CardTitle>

          {isEmpty(imageURL) && (
            <Button className="w-100 py-3 mt-2" outline color="dark" onClick={toggleImageUploadedModal}>
              Add Image
            </Button>
          )}

          {!isEmpty(fieldObj) && (
            <div className="mt-4 row">
              <div className={`col-md-${fieldName === 'ogImage' ? '6' : '12'}`}>
                <a
                  onClick={() => {
                    toggleModal();
                  }}>
                  <img
                    style={{
                      width: '-webkit-fill-available',
                    }}
                    className="mb-3 p-2"
                    src={imageURL}
                    onError={(e) => {
                      showImageError(e);
                    }}
                  />
                  <p className="mb-3">{fieldObj?.name}</p>
                </a>
                <Button color="danger" className="w-100" onClick={handleRemove}>
                  Remove
                </Button>
              </div>
            </div>
          )}

          <AttachmentImageUploadModal
            isOpen={imageUploadedModal}
            toggle={toggleImageUploadedModal}
            forType={forType}
            onUpload={(file) => {
              toggleImageUploadedModal();
              formik.setFieldValue(fieldName, file);
            }}
          />

          <FormFeedback className="d-block">{formik.errors[fieldName]}</FormFeedback>
        </div>
      </CardBody>
    </Card>
  );
};

export default AttachmentImageUploader;
