import AuthService from 'common/auth.service';
import { userSelector } from 'common/globalComponents/selectors';
import { convertEditorBlocksToHTML } from 'components/ContentCompiler';
import FaqCard from 'components/FaqCard';
import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { useFormik } from 'formik';
import useRequest from 'hooks/useRequest';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle, Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import confirm from 'reactstrap-confirm';
import { createStructuredSelector } from 'reselect';
import { API_BASE_URL, LANGUAGE_ARRAY_WITH_STRING, MODULE_TYPE } from 'utils/constants';
import { getCurrentUserData, getTranslationLabel, printDate, titleCaseIfExists, urlSlugify } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  BLOG_STATUS_ENUM,
  checkCustomListDataIsExist,
  checkUserPermission,
  createTreeStructure,
  hasAdminPermission,
  hasDeletePermission,
  hasPublishPermission,
  isObjectEmpty,
} from '../helper';
import AssetImageUploader from './components/AssetImageUploader';
import BlogCareerGuidance from './components/BlogCareerGuidance';
import BlogListData from './components/BlogListData';
import SeoMetaData from './components/SeoMetaData';
import ContentEditor from './editor';
import Sticky from 'react-stickynode';
import { isBrowser } from 'react-device-detect';
import { useRouter } from 'hooks/useRouter';

const stateSelector = createStructuredSelector({
  loggedInUser: userSelector,
});

const UpdateBlog = () => {
  toast.configure();
  const { loggedInUser } = useSelector(stateSelector);

  const router = useRouter();
  const history = useHistory();
  const { id: blogId } = useParams();

  const { stgURL } = getCurrentUserData();

  const editorInstance = useRef(null);

  const auth = new AuthService();
  const source = auth.getAppSourceCookie();

  const [articleUser, setArticleUser] = useState({});

  useEffect(() => {
    if (!isEmpty(loggedInUser)) {
      setArticleUser(checkUserPermission(loggedInUser, 'article'));
    } else {
      setArticleUser({});
    }
  }, [loggedInUser]);

  const [customData, setCustomData] = useState({});
  const [blogdetails, setBlogdetails] = useState();
  const [allCategoriesList, setAllCategoriesList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [isShowListData, setIsShowListData] = useState(false);

  // career guidance
  const [isShowCareerGuidance, setIsShowCareerGuidance] = useState(false);
  const [careerGuidance, setCareerGuidance] = useState({
    salary: '',
    stream: '',
    courses: '',
    // similar_courses: false,
    eligibility: '',
    introduction: '',
    institutes_universities: '',
  });

  const isContentWrapperBlank = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const contentWrapper = doc.querySelector('.content-wrapper');
    return contentWrapper && contentWrapper.children.length === 0;
  };

  const [getBlogRequest, { loading: getBlogLoading }] = useRequest({
    url: `/article/admin/${blogId}`,
    method: 'GET',
    onSuccess: async (data) => {
      setBlogdetails(data);

      if (!editorInstance.current || !editorInstance.current.isReady) {
        return;
      }

      await editorInstance.current.isReady;
      if (!isEmpty(data?.contentJSON)) {
        await editorInstance.current.blocks.render({ blocks: data.contentJSON?.blocks });
      } else if (data?.content) {
        const content = data.content.replaceAll('/n', ' ');

        if (!isContentWrapperBlank(content)) {
          await editorInstance.current.blocks.renderFromHTML(content);
        }
      }
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addBlogRequest, { loading: addBlogLoading }] = useRequest({
    url: `/article/admin`,
    method: 'POST',
    onSuccess: (data) => {
      toast.success(`Success! Blog ( ${data?.id} ) has been added.`);
      if (blogId) {
        window.location.reload();
      } else {
        history.replace(SMKLinks.BLOG_LIST);
      }
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [updateBlogRequest, { loading: updateBlogLoading }] = useRequest({
    url: `/article/admin/${blogId}`,
    method: 'PUT',
    onSuccess: (data) => {
      toast.success(`Success! Category ( ${data?.id} ) has been updated.`);
      window.location.reload();
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const formikInitialValues = () => {
    return {
      title: '',
      slug: '',
      contentJSON: {},
      language: LANGUAGE_ARRAY_WITH_STRING[0]?.value || '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      ogImage: {},
      primaryCategory: {},
      translations: '',
      categories: [],
      image: {},
      author: {},
      tags: '',
      isMedicallyReviewed: false,
      isExpertInvolved: router?.query?.isExpertInvolved ? true : false,
      isFeatured: false,
      medicalReviewer: '',
      expertInvolved: '',
      faqs: {},
      status: BLOG_STATUS_ENUM.DRAFT,
      active: false,
      deleted: false,
    };
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: formikInitialValues(),
    onSubmit: async (values) => {
      const isSuperAdmin = !isEmpty(loggedInUser?.roles) && loggedInUser?.roles?.some((e) => e?.id === 1);

      if (values?.status !== BLOG_STATUS_ENUM.DRAFT) {
        if (isEmpty(values?.primaryCategory)) {
          toast.error('Please select primary category');
          return '';
        }
        if (isEmpty(values?.categories)) {
          toast.error('Please select categories');
          return '';
        }

        if (!isSuperAdmin) {
          if (isEmpty(values?.image)) {
            toast.error('Please select image');
            return '';
          }
          if (isEmpty(values?.ogImage)) {
            toast.error('Please select og image');
            return '';
          }
        }
      }

      if (values?.status === BLOG_STATUS_ENUM.PUBLISHED) {
        if (isEmpty(values?.author)) {
          toast.error('Please select author');
          return '';
        }
      }

      const updatedContent = await editorInstance.current.save();
      const updatedHTMLContent = convertEditorBlocksToHTML(updatedContent);

      const data = {
        ...values,
        active: values?.active,
        deleted: values?.deleted,
        content: updatedHTMLContent,
        contentJSON: updatedContent,
        slug: !isEmpty(values?.slug) ? values?.slug : urlSlugify(values?.title),
        authorId: !isEmpty(values?.author) ? values?.author?.id : null,
        medicalReviewerId:
          !isEmpty(values?.medicalReviewer) && values?.isMedicallyReviewed ? values?.medicalReviewer?.id : null,
        expertInvolvedId:
          !isEmpty(values?.expertInvolved) && values?.isExpertInvolved ? values?.expertInvolved?.id : null,
        categories: !isEmpty(values?.categories) ? values?.categories?.map((e) => ({ id: e?.id })) : [],
        tags: !isEmpty(values?.tags) ? values?.tags : null,
        ...(!isEmpty(values?.primaryCategory)
          ? { primaryCategoryId: values?.primaryCategory?.id, primaryCategory: undefined }
          : { primaryCategoryId: null }),
        ...(!isEmpty(values?.image)
          ? {
              imageId: values?.image?.id,
              image: undefined,
            }
          : { imageId: null }),
        seo: {
          title: !isEmpty(values?.seoTitle) ? values?.seoTitle : values?.title,
          description: !isEmpty(values?.seoDescription) ? values?.seoDescription : '',
          keywords: !isEmpty(values?.seoKeywords) ? values?.seoKeywords?.map((e) => e?.value).join(', ') : '',
          ...(!isEmpty(values?.ogImage)
            ? {
                ogImageId: values?.ogImage?.id,
                ogImage: undefined,
              }
            : { ogImageId: null }),
        },
        ...(checkCustomListDataIsExist(customData) || !isObjectEmpty(customData?.careerGuidance) ? { customData } : {}),
        translations: !isEmpty(values?.translations) ? [values?.translations?.id] : [],
        faqs: !isEmpty(values?.faqs) ? values?.faqs : [],
        seoTitle: undefined,
        seoDescription: undefined,
        seoKeywords: undefined,
        author: undefined,
        medicalReviewer: undefined,
        expertInvolved: undefined,
        ogImage: undefined,
        createdById: values?.createdBy?.id ? values?.createdBy?.id : loggedInUser?.id,
        status: values?.status,
      };

      isEmpty(blogId)
        ? addBlogRequest({
            body: {
              ...data,
            },
          })
        : updateBlogRequest({
            body: {
              ...data,
            },
          });
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required('Please provide title')
        .max(700, 'Title can have a maximum of 700 characters'),
      // categories: Yup.array().min(1, 'Please provide at least one category'),
      // primaryCategory: isNonEmptyObject('Please provide primary category'),
      status: Yup.string().required('Please provide status'),
    }),
  });

  const handleSetBlogValues = () => {
    formik.setValues({
      title: blogdetails?.title || '',
      slug: blogdetails?.slug || '',
      contentJSON: blogdetails?.contentJSON || {},
      language: blogdetails?.language || LANGUAGE_ARRAY_WITH_STRING[0]?.value,
      primaryCategory: !isEmpty(blogdetails?.primaryCategory)
        ? { label: blogdetails?.primaryCategory?.name, value: blogdetails?.primaryCategory?.id }
        : {},
      seoTitle: blogdetails?.seo?.title || '',
      seoDescription: blogdetails?.seo?.description || '',
      seoKeywords: !isEmpty(blogdetails?.seo?.keywords)
        ? blogdetails?.seo?.keywords?.split(', ')?.map((e) => ({
            label: e,
            value: e,
          }))
        : '',
      ogImage: blogdetails?.seo?.ogImage || {},
      image: blogdetails?.image || {},
      translations: !isEmpty(blogdetails?.translations) ? blogdetails?.translations[0] : {},
      categories:
        blogdetails?.categories?.map((e) => ({
          id: e?.id,
        })) || [],
      author: !isEmpty(blogdetails?.author)
        ? {
            label: blogdetails?.author?.displayName,
            value: blogdetails?.author?.id,
            id: blogdetails?.author?.id,
          }
        : {},
      medicalReviewer: !isEmpty(blogdetails?.medicalReviewer)
        ? {
            label: blogdetails?.medicalReviewer?.displayName,
            value: blogdetails?.medicalReviewer?.id,
            id: blogdetails?.medicalReviewer?.id,
          }
        : {},
      expertInvolved: !isEmpty(blogdetails?.expertInvolved)
        ? {
            label: blogdetails?.expertInvolved?.displayName,
            value: blogdetails?.expertInvolved?.id,
            id: blogdetails?.expertInvolved?.id,
          }
        : {},
      tags: !isEmpty(blogdetails?.tags)
        ? blogdetails?.tags?.map((e) => ({
            ...e,
            label: e?.name,
            value: e?.id,
          }))
        : [],
      faqs: blogdetails?.faqs || {},
      isMedicallyReviewed: blogdetails?.isMedicallyReviewed || false,
      isExpertInvolved: blogdetails?.isExpertInvolved || false,
      isFeatured: blogdetails?.isFeatured || false,
      status: blogdetails?.status || '',
      createdBy: !isEmpty(blogdetails?.createdBy)
        ? {
            ...blogdetails?.createdBy,
            label: blogdetails?.createdBy?.displayName,
            value: blogdetails?.createdBy?.id,
          }
        : '',
    });
  };

  useEffect(() => {
    if (blogId) {
      getBlogRequest();
    }
  }, [blogId]);

  useEffect(() => {
    if (blogdetails) {
      handleSetBlogValues();
    }
  }, [blogdetails]);

  const [getCategoriesListRequest, { loading: getCategoriesListLoading }] = useRequest({
    url: `/article/admin/categories/search`,
    method: 'POST',
    onSuccess: (data) => {
      const categoryTree = createTreeStructure(data?.results, 'parentCategoryId');
      setCategoriesList(categoryTree);
      setAllCategoriesList(data?.results);
    },
  });

  const renderCategories = (categories) => {
    const handleCheckboxChange = (categoryId) => {
      const updatedCategories = [...(formik.values.categories || [])];
      const index = updatedCategories.findIndex((category) => category.id === categoryId);

      if (index > -1) {
        updatedCategories.splice(index, 1);
      } else {
        updatedCategories.push({ id: categoryId });
      }

      formik.setFieldValue('categories', updatedCategories);
    };

    return (
      <ul style={{ listStyle: 'none' }}>
        {!isEmpty(categories) &&
          categories?.map((category) => (
            <li key={category.id}>
              <input
                className="me-2"
                type="checkbox"
                id={`category-${category.slug}`}
                checked={
                  !isEmpty(formik.values.categories)
                    ? formik.values.categories?.some((e) => e.id === category.id)
                    : false
                }
                onChange={() => handleCheckboxChange(category.id)}
              />
              <label htmlFor={`category-${category.slug}`} dangerouslySetInnerHTML={{ __html: category?.name }} />
              {category.subcategories.length > 0 && renderCategories(category.subcategories)}
            </li>
          ))}
      </ul>
    );
  };

  useEffect(() => {
    if (!isEmpty(formik.values.language)) {
      getCategoriesListRequest({
        body: {
          page: 1,
          size: 10000,
          sortBy: 'name',
          sortOrder: 1,
          language: formik.values.language,
        },
      });
    }
  }, [formik.values.language]);

  const handleShowPrimaryCategories = () => {
    return categoriesList
      ?.filter((category) => {
        const isCategoryOrDescendantSelected = (cat) => {
          const isSelected =
            !isEmpty(formik.values.categories) &&
            formik.values.categories?.some((selectedCategory) => selectedCategory?.id === cat?.id);

          const areSubcategoriesSelected =
            !isEmpty(cat?.subcategories) &&
            cat?.subcategories?.some((subCategory) => isCategoryOrDescendantSelected(subCategory));

          return isSelected || areSubcategoriesSelected;
        };

        return isCategoryOrDescendantSelected(category);
      })
      ?.map((e) => ({
        ...e,
        label: e?.name,
        value: e.id,
      }));
  };

  useEffect(() => {
    if (!isEmpty(allCategoriesList) && !isEmpty(formik.values.categories)) {
      // show career guidance logix
      const hasMatchingCategory = (slug) => {
        const matchedCategory = allCategoriesList.find((category) => category?.slug === slug);
        return matchedCategory && formik.values.categories.some((category) => category.id === matchedCategory.id);
      };
      const educationCateogry = hasMatchingCategory('education');
      const careerOptionsCategory = hasMatchingCategory('career-options');

      setIsShowCareerGuidance(educationCateogry && careerOptionsCategory);

      // show list data logix
      let showList = false;

      if (source === 'schoolmykids') {
        showList = formik.values.categories.some(
          (cat) => cat.id === allCategoriesList.find((allCat) => allCat?.slug === 'schools')?.id
        );
      }
      if (source === 'theparentz') {
        showList = formik.values.categories.some(
          (cat) => cat.id === allCategoriesList.find((allCat) => allCat?.slug === 'doctors')?.id
        );
      }
      setIsShowListData(showList);
    }
  }, [allCategoriesList, formik.values.categories, source]);

  // updating career guidance
  useEffect(() => {
    if (!isEmpty(careerGuidance)) {
      setCustomData((prev) => ({
        ...prev,
        careerGuidance: careerGuidance,
      }));
    }
  }, [careerGuidance]);

  useEffect(() => {
    if (!isEmpty(blogdetails?.customData?.careerGuidance)) {
      setCareerGuidance(blogdetails?.customData?.careerGuidance);
    }
  }, [blogdetails]);

  const handleChangeLanguage = async (val) => {
    const result = await confirm({
      title: `Change ${val === 'en' ? 'English' : 'Hindi'} Language`,
      message: `Are you sure, you want to change ${val === 'en' ? 'English' : 'Hindi'} language?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      formik.setFieldValue('language', val);
    }
  };

  const handleDeleteBlog = async () => {
    const result = await confirm({
      title: `Delete blog`,
      message: `Are you sure, you want to delete this blog?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      updateBlogRequest({
        body: {
          ...blogdetails,
          active: false,
          deleted: true,
          status: BLOG_STATUS_ENUM.TRASH,
        },
      });
    }
  };

  const handlePublishBlog = async () => {
    const result = await confirm({
      title: `Publish blog`,
      message: `Are you sure, you want to publish this article, please verify the title, slug and primary category before publish?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      formik.setFieldValue('status', BLOG_STATUS_ENUM.PUBLISHED);
      formik.setFieldValue('active', true);
      formik.setFieldValue('deleted', false);
      formik.handleSubmit();
    }
  };
  const handleUnPublishBlog = async () => {
    const result = await confirm({
      title: `Unpublish blog`,
      message: `Are you sure, you want to unpublish this article.`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      formik.setFieldValue('status', BLOG_STATUS_ENUM.UNPUBLISHED);
      formik.setFieldValue('active', false);
      formik.setFieldValue('deleted', false);
      formik.handleSubmit();
    }
  };

  useEffect(() => {
    const unblock = history.block((_, action) => {
      if (action === 'POP') {
        const confirmLeave = window.confirm('Are you sure you want to leave this page?');
        if (!confirmLeave) {
          return false;
        }
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history]);

  return (
    <div className="page-content add-blog-page">
      <Loader isActive={addBlogLoading || updateBlogLoading || getBlogLoading || getCategoriesListLoading} />

      <div className="mx-3">
        <Breadcrumbs title={`Blog`} breadcrumbItem={`${blogId ? 'Update' : 'Add'} Blog`} />
        <Row>
          <Col md={9}>
            <div id="article_page">
              <Card className="border mb-4">
                <CardBody>
                  <div className="form-group mb-4">
                    <Label for="title" className="form-label">
                      Title
                    </Label>
                    <div>
                      <ReactTextareaAutosize
                        className="form-control fs-2"
                        style={{ boxSizing: 'border-box' }}
                        minRows={1}
                        maxRows={10}
                        placeholder="Enter Title..."
                        value={formik.values.title}
                        onChange={(e) => formik.setFieldValue('title', e.target.value)}
                      />
                    </div>

                    {formik.touched.title && formik.errors.title && (
                      <FormFeedback className="d-block fs-6 pt-1">{formik.errors.title}</FormFeedback>
                    )}
                  </div>
                  {
                    <div className="form-group mb-4">
                      <div className="position-relative">
                        {/* use showResetRegenerate & currentSlug & nameValue to enable reset and regenerate functionality */}
                        <SlugInput
                          isDisabled={!hasPublishPermission(loggedInUser, 'article')}
                          formik={formik}
                          ID={blogdetails?.title}
                          nameValue={formik.values.title}
                          formGroupClassName="mb-0"
                          showResetRegenerate={true}
                          currentSlug={blogdetails?.slug}
                        />
                      </div>
                    </div>
                  }

                  {!isEmpty(blogdetails) && blogdetails?.active && (
                    <div className="mt-3">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${stgURL}${blogdetails?.language === 'hi' ? '/hi' : ''}/${
                          blogdetails?.primaryCategory?.slug
                        }/${blogdetails?.slug}`}>
                        {' '}
                        {`${stgURL}${blogdetails?.language === 'hi' ? '/hi' : ''}/${
                          blogdetails?.primaryCategory?.slug
                        }/${blogdetails?.slug}`}
                      </a>
                    </div>
                  )}
                </CardBody>
              </Card>

              <Card className="border">
                <CardBody>
                  <ContentEditor editorInstance={editorInstance} forType={MODULE_TYPE.ARTICLE} />
                </CardBody>
              </Card>

              <SeoMetaData formik={formik} forType={MODULE_TYPE.ARTICLE} />

              {isShowListData && <BlogListData setCustomData={setCustomData} blogdetails={blogdetails} />}

              {isShowCareerGuidance && (
                <BlogCareerGuidance careerGuidance={careerGuidance} setCareerGuidance={setCareerGuidance} />
              )}

              <FaqCard formik={formik} apiDetails={blogdetails} />
            </div>
          </Col>

          <Col md={3}>
            {isBrowser && (
              <Sticky enabled={isBrowser} top={100} bottomBoundary={`#article_page`} innerZ={99}>
                <div>
                  <Card className="border">
                    <CardBody>
                      {articleUser?.status?.filter((e) => e?.show)?.find((e) => e?.value === blogdetails?.status) && (
                        <FormGroup className="mb-4">
                          <Label for="status" className="form-label">
                            Status
                          </Label>
                          <Select
                            placeholder="Select status..."
                            value={
                              formik.values.status
                                ? articleUser?.status?.find((e) => e?.value === formik.values.status)
                                : null
                            }
                            onChange={(res) => {
                              formik.setFieldValue('status', res?.value);
                              formik.setFieldValue('active', res?.keys?.active);
                              formik.setFieldValue('deleted', res?.keys?.deleted);
                            }}
                            options={articleUser?.status?.filter((e) => e?.show === true)}
                          />
                          {formik.touched.status && formik.errors.status && (
                            <FormFeedback className="d-block">{formik.errors.status}</FormFeedback>
                          )}
                        </FormGroup>
                      )}

                      <div>
                        {(isEmpty(blogdetails) ||
                          blogdetails?.editable ||
                          (moment().diff(moment(blogdetails?.updatedDate), 'hours') < 24 &&
                            hasPublishPermission(loggedInUser, 'article')) ||
                          articleUser?.role === 'role_admin') && (
                          <Button
                            className="w-100 h5 mb-3"
                            color="primary"
                            onClick={() => {
                              formik.handleSubmit();
                            }}>
                            {!isEmpty(blogdetails) ? 'Update' : 'Save'}
                          </Button>
                        )}

                        {articleUser?.status?.find((e) => e?.nextStep === true) && (
                          <Button
                            className="w-100 mb-3"
                            color="info"
                            onClick={() => {
                              const obj = articleUser?.status?.find((e) => e?.nextStep === true);
                              if (!isEmpty(obj)) {
                                formik.setFieldValue('status', [obj?.value]);
                                formik.setFieldValue('active', obj?.keys?.active);
                                formik.setFieldValue('deleted', obj?.keys?.deleted);
                              }

                              formik.handleSubmit();
                            }}>
                            {articleUser?.status?.find((e) => e?.nextStep === true)?.label}
                          </Button>
                        )}

                        {!isEmpty(blogdetails) && (
                          <div className="mb-3">
                            <a
                              className="btn btn-light w-100 fs-6"
                              target="_blank"
                              rel="noreferrer"
                              href={`${stgURL}/${
                                source === 'theparentz' ? 'parenting' : source === 'mediwatcher' ? 'wellness' : 'blog'
                              }/preview/${blogdetails?.id}?token=${auth.getSessionCookie()}`}>
                              Preview <i className="bx bx-link-external ms-1" />
                            </a>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                  {!isEmpty(blogdetails) && (
                    <Card>
                      <CardBody>
                        {!articleUser?.status?.filter((e) => e?.show)?.find((e) => e?.value === blogdetails?.status) &&
                          !isEmpty(blogdetails?.status) && (
                            <h5 className="mb-0">
                              <small>Current Status :</small> <b>{titleCaseIfExists(blogdetails?.status)}</b>
                            </h5>
                          )}
                        <h6 className="mt-2 mb-0">
                          editable: <b>{blogdetails?.editable ? 'Yes' : 'No'} </b>
                        </h6>
                        <h6 className="mt-2 mb-0">
                          CreatedBy: <b>{blogdetails?.createdBy?.displayName}</b>{' '}
                        </h6>
                        <h6 className="mt-2 mb-0">
                          Created Date: <b>{printDate(blogdetails?.createdDate)}</b>{' '}
                        </h6>
                        <h6 className="mt-2 mb-0">
                          Modified Date: <b>{printDate(blogdetails?.updatedDate)} </b>
                        </h6>
                        <h6 className="mt-2 mb-2">
                          Current Version: <b>{blogdetails?.version} </b>
                        </h6>
                        <a
                          target="_blank"
                          href={`/blog/${blogdetails?.id}/previous-version-view/${blogdetails?.version}`}>
                          View previous version
                        </a>
                      </CardBody>
                    </Card>
                  )}
                  {(hasPublishPermission(loggedInUser, 'article') || hasDeletePermission(loggedInUser, 'article')) && (
                    <Card>
                      <CardBody>
                        {hasPublishPermission(loggedInUser, 'article') && !blogdetails?.active && (
                          <Button className="w-100 h5" color="success" onClick={handlePublishBlog}>
                            Publish
                          </Button>
                        )}

                        {hasAdminPermission(loggedInUser, 'article') && blogdetails?.active && (
                          <Button outline className="w-100 mt-3 h6" color="primary" onClick={handleUnPublishBlog}>
                            Unpublish
                          </Button>
                        )}

                        {hasDeletePermission(loggedInUser, 'article') && !blogdetails?.deleted && (
                          <Button outline className="w-100 mt-3 h6" color="danger" onClick={handleDeleteBlog}>
                            Move to Trash
                          </Button>
                        )}
                      </CardBody>
                    </Card>
                  )}
                  {hasPublishPermission(loggedInUser, 'article') && (
                    <Card className="border">
                      <CardBody>
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <Label for="createdBy" className="form-label">
                                CreatedBy
                              </Label>
                              <SmkAsyncSelect
                                isClearable={false}
                                acceptedKey={'displayName'}
                                acceptedValue={'id'}
                                placeholder="Type createdBy name.."
                                onChange={(res) => {
                                  formik.setFieldValue('createdBy', res);
                                }}
                                fetchUrl={`${API_BASE_URL}/user/admin/search-article-authors`}
                                apiMethod={'GET'}
                                searchKeyName="name"
                                isQueryParam={true}
                                value={!isEmpty(formik?.values?.createdBy) ? formik?.values?.createdBy : ''}
                              />
                              <FormFeedback className="d-block">{formik.errors.createdBy}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                  <Card className="border">
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="author" className="form-label">
                              Author
                            </Label>
                            <SmkAsyncSelect
                              isDisabled={!hasPublishPermission(loggedInUser, 'article')}
                              isClearable={false}
                              acceptedKey={'displayName'}
                              acceptedValue={'id'}
                              placeholder="Type author name.."
                              onChange={(res) => {
                                formik.setFieldValue('author', res);
                              }}
                              fetchUrl={`${API_BASE_URL}/user/admin/search-article-authors`}
                              apiMethod={'GET'}
                              searchKeyName="name"
                              isQueryParam={true}
                              value={!isEmpty(formik?.values?.author) ? formik?.values?.author : ''}
                            />
                            <FormFeedback className="d-block">{formik.errors.author}</FormFeedback>
                          </FormGroup>
                        </Col>

                        {!isEmpty(blogdetails?.createdBy) && (
                          <Col md={12} className="mt-4">
                            <FormGroup className="mb-0">
                              <Label for="author" className="form-label">
                                CreatedBy
                              </Label>
                              <h6 className="mb-0 bg-light border py-2 px-3 w-100 rounded-2">
                                {blogdetails?.createdBy?.displayName}
                              </h6>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="border">
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="categories" className="form-label">
                              Categories
                            </Label>

                            {!isEmpty(categoriesList) && (
                              <div className="categories-list overflow-auto" style={{ maxHeight: 200 }}>
                                {renderCategories(categoriesList)}
                              </div>
                            )}
                            {formik.touched.categories && formik.errors.categories && (
                              <FormFeedback className="d-block fs-6 pt-1">{formik.errors.categories}</FormFeedback>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="primaryCategory" className="form-label">
                              Primary category
                            </Label>

                            <Select
                              placeholder="Select category..."
                              value={!isEmpty(formik.values.primaryCategory) ? formik.values.primaryCategory : null}
                              onChange={(res) => formik.setFieldValue('primaryCategory', res)}
                              options={!isEmpty(categoriesList) ? handleShowPrimaryCategories() : []}
                            />
                            {formik.touched.primaryCategory && formik.errors.primaryCategory && (
                              <FormFeedback className="d-block fs-6 pt-1">{formik.errors.primaryCategory}</FormFeedback>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="tags" className="form-label">
                              Tags
                            </Label>
                            <SmkAsyncSelect
                              creatable={false}
                              multi={true}
                              acceptedKey={'name'}
                              acceptedValue={'name'}
                              placeholder="Type tag name.."
                              onChange={(res) => {
                                formik.setFieldValue('tags', res);
                              }}
                              fetchUrl={`${API_BASE_URL}/article/admin/tags/search`}
                              filters={{
                                autoComplete: true,
                                page: 1,
                                size: 100,
                                sortBy: 'id',
                                sortOrder: 0,
                                language: formik.values.language,
                                appSource: source,
                              }}
                              searchKeyName="name"
                              value={!isEmpty(formik?.values?.tags) ? formik?.values?.tags : ''}
                            />
                            <FormFeedback className="d-block">{formik.errors.tags}</FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="border">
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <CardTitle className="mb-3">Language </CardTitle>
                          {LANGUAGE_ARRAY_WITH_STRING?.map((option, i) => (
                            <div key={`language_${i}`} className="form-check mb-3 d-inline-block me-4">
                              <input
                                type="radio"
                                id={`language_${option?.value}`}
                                name="language"
                                className="form-check-input"
                                checked={formik.values.language === option?.value}
                                onChange={() => handleChangeLanguage(option?.value)}
                              />
                              <label className="form-check-label" htmlFor={`language_${option?.value}`}>
                                {option?.label}
                              </label>
                            </div>
                          ))}
                        </Col>
                        <Col md={12}>
                          <CardTitle className="mb-3">Translation </CardTitle>
                          <SmkAsyncSelect
                            isDisabled={
                              formik.values.language === 'hi' || !hasPublishPermission(loggedInUser, 'article')
                            }
                            acceptedKey={'title'}
                            acceptedValue={'id'}
                            placeholder="Type article name.."
                            onChange={(res) => {
                              formik.setFieldValue('translations', res);
                            }}
                            fetchUrl={`${API_BASE_URL}/article/admin/search`}
                            filters={{
                              autoComplete: true,
                              active: true,
                              page: 1,
                              size: 500,
                              sortBy: 'id',
                              sortOrder: 0,
                              language: formik.values.language === 'en' ? 'hi' : 'en',
                            }}
                            searchKeyName="name"
                            value={
                              !isEmpty(formik?.values?.translations)
                                ? {
                                    label: getTranslationLabel(formik?.values?.translations),
                                    value: formik?.values?.translations?.id,
                                  }
                                : ''
                            }
                          />

                          {!isEmpty(formik?.values?.translations) && (
                            <div className="mt-3">
                              <div className="card bg-light">
                                <div className="card-body">
                                  <a target="_blank" href={`/blog/edit/${formik?.values?.translations?.id}`}>
                                    <p className="mb-1">
                                      <span className="text-dark">Language:</span>{' '}
                                      {formik?.values?.translations?.language}
                                    </p>
                                    <p className="mb-1">
                                      <span className="text-dark">ID:</span> {formik?.values?.translations?.id}
                                    </p>
                                    <p className="mb-1">
                                      <span className="text-dark">Title:</span>{' '}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: getTranslationLabel(formik?.values?.translations),
                                        }}
                                      />
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <AssetImageUploader
                    forType={MODULE_TYPE.ARTICLE}
                    formik={formik}
                    fieldName={'image'}
                    label={'Select Image'}
                  />
                  {(hasPublishPermission(loggedInUser, 'article') ||
                    loggedInUser?.email == 'akanksha.priya@rhatechnologies.com') && (
                    <Card className="border">
                      <CardBody>
                        <Row>
                          <Col md={12}>
                            <CardTitle className="mb-3">Is Medically Reviewed</CardTitle>
                            {[true, false].map((option, i) => (
                              <div key={`is_medically_${i}`} className="form-check mb-3 d-inline-block me-4">
                                <input
                                  type="radio"
                                  id={`is_medically_${option}`}
                                  name="is_medically"
                                  className="form-check-input"
                                  checked={formik.values.isMedicallyReviewed === option}
                                  onChange={() => formik.setFieldValue('isMedicallyReviewed', option)}
                                />
                                <label className="form-check-label" htmlFor={`is_medically_${option}`}>
                                  {option ? 'Yes' : 'No'}
                                </label>
                              </div>
                            ))}
                          </Col>
                          <Col md={12}>
                            <CardTitle className="mb-3">Is Expert Involved</CardTitle>
                            {[true, false].map((option, i) => (
                              <div key={`is_expert_${i}`} className="form-check mb-3 d-inline-block me-4">
                                <input
                                  type="radio"
                                  id={`is_expert_${option}`}
                                  name="is_expert"
                                  className="form-check-input"
                                  checked={formik.values.isExpertInvolved === option}
                                  onChange={() => formik.setFieldValue('isExpertInvolved', option)}
                                />
                                <label className="form-check-label" htmlFor={`is_expert_${option}`}>
                                  {option ? 'Yes' : 'No'}
                                </label>
                              </div>
                            ))}
                          </Col>
                          <Col md={12}>
                            <CardTitle className="mb-3">is Featured </CardTitle>
                            {[true, false].map((option, i) => (
                              <div key={`is_featured_${i}`} className="form-check mb-3 d-inline-block me-4">
                                <input
                                  type="radio"
                                  id={`is_featured_${option}`}
                                  name="is_featured_"
                                  className="form-check-input"
                                  checked={formik.values.isFeatured === option}
                                  onChange={() => formik.setFieldValue('isFeatured', option)}
                                />
                                <label className="form-check-label" htmlFor={`is_featured_${option}`}>
                                  {option ? 'Yes' : 'No'}
                                </label>
                              </div>
                            ))}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                  {(formik.values.isMedicallyReviewed || formik.values.isExpertInvolved) && (
                    <Card className="border">
                      <CardBody>
                        <Row>
                          {formik.values.isMedicallyReviewed && (
                            <Col md={12}>
                              <FormGroup className="mb-4">
                                <Label for="medicalReviewer" className="form-label">
                                  Medical Reviewer
                                </Label>
                                <SmkAsyncSelect
                                  isClearable={false}
                                  acceptedKey={'displayName'}
                                  acceptedValue={'id'}
                                  placeholder="Type user name.."
                                  onChange={(res) => {
                                    formik.setFieldValue('medicalReviewer', res);
                                  }}
                                  fetchUrl={`${API_BASE_URL}/user/admin/search-article-authors`}
                                  apiMethod={'GET'}
                                  searchKeyName="name"
                                  isQueryParam={true}
                                  value={
                                    !isEmpty(formik?.values?.medicalReviewer) ? formik?.values?.medicalReviewer : ''
                                  }
                                />
                                <FormFeedback className="d-block">{formik.errors.medicalReviewer}</FormFeedback>
                              </FormGroup>
                            </Col>
                          )}
                          {formik.values.isExpertInvolved && (
                            <Col md={12}>
                              <FormGroup className="mb-4">
                                <Label for="expertInvolved" className="form-label">
                                  Expert Involved
                                </Label>
                                <SmkAsyncSelect
                                  isClearable={false}
                                  acceptedKey={'displayName'}
                                  acceptedValue={'id'}
                                  placeholder="Type user name.."
                                  onChange={(res) => {
                                    formik.setFieldValue('expertInvolved', res);
                                  }}
                                  fetchUrl={`${API_BASE_URL}/user/admin/search-article-authors`}
                                  apiMethod={'GET'}
                                  searchKeyName="name"
                                  isQueryParam={true}
                                  value={!isEmpty(formik?.values?.expertInvolved) ? formik?.values?.expertInvolved : ''}
                                />
                                <FormFeedback className="d-block">{formik.errors.expertInvolved}</FormFeedback>
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                </div>
              </Sticky>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdateBlog;
