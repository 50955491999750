import Loader from 'components/Loader';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import { API_BASE_URL, shortToFullDay } from 'utils/constants';
import { SMKLinks } from 'utils/links';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TimingsTableRows from '../../../components/TimingsTableRows';
import useRequest from '../../../hooks/useRequest';
import { buildURL } from '../../../utils/helper';

const AddUpdateClinic = () => {
  const { doctorId, id: clinicId } = useParams();
  const history = useHistory();

  toast.configure();
  const [clinicDetails, setClinicDetails] = useState();

  const defaultTimings = {
    Sun: [],
    Mon: [],
    Tue: [],
    Wed: [],
    Thu: [],
    Fri: [],
    Sat: [],
  };

  const [addRowInTimings, setAddRowInTimings] = useState(defaultTimings);

  const addRowTable = (weekDay) => {
    const data = {
      startTime: new Date().setHours(10, 0, 0, 0), // Set default start time to 10:00 AM
      endTime: new Date().setHours(18, 0, 0, 0), // Set default end time to 06:00 PM
    };
    setAddRowInTimings({
      ...addRowInTimings,
      [weekDay]: [...addRowInTimings[weekDay], data],
    });
  };

  const tableRowRemove = (index, weekDay) => {
    const dataRow = addRowInTimings[weekDay];
    dataRow.splice(index, 1);
    setAddRowInTimings({
      ...addRowInTimings,
      [weekDay]: dataRow,
    });
  };

  const [getDoctorClinicDetails, { loading: getDoctorClinicLoading }] = useRequest({
    url: `/healthcare/doctor/admin/locations/${clinicId}`,
    method: 'GET',
    onSuccess: (data) => {
      if (!isEmpty(data?.timings)) {
        const timingsData = reverseTimingsFormat(data?.timings);
        setAddRowInTimings({
          ...defaultTimings,
          ...timingsData,
        });
      }
      setClinicDetails(data);
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  const [addDoctorClinicRequest, { loading: addDoctorClinicLoading }] = useRequest({
    url: `/healthcare/doctor/admin/${doctorId}/location`,
    method: 'POST',
    onSuccess: () => {
      const url = buildURL(SMKLinks.DOCTOR_DETAILS, {
        id: doctorId,
      });
      history.replace(url);
      toast.success('Success! Clinic has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateDoctorClinicRequest, { loading: updateDoctorClinicLoading }] = useRequest({
    url: `/healthcare/doctor/admin/${doctorId}/location/${clinicId}`,
    method: 'PUT',
    onSuccess: () => {
      window.location.reload();
      toast.success('Success! Clinic has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => {
    return {
      active: clinicDetails?.active,
      deleted: clinicDetails?.deleted,
      healthcareFacility: !isEmpty(clinicDetails?.healthcareFacility)
        ? {
            label: clinicDetails?.healthcareFacility?.name,
            value: clinicDetails?.healthcareFacilityId,
          }
        : null,
      defaultCharges: clinicDetails?.defaultCharges,
      notes: clinicDetails?.notes,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    healthcareFacility: '',
    timings: '',
    defaultCharges: '',
    registrationFee: '',
    firstVisit: '',
    followUpVisit: '',
    notes: '',
    registrationFeeCurrency: { label: 'INR', value: 'inr' },
    firstVisitCurrency: { label: 'INR', value: 'inr' },
    followUpVisitCurrency: { label: 'INR', value: 'inr' },
  });

  const reverseTimingsFormat = (data) => {
    const reversedData = {};

    data.forEach(({ days, timing }) => {
      const dayRows = timing.map((timeRange) => {
        const [startTime, endTime] = timeRange.split(' - ');

        const parseTime = (timeStr) => {
          const [time, modifier] = timeStr.trim().split(' ');
          let [hours, minutes] = time.split(':').map(Number);

          if (modifier === 'PM' && hours < 12) hours += 12;
          if (modifier === 'AM' && hours === 12) hours -= 12;

          return new Date(1970, 0, 1, hours, minutes);
        };

        const start = parseTime(startTime);
        const end = parseTime(endTime);

        return {
          startTime: start.toISOString(),
          endTime: end.toISOString(),
        };
      });

      reversedData[days] = dayRows;
    });

    return reversedData;
  };

  const timingsFormat = (data) => {
    const timings = [];

    Object.keys(data).forEach((weekDay) => {
      const dayRows = data[weekDay];
      if (dayRows.length > 0) {
        const timing = dayRows.map((row) => {
          const start = new Date(row.startTime);
          const end = new Date(row.endTime);

          let startTime = start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
          let endTime = end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

          startTime = startTime.replace('am', 'AM').replace('pm', 'PM');
          endTime = endTime.replace('am', 'AM').replace('pm', 'PM');

          return `${startTime} - ${endTime}`;
        });

        timings.push({
          days: weekDay,
          timing: timing,
        });
      }
    });

    return timings;
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(clinicDetails) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: (values) => {
      const timings = timingsFormat(addRowInTimings);

      const payload = {
        active: values?.active,
        deleted: values?.deleted,
        healthcareFacilityId: values?.healthcareFacility?.value,
        defaultCharges: values?.defaultCharges,
        notes: values?.notes,
        timings,
        doctorId,
      };

      isEmpty(clinicDetails)
        ? addDoctorClinicRequest({
            body: payload,
          })
        : updateDoctorClinicRequest({
            body: payload,
          });
    },
    validationSchema: Yup.object().shape({
      healthcareFacility: Yup.object().required('Please provide Healthcare facility'),
      // defaultCharges: Yup.string().required('Please provide defaultCharges'),
      // notes: Yup.string().required('Please provide notes'),
    }),
  });

  useEffect(() => {
    if (clinicId) {
      getDoctorClinicDetails();
    }
  }, [clinicId]);

  return (
    <div className="page-content">
      <Container fluid>
        <Loader isActive={updateDoctorClinicLoading || addDoctorClinicLoading || getDoctorClinicLoading} />
        <Breadcrumbs title="Clinic" breadcrumbItem={`${isEmpty(clinicId) ? 'Add' : 'Update'} Clinic`} />
        <Form onSubmit={formik.handleSubmit}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <FormGroup className="mb-4">
                      <Label className="form-label">
                        Healthcare Facility <span className="text-danger">*</span>{' '}
                      </Label>
                      <SmkAsyncSelect
                        acceptedKey={'name'}
                        acceptedValue={'id'}
                        searchKeyName="name"
                        placeholder="Type healthcare name.."
                        fetchUrl={`${API_BASE_URL}/healthcare/doctor/admin/healthcareFacility/search`}
                        filters={{
                          autoComplete: true,
                          page: 1,
                          size: 100,
                          sortBy: 'name',
                          sortOrder: 1,
                        }}
                        onChange={(res) => {
                          formik.setFieldValue('healthcareFacility', {
                            label: res?.name,
                            value: res?.id,
                          });
                        }}
                        value={formik.values.healthcareFacility}
                      />
                      <FormFeedback className="d-block">{formik.errors.healthcareFacility}</FormFeedback>
                    </FormGroup>

                    <FormGroup className="mb-4">
                      <Label className="form-label">Default Charges</Label>
                      <Input
                        id="defaultCharges"
                        type="text"
                        className="form-control"
                        placeholder="Default Charges..."
                        invalid={!!(formik.touched.defaultCharges && formik.errors.defaultCharges)}
                        {...formik.getFieldProps('defaultCharges')}
                        error={!isEmpty(formik.errors.defaultCharges)}
                      />
                      <FormFeedback className="d-block">{formik.errors.defaultCharges}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <Label className="form-label">Notes</Label>
                      <Input
                        id="notes"
                        type="textarea"
                        rows="3"
                        className="form-control"
                        placeholder="Notes..."
                        invalid={!!(formik.touched.notes && formik.errors.notes)}
                        {...formik.getFieldProps('notes')}
                        error={!isEmpty(formik.errors.notes)}
                      />
                      <FormFeedback className="d-block">{formik.errors.notes}</FormFeedback>
                    </FormGroup>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div className="mb-4">
                      <h5 className="mb-0">
                        Timings <span className="text-danger">*</span>
                      </h5>
                      <FormFeedback className="d-block mt-2">{formik.errors.timings}</FormFeedback>
                    </div>
                    <Table className="table-nowrap mb-0 table-white">
                      <tbody>
                        {Object.keys(addRowInTimings).map((weekDay) => (
                          <React.Fragment key={weekDay}>
                            <tr>
                              <td>{shortToFullDay[weekDay]}</td>
                              <td colSpan={6}></td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {addRowInTimings[weekDay]?.length < 3 && (
                                  <button
                                    type="button"
                                    onClick={() => addRowTable(weekDay)}
                                    className="btn btn-light rounded-circle">
                                    <i className="bx bx-plus" />
                                  </button>
                                )}
                              </td>
                            </tr>
                            <TimingsTableRows
                              rows={addRowInTimings[weekDay]}
                              tableRowRemove={tableRowRemove}
                              addRowInTimings={addRowInTimings}
                              setAddRowInTimings={setAddRowInTimings}
                              weekDay={weekDay}
                            />
                          </React.Fragment>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>

                {/* <Card>
                  <CardBody>
                    <h5 className="mb-4">Charges</h5>
                    <Row className="my-4">
                      <Col md={6} className="d-flex ">
                        <Label className="form-label mb-0">Registration Fees</Label>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={4}>
                            <Select
                              id="registrationFeeCurrency"
                              options={CURRENCY_ARRAY}
                              value={formik.values.registrationFeeCurrency}
                              onChange={(value) => formik.setFieldValue('registrationFeeCurrency', value)}
                            />
                          </Col>
                          <Col md={8}>
                            <Input
                              id="registrationFee"
                              type="number"
                              className="form-control"
                              placeholder="Amount..."
                              invalid={!!(formik.touched.registrationFee && formik.errors.registrationFee)}
                              {...formik.getFieldProps('registrationFee')}
                              error={!isEmpty(formik.errors.registrationFee)}
                            />
                            <FormFeedback className="d-block">{formik.errors.registrationFee}</FormFeedback>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col md={6} className="d-flex ">
                        <Label className="form-label mb-0">First visit</Label>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={4}>
                            <Select
                              id="firstVisitCurrency"
                              options={CURRENCY_ARRAY}
                              value={formik.values.firstVisitCurrency}
                              onChange={(value) => formik.setFieldValue('firstVisitCurrency', value)}
                            />
                          </Col>
                          <Col md={8}>
                            <Input
                              id="firstVisit"
                              type="number"
                              className="form-control"
                              placeholder="Amount..."
                              invalid={!!(formik.touched.firstVisit && formik.errors.firstVisit)}
                              {...formik.getFieldProps('firstVisit')}
                              error={!isEmpty(formik.errors.firstVisit)}
                            />
                            <FormFeedback className="d-block">{formik.errors.firstVisit}</FormFeedback>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col md={6} className="d-flex ">
                        <Label className="form-label mb-0">Follow-up visit</Label>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={4}>
                            <Select
                              id="followUpVisitCurrency"
                              options={CURRENCY_ARRAY}
                              value={formik.values.followUpVisitCurrency}
                              onChange={(value) => formik.setFieldValue('followUpVisitCurrency', value)}
                            />
                          </Col>
                          <Col md={8}>
                            <Input
                              id="followUpVisit"
                              type="number"
                              className="form-control"
                              placeholder="Amount..."
                              invalid={!!(formik.touched.followUpVisit && formik.errors.followUpVisit)}
                              {...formik.getFieldProps('followUpVisit')}
                              error={!isEmpty(formik.errors.followUpVisit)}
                            />
                            <FormFeedback className="d-block">{formik.errors.followUpVisit}</FormFeedback>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col md={6} className="d-flex ">
                        <Label className="form-label mb-0">Follow-up validaity (days)</Label>
                      </Col>
                      <Col md={6}>
                        <Select
                          id="followUpValidity"
                          options={Array(60)
                            .fill('numbers')
                            ?.map((e, i) => ({
                              label: i + 1,
                              value: i + 1,
                            }))}
                          value={formik.values.followUpValidity}
                          onChange={(value) => formik.setFieldValue('followUpValidity', value)}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card> */}
              </Col>

              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="w-100 mb-3">
                      {`${isEmpty(clinicId) ? 'Add' : 'Update'}`} Clinic
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        const url = buildURL(SMKLinks.DOCTOR_DETAILS, {
                          id: doctorId,
                        });
                        history.replace(url);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    {[
                      {
                        name: 'Active',
                        value: 'active',
                      },
                      {
                        name: 'Deleted',
                        value: 'deleted',
                      },
                    ]?.map((data) => (
                      <div>
                        <CardTitle className="mb-3">{data?.name}</CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`${data?.value}_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`${data?.value}_${option}`}
                              name={data?.value}
                              className="form-check-input"
                              checked={formik.values[data?.value] === option}
                              onChange={() => formik.setFieldValue(data?.value, option)}
                            />
                            <label className="form-check-label" htmlFor={`${data?.value}_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </div>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Form>
      </Container>
    </div>
  );
};

export default AddUpdateClinic;
