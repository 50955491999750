import NestedImageUploader from 'components/NestedImageUploader';
import TextEditor from 'components/TextEditor';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, Col, Collapse, Input, Label, Row } from 'reactstrap';
import { MODULE_TYPE, STATIC_PAGE_SECTION_TYPES } from 'utils/constants';
import Select from 'react-select';
import { titleCaseIfExists } from 'utils/helper';
import CustomArticleSearch from './CustomArticleSearch';

const CollapsibleSections = ({
  sections,
  handleUpdateSection,
  handleRemoveSection,
  handleAddItem,
  handleRemoveItem,
  handleUpdateItems,
}) => {
  const [showSectionSitemap, setShowSectionSitemap] = useState(false);

  const [openSectionIndex, setOpenSectionIndex] = useState(null);
  const [openSectionItemIndex, setOpenSectionItemIndex] = useState(null);
  const [fieldVisibility, setFieldVisibility] = useState([]);
  const prevSectionsLength = useRef(sections.length);

  //   sections
  const defaultPayload = {
    subTitle: false,
    content: false,
    metadata: false,
    image: false,
    articles: false,
  };

  const toggleSection = (index) => {
    setOpenSectionIndex(openSectionIndex === index ? null : index);
  };

  const handleCheckboxChange = (sectionIndex, field) => {
    setFieldVisibility((prev) =>
      prev.map((visibility, index) =>
        index === sectionIndex ? { ...visibility, [field]: !visibility[field] } : visibility
      )
    );
  };

  useEffect(() => {
    if (sections.length !== prevSectionsLength.current) {
      if (!isEmpty(sections)) {
        const data = sections.map((section) => ({
          subTitle: !isEmpty(section?.subTitle),
          content: !isEmpty(section?.content),
          metadata: !isEmpty(section?.metadata),
          image: !isEmpty(section?.image),
          articles: !isEmpty(section?.articleIds),
          itemsList: !isEmpty(section?.listItems)
            ? section.listItems.map((item) => ({
                subTitle: !isEmpty(item?.subTitle),
                description: !isEmpty(item?.description),
                metadata: !isEmpty(item?.metadata),
                image: !isEmpty(item?.image),
                articles: !isEmpty(item?.articleIds),
              }))
            : [],
        }));

        setFieldVisibility(data);
        prevSectionsLength.current = sections.length;
      }
    }
  }, [sections]);

  //   section items
  const defaultItemsPayload = {
    subTitle: false,
    description: false,
    metadata: false,
    image: false,
    articles: false,
  };

  const toggleSectionItems = (index) => {
    setOpenSectionItemIndex(openSectionItemIndex === index ? null : index);
  };

  const handleItemsCheckboxChange = (sectionIndex, itemIndex, field) => {
    setFieldVisibility((prev) =>
      prev.map((section, secIndex) => {
        return secIndex === sectionIndex
          ? {
              ...section,
              itemsList: section?.itemsList?.map((item, itmIndex) =>
                itmIndex === itemIndex ? { ...item, [field]: !item[field] } : item
              ),
            }
          : section;
      })
    );
  };

  return (
    <>
      <div className="border-bottom d-flex align-items-center justify-content-between pb-3">
        <h5 className="mb-0">Sections</h5>
        <h6
          className="text-primary mb-0"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setShowSectionSitemap(!showSectionSitemap);
          }}>
          {showSectionSitemap ? 'Hide' : 'Show'} Section sitemap
        </h6>
      </div>

      {showSectionSitemap && (
        <div>
          {!isEmpty(sections) &&
            sections?.map((section, sIndex) => {
              return (
                <div className="mb-3" key={sIndex}>
                  <a
                    className="text-dark mb-3"
                    href={`#section_${sIndex + 1}`}
                    onClick={() => {
                      setOpenSectionIndex(sIndex);
                    }}>
                    <h5 className="mb-2">
                      <span>{section?.order}. </span>
                      {section?.title} - ( {section?.slug} )
                    </h5>
                  </a>
                  {section?.listItems?.map((item, iIndex) => {
                    return (
                      <a
                        className="text-dark mb-3"
                        href={`#section_${sIndex + 1}_item_${iIndex + 1}`}
                        key={iIndex}
                        onClick={() => {
                          setOpenSectionIndex(sIndex);
                          toggleSectionItems(iIndex);
                        }}>
                        <h6
                          style={{
                            marginLeft: '40px',
                            borderLeft: '3px solid #ccc',
                            paddingLeft: '10px',
                            marginTop: '5px',
                          }}>
                          <span>{item?.order}</span> {item?.title}
                        </h6>
                      </a>
                    );
                  })}
                </div>
              );
            })}
        </div>
      )}

      {!isEmpty(sections) &&
        sections.map((section, sectionIndex) => (
          <Card className="mb-4" key={sectionIndex}>
            <div
              style={{ cursor: 'pointer' }}
              className="bg-info text-white p-3 w-100 rounded-2 d-flex align-items-center justify-content-between"
              onClick={() => toggleSection(sectionIndex)}>
              <h4 className="mb-0">Section - {sectionIndex + 1}</h4>
              <i className={`bx bx-chevron-${openSectionIndex === sectionIndex ? 'up' : 'down'} fs-2`} />
            </div>
            <Collapse isOpen={openSectionIndex === sectionIndex}>
              <CardBody>
                <div className="row mb-4 d-flex justify-content-end">
                  <div className="col-md-3 text-end">
                    <Button outline color="danger" onClick={() => handleRemoveSection(sectionIndex)}>
                      Remove Section
                    </Button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="row" id={`section_${sectionIndex + 1}`}>
                      <div className="d-flex align-items-center col-md-3">
                        <Label for={`order-${sectionIndex}`} className="form-label mb-0">
                          Order <span className="text-danger">*</span>
                        </Label>
                      </div>
                      <div className="col-md-9">
                        <Select
                          placeholder="Select order"
                          id="order"
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 99999 }),
                          }}
                          options={Array.from({ length: 20 }, (_, index) => ({
                            value: index + 1,
                            label: (index + 1).toString(),
                          }))}
                          value={
                            section?.order
                              ? {
                                  label: section.order.toString(),
                                  value: section.order,
                                }
                              : null
                          }
                          onChange={(e) => handleUpdateSection(sectionIndex, 'order', Number(e.value))}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="mb-4 row">
                      <div className="col-md-2 d-flex align-items-center justify-content-end">
                        <Label for={`slug-${sectionIndex}`} className="form-label mb-0 ">
                          Key <span className="text-danger">*</span>
                        </Label>
                      </div>
                      <div className="col-md-10">
                        <Select
                          placeholder="Select key"
                          id="key"
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 99999 }),
                          }}
                          options={STATIC_PAGE_SECTION_TYPES}
                          value={STATIC_PAGE_SECTION_TYPES?.find((e) => e?.value === section.slug)}
                          onChange={(e) => handleUpdateSection(sectionIndex, 'slug', e.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-4 d-flex align-items-center row">
                  <div className="col-md-1">
                    <Label for={`title-${sectionIndex}`} className="form-label mb-0">
                      Title <span className="text-danger">*</span>
                    </Label>
                  </div>
                  <div className="col-md-11">
                    <Input
                      id={`title-${sectionIndex}`}
                      name={`title-${sectionIndex}`}
                      type="text"
                      className="form-control"
                      placeholder="title..."
                      value={section?.title}
                      onChange={(e) => handleUpdateSection(sectionIndex, 'title', e.target.value)}
                    />
                  </div>
                </div>

                <div className="mb-4 bg-light p-4 rounded-2">
                  <Label className="mb-3">Show Fields:</Label>
                  <div className="d-flex gap-3">
                    {Object.keys(defaultPayload)?.map((e) => (
                      <div className="me-2" key={e}>
                        <Input
                          id={`${e}_${sectionIndex}_checkbox`}
                          type="checkbox"
                          checked={fieldVisibility[sectionIndex]?.[e]}
                          onChange={() => handleCheckboxChange(sectionIndex, e)}
                        />
                        <Label htmlFor={`${e}_${sectionIndex}_checkbox`}>{titleCaseIfExists(e)}</Label>
                      </div>
                    ))}
                  </div>
                </div>

                {fieldVisibility[sectionIndex]?.subTitle && (
                  <div className="mb-4 d-flex align-items-start row">
                    <div className="col-md-1">
                      <Label for={`subTitle-${sectionIndex}`} className="form-label mb-0">
                        SubTitle
                      </Label>
                    </div>
                    <div className="col-md-11">
                      <Input
                        id={`subTitle-${sectionIndex}`}
                        name={`subTitle-${sectionIndex}`}
                        type="text"
                        className="form-control"
                        placeholder="subTitle..."
                        value={section?.subTitle}
                        onChange={(e) => handleUpdateSection(sectionIndex, 'subTitle', e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {fieldVisibility[sectionIndex]?.content && (
                  <div className="mb-4 d-flex align-items-start row">
                    <div className="col-md-1">
                      <Label for={`content-${sectionIndex}`} className="form-label mb-0">
                        Content
                      </Label>
                    </div>
                    <div className="col-md-11">
                      <TextEditor
                        simple
                        height={200}
                        initialValue={section?.content}
                        onChange={(val) => handleUpdateSection(sectionIndex, 'content', val)}
                      />
                    </div>
                  </div>
                )}

                {(fieldVisibility[sectionIndex]?.metadata || fieldVisibility[sectionIndex]?.image) && (
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-11">
                      <div className="row">
                        {fieldVisibility[sectionIndex]?.metadata && (
                          <div className="col-md-6">
                            <Label for={`metadata-${sectionIndex}`} className="form-label mb-3">
                              Metadata
                            </Label>
                            <Input
                              rows={10}
                              id={`metadata-${sectionIndex}`}
                              name={`metadata-${sectionIndex}`}
                              type="textarea"
                              className="form-control"
                              placeholder="Metadata..."
                              value={section?.metadata}
                              onChange={(e) => handleUpdateSection(sectionIndex, 'metadata', e.target.value)}
                            />
                          </div>
                        )}

                        {fieldVisibility[sectionIndex]?.image && (
                          <div className="col-md-6">
                            <Label for={`image-${sectionIndex}`} className="form-label mb-3">
                              Image
                            </Label>
                            <NestedImageUploader
                              showOriginalInput={true}
                              forType={MODULE_TYPE.METADATA}
                              label={'Select Image'}
                              value={section?.image}
                              onChange={(val) => handleUpdateSection(sectionIndex, 'image', val)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {fieldVisibility[sectionIndex]?.articles && (
                  <div className="mb-4 pt-4 d-flex align-items-start row">
                    <div className="col-md-1">
                      <Label for={`articles-${sectionIndex}`} className="form-label mb-3">
                        Articles
                      </Label>
                    </div>
                    <div className="col-md-11">
                      <CustomArticleSearch
                        value={section.articleIds}
                        onChange={(data) => handleUpdateSection(sectionIndex, 'articleIds', data)}
                      />
                    </div>
                  </div>
                )}

                <div className="mb-4 pt-4 d-flex align-items-start row">
                  <div className="col-md-1">
                    <Label for={`items-${sectionIndex}`} className="form-label mb-3">
                      Items
                    </Label>
                  </div>
                  <div className="col-md-11">
                    {!isEmpty(section?.listItems) &&
                      section?.listItems?.map((item, itemIndex) => {
                        return (
                          <div
                            id={`section_${sectionIndex + 1}_item_${itemIndex + 1}`}
                            key={itemIndex}
                            className="mb-3 bg-light p-4 rounded-3 mb-3">
                            <div className="p-3 bg-white shadow-md border border-info text-info text-center rounded-2">
                              <div
                                style={{ cursor: 'pointer' }}
                                className="d-flex align-items-center justify-content-between"
                                onClick={() => toggleSectionItems(itemIndex)}>
                                <h4 className="mb-0">
                                  Section - {sectionIndex + 1} , <span className="ms-3">Item - {itemIndex + 1}</span>
                                </h4>
                                <i
                                  className={`bx bx-chevron-${openSectionItemIndex === itemIndex ? 'up' : 'down'} fs-2`}
                                />
                              </div>
                            </div>
                            <Collapse isOpen={openSectionItemIndex === itemIndex}>
                              <div className="row mb-4 d-flex justify-content-end mt-4">
                                <div className="col-md-3 text-end">
                                  <Button
                                    outline
                                    color="danger"
                                    onClick={() => handleRemoveItem(sectionIndex, itemIndex)}>
                                    <i class="bx bx-trash me-1" /> Delete
                                  </Button>
                                </div>
                              </div>

                              <div className="col-md-12 mb-3 form-control mb-0 rounded-2 p-4 py-4">
                                <Row>
                                  <Col md={3} className="mb-3">
                                    <Label for={`item_order_${sectionIndex}`} className="form-label">
                                      Order <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                      placeholder="Select order"
                                      id="order"
                                      styles={{
                                        menu: (provided) => ({ ...provided, zIndex: 99999 }),
                                      }}
                                      options={Array.from({ length: 20 }, (_, index) => ({
                                        value: index + 1,
                                        label: (index + 1).toString(),
                                      }))}
                                      value={
                                        item.order
                                          ? {
                                              label: item.order.toString(),
                                              value: item.order,
                                            }
                                          : null
                                      }
                                      onChange={(e) =>
                                        handleUpdateItems(sectionIndex, itemIndex, 'order', Number(e.value))
                                      }
                                    />
                                  </Col>
                                  <Col md={9} className="mb-3">
                                    <Label for={`item_title_${sectionIndex}`} className="form-label">
                                      Title <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      id={`item_title_${itemIndex}`}
                                      name={`item_title_${itemIndex}`}
                                      type="text"
                                      className="form-control"
                                      placeholder="Title..."
                                      value={item.title}
                                      onChange={(e) =>
                                        handleUpdateItems(sectionIndex, itemIndex, 'title', e.target.value)
                                      }
                                    />
                                  </Col>

                                  <div className="mb-4 bg-light p-4 rounded-2">
                                    <Label className="mb-3">Show Item Fields:</Label>
                                    <div className="d-flex gap-3">
                                      {Object.keys(defaultItemsPayload)?.map((e) => (
                                        <div className="me-2" key={e}>
                                          <Input
                                            id={`${e}_${sectionIndex}_item_${itemIndex}_checkbox`}
                                            type="checkbox"
                                            checked={fieldVisibility?.[sectionIndex]?.itemsList?.[itemIndex]?.[e]}
                                            onChange={() => handleItemsCheckboxChange(sectionIndex, itemIndex, e)}
                                          />
                                          <Label htmlFor={`${e}_${sectionIndex}_item_${itemIndex}_checkbox`}>
                                            {e === 'description' ? 'Content' : titleCaseIfExists(e)}
                                          </Label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>

                                  {fieldVisibility?.[sectionIndex]?.itemsList?.[itemIndex]?.subTitle && (
                                    <Col md={12} className="mb-3">
                                      <Label for={`item_subTitle_${sectionIndex}`} className="form-label">
                                        SubTitle
                                      </Label>
                                      <Input
                                        id={`item_subTitle_${itemIndex}`}
                                        name={`item_subTitle_${itemIndex}`}
                                        type="textarea"
                                        rows={3}
                                        className="form-control"
                                        placeholder="summary..."
                                        value={item.subTitle}
                                        onChange={(e) =>
                                          handleUpdateItems(sectionIndex, itemIndex, 'subTitle', e.target.value)
                                        }
                                      />
                                    </Col>
                                  )}

                                  {fieldVisibility?.[sectionIndex]?.itemsList?.[itemIndex]?.description && (
                                    <Col md={12} className="mb-3">
                                      <Label for={`item_description_${sectionIndex}`} className="form-label">
                                        Content
                                      </Label>

                                      <TextEditor
                                        simple
                                        height={200}
                                        initialValue={item.description}
                                        onChange={(val) => {
                                          handleUpdateItems(sectionIndex, itemIndex, 'description', val);
                                        }}
                                      />
                                    </Col>
                                  )}

                                  {fieldVisibility?.[sectionIndex]?.itemsList?.[itemIndex]?.metadata && (
                                    <Col md={6}>
                                      <Label for={`metadata_${sectionIndex}`} className="form-label">
                                        Metadata
                                      </Label>
                                      <Input
                                        rows={10}
                                        id={`item_metadata_${itemIndex}`}
                                        name={`item_metadata_${itemIndex}`}
                                        type="textarea"
                                        className="form-control"
                                        placeholder="Metadata..."
                                        value={item.metadata}
                                        onChange={(e) =>
                                          handleUpdateItems(sectionIndex, itemIndex, 'metadata', e.target.value)
                                        }
                                      />
                                    </Col>
                                  )}
                                  {fieldVisibility?.[sectionIndex]?.itemsList?.[itemIndex]?.image && (
                                    <Col md={6}>
                                      <NestedImageUploader
                                        showOriginalInput={true}
                                        forType={MODULE_TYPE.METADATA}
                                        label={'Select Image'}
                                        value={item.image}
                                        onChange={(val) => handleUpdateItems(sectionIndex, itemIndex, 'image', val)}
                                      />
                                    </Col>
                                  )}

                                  {fieldVisibility[sectionIndex]?.itemsList?.[itemIndex]?.articles && (
                                    <div className="mb-4 pt-4 ">
                                      <Label className="form-label mb-2">Articles</Label>
                                      <CustomArticleSearch
                                        value={item.articleIds}
                                        onChange={(data) =>
                                          handleUpdateItems(sectionIndex, itemIndex, 'articleIds', data)
                                        }
                                      />
                                    </div>
                                  )}
                                </Row>
                              </div>
                            </Collapse>
                          </div>
                        );
                      })}

                    <Button
                      className={!isEmpty(section?.listItems) ? 'mt-3 px-4' : 'px-4'}
                      color="success"
                      onClick={() => {
                        handleAddItem(sectionIndex);

                        setFieldVisibility((prev) => {
                          return !isEmpty(prev)
                            ? prev?.map((prevVisibility, secIndex) => {
                                if (secIndex === sectionIndex) {
                                  return {
                                    ...prevVisibility,
                                    itemsList: !isEmpty(prevVisibility.itemsList)
                                      ? [...prevVisibility.itemsList, defaultItemsPayload]
                                      : [defaultItemsPayload],
                                  };
                                }
                                return {
                                  ...prevVisibility,
                                  itemsList: [defaultItemsPayload],
                                };
                              })
                            : [];
                        });
                      }}>
                      Add Item
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Collapse>
          </Card>
        ))}
    </>
  );
};

export default CollapsibleSections;
