import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from 'components/Loader';
import Paginate from 'components/Paginate';
import TableError from 'components/TableError';
import { DELETE_COMMUNITY_POST, UPDATE_COMMUNITY_POST } from 'containers/community/graphql/community.mutation';
import { COMMUNITY_POSTS_SEARCH } from 'containers/community/graphql/community.query';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { SORT_VALUES_DATE } from 'utils/constants';
import { buildURL, printSerialNumbers } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import confirm from 'reactstrap-confirm';

const PostsList = () => {
  const router = useRouter();
  const { query } = router;
  const [postsList, setPostsList] = useState([]);
  const [postsPagination, setPostsPagination] = useState({});

  const [getPostsRequest, { loading: postsRequestLoading }] = useLazyQuery(COMMUNITY_POSTS_SEARCH, {
    onCompleted: (data) => {
      const payload = data?.admin_community_post_search;
      if (!isEmpty(payload)) {
        setPostsList(payload?.results);
        setPostsPagination(payload?.pagination);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const [markPostActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useMutation(UPDATE_COMMUNITY_POST, {
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_community_post_update)) {
        handleChangePage(parseInt(router?.query?.page, 10) || 1);
        toast.success(`Success! Community Post has been Updated `);
      }
    },
    onError: (error) => {
      toast.error('Something went wrong!');
    },
  });

  const [markPostDeleted, { loading: markDeletedLoading }] = useMutation(DELETE_COMMUNITY_POST, {
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_community_post_remove)) {
        handleChangePage(parseInt(router?.query?.page, 10) || 1);
        toast.success(`Success! Community Post has been Updated `);
      }
    },
    onError: (error) => {
      toast.error('Something went wrong!');
    },
  });

  const handleChangePage = (page) => {
    getPostsRequest({
      variables: {
        searchDTO: {
          page: page ? page : 1,
          size: 20,
          groupId: query?.id,
          ...SORT_VALUES_DATE,
        },
      },
    });
  };

  const handlePostActive = (data) => {
    // toggle post active
    markPostActiveInActiveRequest({
      variables: {
        postId: data?.id,
        createDTO: {
          groupId: data?.group?.id,
          active: !data?.active,
          title: data?.title,
        },
      },
    });
  };

  const handlePostDelete = async (data) => {
    const result = await confirm({
      title: `${data?.deleted ? 'Undelete' : 'Delete'} Group`,
      message: `Are you sure, you want to ${data?.deleted ? 'Undelete' : 'Delete'} this Post?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      await markPostDeleted({
        variables: {
          postId: data?.id,
        },
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(query?.id)) {
      handleChangePage();
    }
  }, [query?.id]);

  return (
    <>
      <Loader isActive={postsRequestLoading || markActiveInActiveLoading || markDeletedLoading} />

      {postsPagination && (
        <Row className="mb-3 mt-4">
          <Col sm="4">
            <div className="search-box me-2 mb-2 d-inline-block">
              <div className="position-relative font-bold">{postsPagination?.totalCount} Posts Found</div>
            </div>
          </Col>
        </Row>
      )}

      <div className="table-responsive" style={{ minHeight: 300 }}>
        <Table className="project-list-table   table-centered table-borderless">
          <thead>
            <tr className="table-warning">
              <th scope="col">S.No.</th>
              <th scope="col">Title</th>
              <th scope="col">Comment Count</th>
              <th scope="col">Reaction Count</th>
              <th scope="col">isAnonymous</th>
              <th scope="col">isPinned</th>
              <th scope="col">Active</th>
              <th scope="col">Deleted</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(postsList) ? (
              postsList?.map((post, index) => {
                return (
                  <tr key={`post_${post?.id}`} className={!post?.active ? 'bg-danger' : ''}>
                    <td>{printSerialNumbers(postsPagination)[index]}</td>
                    <td>
                      <div className="d-flex">
                        <div>
                          <Link
                            to={buildURL(SMKLinks.COMMUNITY_POST_COMMENTS_EDIT, {
                              id: post?.id,
                            })}>
                            {post?.title}
                          </Link>
                        </div>

                        {post?.id && (
                          <div className="ms-2">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={process.env.REACT_APP_THEPARENTZ_WEB_URL + `/community/post/${post?.id}`}>
                              <i className="bx bx-link-external fs-4" />
                            </a>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>{post?.stats?.commentCount}</td>
                    <td>{post?.stats?.reactionCount}</td>
                    <td>
                      <Badge className={post?.isAnonymous ? 'bg-success' : 'bg-danger'}>
                        {post?.isAnonymous ? 'Yes' : 'No'}
                      </Badge>
                    </td>
                    <td>
                      <Badge className={post?.isPinned ? 'bg-success' : 'bg-danger'}>
                        {post?.isPinned ? 'Yes' : 'No'}
                      </Badge>
                    </td>
                    <td>
                      <Badge className={post?.active ? 'bg-success' : 'bg-danger'}>{post?.active ? 'Yes' : 'No'}</Badge>
                    </td>
                    <td>
                      <Badge className={post?.deleted ? 'bg-success' : 'bg-danger'}>
                        {post?.deleted ? 'Yes' : 'No'}
                      </Badge>
                    </td>
                    <td>
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="card-drop" tag="i">
                          <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <Link
                            to={buildURL(SMKLinks.COMMUNITY_POSTS_EDIT, {
                              id: post?.id,
                            })}>
                            <DropdownItem>
                              <i className="fas fa-pencil-alt text-success m-1 pe-3" />
                              Edit Comments
                            </DropdownItem>
                          </Link>

                          <DropdownItem onClick={() => handlePostActive(post)}>
                            <i
                              className={`fas  ${
                                post?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                              }   m-1 pe-3`}
                            />
                            {post?.active ? 'Disable' : 'Enable'}
                          </DropdownItem>
                          <DropdownItem onClick={() => handlePostDelete(post)}>
                            <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                            {post?.deleted ? 'Undelete' : 'Delete'}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                );
              })
            ) : (
              <TableError />
            )}
          </tbody>
        </Table>

        {!isEmpty(postsList) && <Paginate pageInfo={postsPagination} onChangePage={handleChangePage} />}
      </div>
    </>
  );
};

export default PostsList;
