// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
// Import Image
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import confirm from 'reactstrap-confirm';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import {
  ACTIVE_AND_DELETED_ARRAY,
  BABY_NAMES_GENDER_ARRAY,
  BabyNameMetaInfoType,
  GENDER_VALUES,
  LANGUAGE_ARRAY_WITH_STRING,
  LANGUAGE_VALUES_STRING,
  PRINT_LANGUAGE,
  PRINT_LANGUAGE_COLOR,
  SORT_VALUES,
} from '../../../utils/constants';
import {
  buildURL,
  checkIfValueExist,
  convertHtmlToText,
  formatInputDate,
  getTranslationLabel,
  printDate,
  printDateTimeTwoLines,
} from '../../../utils/helper';
import { SMKLinks } from '../../../utils/links';

const BabynamesList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [babyNames, setBabyNames] = useState([]);
  const [babyNamesPagination, setBabyNamesPagination] = useState({});
  const [originList, setOriginList] = useState([]);
  const [religionList, setReligionList] = useState([]);

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    createdStartDate: formatInputDate(moment().subtract(20, 'years')),
    createdEndDate: formatInputDate(moment()),
    id: undefined,
    language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,
    name: undefined,
    meaning: undefined,
    gender: undefined,
    origin: undefined,
    religion: undefined,
    forTranslation: { value: null, label: 'All' },
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getBabyNamesRequest, { loading: getBabyNamesLoading }] = useRequest({
    url: `/baby-name/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setBabyNames(data?.results);
      setBabyNamesPagination(data?.pagination);
    },
  });

  const [markPublishRequest, { loading: markPublishLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      toast.success('Success! Baby Name has been Published');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(`Error occurred: ${err}`);
    },
  });

  const [markUnpublishRequest, { loading: markUnpublishLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      toast.success('Success! Baby Name has been Unpublished');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(`Error occurred: ${err}`);
    },
  });

  const [undeleteReviewRequest, { loading: undeleteReviewLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      toast.success('Success! Baby Name has been UnDelete');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(`Error occurred: ${err}`);
    },
  });

  const setPageInUrl = (filters) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ ...filters }).toString()}`,
    });
  };

  const handleChangePage = (page, updatedFilters) => {
    const currentFilters = updatedFilters ? updatedFilters : filters;
    setPageInUrl({
      page,
      ...(currentFilters?.origin ? { origin: currentFilters?.origin } : {}),
      ...(currentFilters?.religion ? { religion: currentFilters?.religion } : {}),
    });

    const data = {
      page,
      size: 20,
      ...currentFilters,
      active: checkIfValueExist(currentFilters.active?.value),
      deleted: checkIfValueExist(currentFilters.deleted?.value),
      forTranslation: checkIfValueExist(currentFilters.forTranslation?.value),
      gender: checkIfValueExist(currentFilters?.gender?.value),
      origin: checkIfValueExist(currentFilters?.origin),
      religion: checkIfValueExist(currentFilters?.religion),
      ...SORT_VALUES,
    };

    getBabyNamesRequest({
      body: {
        ...data,
      },
    });
  };

  const [deleteBabyNameRequest, { loading: deleteBabyNameLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success('Success! Baby Name has been Deleted');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(`Error occurred: ${err}`);
    },
  });

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl({ page: 1 });

    setFilters({
      ...defaultFilters,
      id: '',
      name: '',
      meaning: '',
      gender: '',
      origin: '',
      religion: '',
    });
    getBabyNamesRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        forTranslation: checkIfValueExist(defaultFilters.forTranslation?.value),
        ...SORT_VALUES,
      },
    });
  };

  const [getOriginRequest, { loading: getOriginLoading }] = useRequest({
    url: `/baby-name/metaInfo/${BabyNameMetaInfoType.ORIGIN}`,
    method: 'GET',
    headers: {
      'accept-language': 'en',
    },
    onSuccess: (data) => {
      setOriginList(data);
    },
  });

  const [getReligionRequest, { loading: getReligionLoading }] = useRequest({
    url: `/baby-name/metaInfo/${BabyNameMetaInfoType.RELIGION}`,
    method: 'GET',
    headers: {
      'accept-language': 'en',
    },
    onSuccess: (data) => {
      setReligionList(data);
    },
  });

  useEffect(() => {
    if (!router?.query?.origin && !router?.query?.religion) {
      getOriginRequest();
      getReligionRequest();
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
    }
  }, []);

  useEffect(() => {
    if (router.query.origin || router.query.religion) {
      getOriginRequest();
      getReligionRequest();
      const { origin, religion, page } = router.query;

      if (religion || origin) {
        let updatedFilters = { ...filters };

        if (origin && originList) {
          updatedFilters = { ...updatedFilters, origin };
        }
        if (religion && religionList) {
          updatedFilters = { ...updatedFilters, religion };
        }

        setFilters(updatedFilters);
        handleChangePage(page || 1, updatedFilters);

        // Update URL
        const queryParams = new URLSearchParams();
        if (page) queryParams.set('page', page);
        if (origin) queryParams.set('origin', origin);
        if (religion) queryParams.set('religion', religion);

        const newUrl = `${router.pathname}?${queryParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
      }
    }
  }, [router.query.origin, router.query.religion]);

  // const handleToggleActive = async (data) => {
  //   const result = await confirm({
  //     title: `${data?.active ? 'Unpublish' : 'Publish'} Babyname`,
  //     message: `Are you sure, you want to ${data?.active ? 'Unpublish' : 'Publish'} this Babyname?`,
  //     confirmText: 'Confirm',
  //     confirmColor: 'primary',
  //     cancelColor: 'link text-danger',
  //   });

  //   if (result) {
  //     if (data?.active) {
  //       markUnpublishRequest({ url: `/baby-name/admin/unpublish/${data.id}` });
  //     } else {
  //       markPublishRequest({ url: `/baby-name/admin/publish/${data.id}` });
  //     }
  //   }
  // };

  // const handleDeleteItem = async (data) => {
  //   const result = await confirm({
  //     title: `${data?.active ? 'Undelete' : 'Delete'} Babyname`,
  //     message: `Are you sure, you want to ${data?.deleted ? 'Undelete' : 'Delete'
  //       } this Babyname?`,
  //     confirmText: 'Confirm',
  //     confirmColor: 'primary',
  //     cancelColor: 'link text-danger',
  //   });

  //   if (result) {
  //     if (data?.active) {
  //       deleteReviewRequest({ url: `/baby-name/admin/delete/${data.id}` });
  //     } else {
  //       undeleteReviewRequest({ url: `/baby-name/admin/undelete/${data.id}` });
  //     }
  //   }
  // };

  const handleDeleteBabyName = async (data) => {
    const result = await confirm({
      title: 'Delete Baby Name',
      message: `Are you sure, you want to Delete this Babyname?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      deleteBabyNameRequest({ url: `/baby-name/admin/delete/${data.id}` });
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getBabyNamesLoading ||
            undeleteReviewLoading ||
            deleteBabyNameLoading ||
            markUnpublishLoading ||
            markPublishLoading ||
            getReligionLoading ||
            getOriginLoading
          }
        />
        {/* {getBabyNamesLoading && <Loader isActive />} */}
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="BabyNames" breadcrumbItem="Baby Names List" />
          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Published</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Created DateFrom</label>
                    <Input
                      type="date"
                      name="createdStartDate"
                      placeholder="From Date"
                      value={filters?.createdStartDate}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          createdStartDate: formatInputDate(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Created DateTo</label>
                    <Input
                      type="date"
                      name="createdEndDate"
                      placeholder="To Date"
                      value={filters?.createdEndDate}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          createdEndDate: formatInputDate(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Gender</label>
                    <Select
                      options={BABY_NAMES_GENDER_ARRAY}
                      value={filters.gender}
                      onChange={(value) => setFilters({ ...filters, gender: value })}
                    />
                  </div>

                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Origin</label>
                    <Select
                      options={originList?.map((s) => ({
                        ...s,
                        label: s.name,
                        value: s.slug,
                      }))}
                      value={
                        filters.origin
                          ? originList
                              ?.map((s) => ({
                                ...s,
                                label: s.name,
                                value: s.slug,
                              }))
                              ?.find((t) => t.value === filters.origin)
                          : null
                      }
                      onChange={(item) => setFilters({ ...filters, origin: item.value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Religion</label>
                    <Select
                      options={religionList?.map((s) => ({
                        ...s,
                        label: s.name,
                        value: s.slug,
                      }))}
                      value={
                        filters.religion
                          ? religionList
                              ?.map((s) => ({
                                ...s,
                                label: s.name,
                                value: s.slug,
                              }))
                              ?.find((t) => t.value === filters.religion)
                          : null
                      }
                      onChange={(item) => setFilters({ ...filters, religion: item.value })}
                    />
                  </div>

                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY_WITH_STRING}
                      value={LANGUAGE_ARRAY_WITH_STRING.find((t) => t.value === filters.language)}
                      onChange={(item) =>
                        setFilters({
                          ...filters,
                          language: item.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Translated</label>
                    <Select
                      options={[
                        {
                          label: 'All',
                          value: null,
                        },
                        {
                          label: 'Yes',
                          value: 0,
                        },
                        {
                          label: 'No',
                          value: 1,
                        },
                      ]}
                      value={filters.forTranslation}
                      onChange={(value) => {
                        setFilters({ ...filters, forTranslation: value });
                      }}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Babyname Id</label>
                    <Input
                      type="number"
                      name="name"
                      placeholder="Babyname Id..."
                      value={filters.id}
                      onChange={(e) => setFilters({ ...filters, id: e.target.value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Babyname</label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>
          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{babyNamesPagination?.totalCount} Baby Names Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_BABY_NAME}>
                  <i className="mdi mdi-plus me-1" />
                  Add Baby Name
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">#ID</th>
                        <th scope="col" />
                        <th scope="col">Name/Slug</th>
                        <th scope="col">Translated</th>
                        <th scope="col">Meaning</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Language</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(babyNames) ? (
                        babyNames?.map((data, index) => (
                          <tr key={index}>
                            <td>
                              {' '}
                              <Link
                                to={buildURL(SMKLinks.BABY_NAME_DETAILS, {
                                  id: data.id,
                                })}>
                                {data.id}
                              </Link>
                            </td>
                            <td>
                              {data?.gender?.approved ? (
                                <i title="Active" className=" bx bx-check-shield font-size-24 text-success" />
                              ) : (
                                <i title="InActive" className=" bx bx-error-alt font-size-24 text-warning" />
                              )}
                            </td>
                            <td>
                              <div className="d-flex">
                                <div>
                                  <Link
                                    to={buildURL(SMKLinks.EDIT_BABY_NAME, {
                                      id: data?.id,
                                    })}>
                                    {convertHtmlToText(data.name)}
                                  </Link>
                                  <br />
                                  {data.slug}
                                </div>

                                {!isEmpty(data.slug) && (
                                  <div className="ms-2">
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`https:www.theparentz.com/baby-names/${data?.slug}`}>
                                      <i className="bx bx-link-external fs-4" />
                                    </a>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="text-center">
                              <a
                                target="_blank"
                                href={buildURL(SMKLinks.EDIT_BABY_NAME, {
                                  id: data?.translations[0]?.id,
                                })}>
                                {getTranslationLabel(data?.translations[0])}
                              </a>
                              <br />
                              {data?.translations[0]?.slug}
                            </td>
                            <td style={{ width: '350px' }}>
                              {data.meaning}
                              {data.meanings?.length > 0 && (
                                <>
                                  <br />
                                  <b>Meanings: </b>
                                  {data.meanings?.map((o) => o.name).join(', ')}
                                </>
                              )}
                            </td>

                            <td>{GENDER_VALUES[data?.genderValue]}</td>
                            <td>{printDate(data?.createdDate)}</td>
                            <td>
                              <Badge className={PRINT_LANGUAGE_COLOR[data?.language]}>
                                {PRINT_LANGUAGE[data?.language]}
                              </Badge>
                            </td>

                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            {/* <td>{data?.viewCount}</td> */}
                            {/* <td>{data?.origins?.map((o) => o.name).join(', ')}</td>
                            <td>{data?.religions?.map((o) => o.name).join(', ')}</td> */}
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <Link
                                    to={buildURL(SMKLinks.EDIT_BABY_NAME, {
                                      id: data.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>

                                  {data?.language !== LANGUAGE_VALUES_STRING.hindi && isEmpty(data?.translations) && (
                                    <Link
                                      target="_blank"
                                      to={buildURL(
                                        SMKLinks.TRANSLATE_BABY_NAME,
                                        {
                                          id: data.id,
                                        },
                                        {
                                          origin: filters?.origin,
                                          religion: filters?.religion,
                                          page: router?.query?.page,
                                        }
                                      )}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Translate
                                      </DropdownItem>
                                    </Link>
                                  )}

                                  <DropdownItem
                                    className="text-danger"
                                    onClick={() => {
                                      handleDeleteBabyName(data);
                                    }}>
                                    <i className="fas fa-trash m-1 pe-3 " />
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={babyNamesPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default BabynamesList;
