import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormFeedback, FormGroup, Input } from 'reactstrap';

const DynamicSelect = ({ formik, keyName, label, doctorDetails, defaultValues }) => {
  const [inputs, setInputs] = useState(['']);

  useEffect(() => {
    if (!isEmpty(doctorDetails)) {
      const currentData = doctorDetails[keyName];
      if (!isEmpty(currentData)) {
        setInputs(currentData);
      }
    }
  }, [doctorDetails]);

  useEffect(() => {
    if (!isEmpty(defaultValues)) {
      setInputs(defaultValues);
    }
  }, [defaultValues]);

  const handleAddInput = () => {
    setInputs([...inputs, '']);
    formik.setFieldValue(keyName, [...inputs, '']);
  };

  const handleRemoveInput = (index) => {
    const updatedInputs = inputs.filter((_, i) => i !== index);
    setInputs(updatedInputs);
    formik.setFieldValue(keyName, updatedInputs);
  };

  const handleChange = (index, e) => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = e.target.value;
    setInputs(updatedInputs);
    formik.setFieldValue(keyName, updatedInputs);
  };

  return (
    <FormGroup>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h5 className="mb-0">{label}</h5>
        <button type="button" className="btn btn-md btn-outline-success mt-2" onClick={handleAddInput}>
          <i className="bx bx-plus me-1" />
          Add
        </button>
      </div>
      {!isEmpty(inputs) &&
        inputs?.map((input, index) => (
          <div key={index} className="d-flex align-items-center mb-3">
            <h5 className="form-label me-4 mb-0">{index + 1}. </h5>
            <Input
              type="text"
              value={input}
              onChange={(e) => handleChange(index, e)}
              className="form-control"
              placeholder={`Enter ${label}...`}
              invalid={!!(formik.touched[keyName] && formik.errors[keyName]?.[index])}
            />
            <button type="button" className="btn btn-outline-danger ms-4" onClick={() => handleRemoveInput(index)}>
              <i className="bx bx-trash" />
            </button>
          </div>
        ))}

      <FormFeedback className="d-block">{formik?.errors?.[keyName]}</FormFeedback>
    </FormGroup>
  );
};

export default DynamicSelect;
