import { useLazyQuery } from '@apollo/client';
import Loader from 'components/Loader';
import Paginate from 'components/Paginate';
import TableError from 'components/TableError';
import { COMMUNITY_MEMBER_SEARCH } from 'containers/community/graphql/community.query';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Badge, Table } from 'reactstrap';
import { SORT_VALUES_DATE } from 'utils/constants';
import { printSerialNumbers } from 'utils/helper';

const ContributorsList = () => {
  const router = useRouter();
  const { query } = router;
  const [membersList, setMembersList] = useState([]);
  const [membersPagination, setMembersPagination] = useState({});

  const [getMembersRequest, { loading: communityMembersRequestLoading }] = useLazyQuery(COMMUNITY_MEMBER_SEARCH, {
    onCompleted: (data) => {
      const payload = data?.admin_community_member_search;
      if (!isEmpty(payload)) {
        setMembersList(payload?.results);
        setMembersPagination(payload?.pagination);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const handleChangePage = (page) => {
    getMembersRequest({
      variables: {
        searchDTO: {
          page: page ? page : 1,
          size: 20,
          groupId: query?.id,
          ...SORT_VALUES_DATE,
        },
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(query?.id)) {
      handleChangePage();
    }
  }, [query?.id]);

  return (
    <>
      <Loader isActive={communityMembersRequestLoading} />
      <div className="table-responsive mt-4" style={{ minHeight: 300 }}>
        <Table className="project-list-table   table-centered table-borderless">
          <thead>
            <tr className="table-warning">
              <th scope="col">S.No.</th>
              <th scope="col">Name</th>
              <th scope="col">Active</th>
              <th scope="col">Deleted</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(membersList) ? (
              membersList?.map((item, index) => {
                return (
                  <tr key={`_test_${item?.memeber?.displayName}_`} className={!item?.active ? 'bg-danger' : ''}>
                    <td>{printSerialNumbers(membersPagination)[index]}</td>
                    <td>{item?.member?.displayName}</td>
                    <td>
                      <Badge className={item?.active ? 'bg-success' : 'bg-danger'}>{item?.active ? 'Yes' : 'No'}</Badge>
                    </td>
                    <td>
                      <Badge className={item?.deleted ? 'bg-success' : 'bg-danger'}>
                        {item?.deleted ? 'Yes' : 'No'}
                      </Badge>
                    </td>
                  </tr>
                );
              })
            ) : (
              <TableError />
            )}
          </tbody>
        </Table>

        {!isEmpty(membersList) && <Paginate pageInfo={membersPagination} onChangePage={handleChangePage} />}
      </div>
    </>
  );
};

export default ContributorsList;
