import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { isEmpty } from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup } from 'reactstrap';
import { API_BASE_URL, taxonomyType } from 'utils/constants';

const DoctorSpecializations = ({ formik, doctorDetails }) => {
  toast.configure();

  const handleRemoveSpecialization = (id) => {
    const updatedData = formik.values?.specialities?.filter((item) => item.id !== id);
    formik.setFieldValue('specialities', updatedData);
  };

  const handleSelectSpecializationChange = (res) => {
    const categories = formik.values?.specialities ? [...formik.values?.specialities] : [];
    let updatedData = null;

    if (!categories.some((item) => item?.id === res?.id)) {
      updatedData = [res, ...categories];
    } else {
      toast.error('Speciality already added!');
      updatedData = categories;
      return;
    }

    formik.setFieldValue('specialities', updatedData);
    const specialityiesName = !isEmpty(updatedData) ? updatedData?.map((e) => e?.name) : [];
    const specializationsValue = !isEmpty(doctorDetails?.specializations)
      ? [...doctorDetails?.specializations, res?.name]
      : [...(!isEmpty(formik.values.specializations) ? formik.values.specializations : []), res?.name];

    formik.setFieldValue('areaOfExpertise', specialityiesName?.join(', '));
    formik.setFieldValue(
      'specializations',
      specializationsValue?.filter((item, index, self) => index === self.findIndex((t) => t === item))
    );
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <Card>
          <CardBody>
            <h5 className="mb-4">Specialities</h5>
            <SmkAsyncSelect
              isHealthcare={true}
              acceptedKey={'name'}
              acceptedValue={'id'}
              searchKeyName="name"
              placeholder="Type speciality name.."
              fetchUrl={`${API_BASE_URL}/common/admin/taxonomy/search`}
              filters={{
                autoComplete: true,
                page: 1,
                size: 1000,
                sortBy: 'name',
                sortOrder: 0,
                type: taxonomyType.DOCTOR_SPECIALITY,
              }}
              onChange={handleSelectSpecializationChange}
            />
          </CardBody>
        </Card>

        <div className="mb-3">
          {!isEmpty(formik.values.specialities) &&
            formik.values.specialities?.slice()?.map((item, index) => (
              <div
                key={item.uid}
                className="bg-white rounded-2 p-3 d-flex justify-content-between align-items-center mt-3">
                <h6 className="mb-0">
                  {index + 1}. {item.name}
                  {!isEmpty(item.slug) && ` - ( ${item.slug} )`}
                </h6>
                <button
                  className="btn btn-md btn-danger"
                  type="button"
                  onClick={() => handleRemoveSpecialization(item.id)}>
                  Remove
                </button>
              </div>
            ))}
        </div>

        <FormFeedback className="d-block">{formik?.errors?.specialities}</FormFeedback>
      </FormGroup>

      <div className="my-5">
        <Button type="submit" color="primary">
          Update Doctor
        </Button>
      </div>
    </Form>
  );
};

export default DoctorSpecializations;
