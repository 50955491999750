import { gql } from '@apollo/client';

// add Article categories
export const ADD_COMMUNITY_GROUP = gql`
  mutation Admin_community_group_add($createDTO: GroupCreateDTO!) {
    admin_community_group_add(createDTO: $createDTO) {
      id
      active
      deleted
    }
  }
`;

// update Article categories
export const UPDATE_COMMUNITY_GROUP = gql`
  mutation Admin_community_group_update($groupId: String!, $createDTO: GroupCreateDTO!) {
    admin_community_group_update(groupId: $groupId, createDTO: $createDTO) {
      id
      active
      deleted
    }
  }
`;

export const ADD_COMMUNITY_POST = gql`
  mutation Admin_community_post_add($createDTO: PostCreateDTO!) {
    admin_community_post_add(createDTO: $createDTO) {
      id
      deleted
      active
      groupId
      title
      slug
      language
      isPinned
      isAnonymous
      stats {
        viewCount
        postCount
        commentCount
        reactionCount
      }
    }
  }
`;

export const UPDATE_COMMUNITY_POST = gql`
  mutation Admin_community_post_update($postId: String!, $createDTO: PostCreateDTO!) {
    admin_community_post_update(postId: $postId, createDTO: $createDTO) {
      id
      deleted
      active
      groupId
      title
      slug
      language
      isPinned
      isAnonymous
      stats {
        viewCount
        commentCount
        reactionCount
      }
    }
  }
`;

export const DELETE_COMMUNITY_POST = gql`
  mutation Admin_community_post_remove($postId: String!) {
    admin_community_post_remove(postId: $postId)
  }
`;

// Update Post Comment
export const UPDATE_POST_COMMENT = gql`
  mutation Admin_community_post_comment_update($commentId: String!, $createDTO: PostCommentCreateDTO!) {
    admin_community_post_comment_update(commentId: $commentId, createDTO: $createDTO) {
      id
    }
  }
`;

// Delete Post Comment
export const DELETE_POST_COMMENT = gql`
  mutation Admin_community_post_comment_remove($commentId: String!) {
    admin_community_post_comment_remove(commentId: $commentId)
  }
`;
