import { showImageError } from 'containers/blog/helper';
import { isEmpty, size } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import confirm from 'reactstrap-confirm';
import { showImage } from 'utils/helper';
import { AttachmentImageUploadModal } from './index';

const CustomAttachmentImageUploader = ({
  value,
  onReset,
  onChange,
  label,
  forType,
  renameOriginal = true,
  renameOriginalRaw = true,
}) => {
  const [imageUploadedModal, setImageUploadedModal] = useState(false);
  toast.configure();

  const fieldObj = value;
  const IMAGE_TYPE = 'thumbnailMedium';

  const imageURL = fieldObj?.[IMAGE_TYPE]
    ? showImage(fieldObj?.[IMAGE_TYPE])
    : fieldObj?.original
    ? showImage(fieldObj?.original)
    : fieldObj?.url;

  const toggleImageUploadedModal = () => {
    setImageUploadedModal(!imageUploadedModal);
  };

  const handleRemove = async () => {
    const result = await confirm({
      title: 'Remove Image',
      message: 'Are you sure, you want to remove this image?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      onReset();
    }
  };

  return (
    <Card className="border">
      <CardBody>
        <div>
          <CardTitle>{label}</CardTitle>

          {isEmpty(imageURL) && (
            <Button className="w-100 py-3 mt-2" outline color="dark" onClick={toggleImageUploadedModal}>
              Add Image
            </Button>
          )}

          {!isEmpty(fieldObj) && (
            <div className="mt-4 row">
              <div className={`col-md-12`}>
                <a
                  onClick={() => {
                    toggleModal();
                  }}>
                  <img
                    style={{
                      width: '-webkit-fill-available',
                    }}
                    className="mb-3 p-2"
                    src={imageURL}
                    onError={(e) => {
                      showImageError(e);
                    }}
                  />
                  <p className="mb-3">{fieldObj?.name}</p>
                </a>
                <Button color="danger" className="w-100" onClick={handleRemove}>
                  Remove
                </Button>
              </div>
            </div>
          )}

          <AttachmentImageUploadModal
            renameOriginal={renameOriginal}
            renameOriginalRaw={renameOriginalRaw}
            isOpen={imageUploadedModal}
            toggle={toggleImageUploadedModal}
            forType={forType}
            onUpload={(file) => {
              toggleImageUploadedModal();
              if (file) {
                onChange({
                  ...file,
                  size: Number(file.size),
                });
              }
            }}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default CustomAttachmentImageUploader;
