import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import { useFormik } from 'formik';
import useRequest from 'hooks/useRequest';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import DoctorClinics from '../DoctorClinics';
import DoctorSpecializations from '../DoctorSpecialization';
import DoctorSummaryBlock from '../DoctorSummaryBlock';
import UpdateDoctorFields from './updateDoctorFields';

const DoctorDetail = () => {
  const { id: doctorId } = useParams();
  const router = useRouter();

  const [doctorDetails, setDoctorDetails] = useState([]);
  const [selectedTab, setSelectedTab] = useState(router?.query?.tab || 'clinics');

  const [getDoctorDetails, { loading: getDoctorLoading }] = useRequest({
    url: `/healthcare/doctor/admin/${doctorId}`,
    method: 'GET',
    onSuccess: (data) => {
      setDoctorDetails(data);
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  const [updateDoctorRequest, { loading: updateDoctorLoading }] = useRequest({
    url: `/healthcare/doctor/admin/${doctorId}`,
    method: 'PUT',
    onSuccess: () => {
      setSelectedTab(router?.query?.tab);
      window.location.reload();
      toast.success('Success! Doctor has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const getInitialValues = () => ({
    ...doctorDetails,
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialValues(),
    onSubmit: async (values) => {
      let payload = {};

      if (selectedTab === 'specialities') {
        payload = {
          [selectedTab]: values?.[selectedTab],
          areaOfExpertise: values?.areaOfExpertise,
          specializations: values?.specializations,
        };
      } else {
        payload = {
          [selectedTab]: values?.[selectedTab],
        };
      }

      updateDoctorRequest({
        body: payload,
      });
    },
  });

  const tabsList = [
    {
      name: ' Clinics',
      slug: 'clinics',
      component: <DoctorClinics doctorId={doctorId} />,
    },
    {
      name: 'Specialities',
      slug: 'specialities',
      component: <DoctorSpecializations formik={formik} doctorDetails={doctorDetails} />,
    },
    {
      name: 'Specializations',
      slug: 'specializations',
      component: (
        <UpdateDoctorFields
          doctorDetails={doctorDetails}
          formik={formik}
          keyName={'specializations'}
          label={'Specializations'}
        />
      ),
    },
    {
      name: 'Education',
      slug: 'educations',
      component: (
        <UpdateDoctorFields doctorDetails={doctorDetails} formik={formik} keyName={'educations'} label={'Educations'} />
      ),
    },
    {
      name: 'Awards',
      slug: 'awards',
      component: (
        <UpdateDoctorFields doctorDetails={doctorDetails} formik={formik} keyName={'awards'} label={'Awards'} />
      ),
    },
    {
      name: 'Services',
      slug: 'services',
      component: (
        <UpdateDoctorFields doctorDetails={doctorDetails} formik={formik} keyName={'services'} label={'Services'} />
      ),
    },
    {
      name: 'Registration',
      slug: 'registration',
      component: (
        <UpdateDoctorFields
          doctorDetails={doctorDetails}
          formik={formik}
          keyName={'registration'}
          label={'Registration'}
        />
      ),
    },
    {
      name: 'Experiences',
      slug: 'experiences',
      component: (
        <UpdateDoctorFields
          doctorDetails={doctorDetails}
          formik={formik}
          keyName={'experiences'}
          label={'Experiences'}
        />
      ),
    },
    {
      name: 'memberships',
      slug: 'memberships',
      component: (
        <UpdateDoctorFields
          doctorDetails={doctorDetails}
          formik={formik}
          keyName={'memberships'}
          label={'Memberships'}
        />
      ),
    },
    {
      name: 'Trainings',
      slug: 'trainings',
      component: (
        <UpdateDoctorFields doctorDetails={doctorDetails} formik={formik} keyName={'trainings'} label={'Trainings'} />
      ),
    },
  ];

  useEffect(() => {
    getDoctorDetails();
  }, []);

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    if (selectedTab && currentSearchParams.get('tab') !== selectedTab) {
      currentSearchParams.set('tab', selectedTab);
      const newUrl = `${window.location.pathname}?${currentSearchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
    }
  }, [selectedTab]);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getDoctorLoading || updateDoctorLoading} />
        <Container fluid>
          <Breadcrumbs title="Doctor" breadcrumbItem="Doctor Details" />

          {!isEmpty(doctorDetails) && <DoctorSummaryBlock doctorDetails={doctorDetails} />}

          <Row>
            <Col lg={3}>
              <Card>
                <CardBody className="p-4">
                  <div>
                    <h5 className="text-muted mb-3">Doctor Details</h5>
                    <ul className="list-unstyled font-weight-medium">
                      {tabsList?.map((tab, i) => (
                        <li key={i}>
                          <Link
                            to={`/doctor/details/${doctorDetails?.id}`}
                            replace
                            onClick={() => setSelectedTab(tab?.slug)}
                            className={`${selectedTab === tab?.slug ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                            <i className="mdi mdi-chevron-right me-1" /> {tab.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {!isEmpty(doctorDetails) && (
              <Col lg={9}>{tabsList?.map((tab) => (tab?.slug === selectedTab ? tab?.component : null))}</Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DoctorDetail;
