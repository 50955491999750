import { USER_ROLE } from '../../utils/constants';
import CommunityGroupsList from './groups';
import AddUpdateGroups from './groups/addUpdateGroups';
import CommunityGroupDetails from './groups/groupDetails';
import UpdatePostComments from './groups/posts/comments';
import COMMUNITY_LINKS from './links';
import CommunityReportedPostsList from './posts';
import AddUpdatePosts from './posts/addUpdatePosts';

const COMMUNITY_ROUTES = [
  {
    path: COMMUNITY_LINKS.COMMUNITY_GROUPS_LIST,
    exact: true,
    component: CommunityGroupsList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COMMUNITY_LINKS.COMMUNITY_GROUPS_ADD,
    exact: true,
    component: AddUpdateGroups,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COMMUNITY_LINKS.COMMUNITY_GROUPS_EDIT,
    exact: true,
    component: AddUpdateGroups,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COMMUNITY_LINKS.COMMUNITY_GROUPS_DETAILS,
    exact: true,
    component: CommunityGroupDetails,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COMMUNITY_LINKS.COMMUNITY_REPORTED_POSTS_LIST,
    exact: true,
    component: CommunityReportedPostsList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COMMUNITY_LINKS.COMMUNITY_POSTS_ADD,
    exact: true,
    component: AddUpdatePosts,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COMMUNITY_LINKS.COMMUNITY_POSTS_EDIT,
    exact: true,
    component: AddUpdatePosts,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COMMUNITY_LINKS.COMMUNITY_POST_COMMENTS_EDIT,
    exact: true,
    component: UpdatePostComments,
    role: [USER_ROLE.ADMIN],
  },
];

export { COMMUNITY_LINKS, COMMUNITY_ROUTES };
