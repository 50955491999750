import ImageUploadPreview from 'components/ImageUploadPreview';
import Loader from 'components/Loader';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { api } from 'utils/axios';
import { API_BASE_URL } from 'utils/constants';
import { getCurrentUserData } from 'utils/helper';

const UploadMedia = ({ formik, field, uploadedImagePreview, setUploadedImagePreview, source }) => {
  const [imageUploading, setImageUploading] = useState(false);

  const uploadFile = (file) => {
    const payload = {
      isAsset: true,
      renameOriginal: formik?.values?.renameOriginal,
      renameOriginalRaw: formik?.values?.renameOriginalRaw,

      backgroundDetails: {
        width: formik?.values?.width || 0,
        height: formik?.values?.height || 0,
        minPadding: formik?.values?.minPadding || 0,
        backgroundColor: formik?.values?.backgroundColor || '',
      },
    };

    const formData = new FormData();
    formData.append('file', file);

    api({
      method: 'POST',
      url: `${API_BASE_URL}/attachment/upload?config=${btoa(JSON.stringify(payload))}`,
      data: formData,
    })
      ?.then((res) => {
        if (isEmpty(res?.message)) {
          setUploadedImagePreview(res);
        }
        setImageUploading(false);
      })
      ?.catch((err) => {
        console.warn('error', err);
      });
  };

  const handleFileChange = (acceptedFile) => {
    const file = acceptedFile[0];

    if (file) {
      const filename = file.name;
      const validFilenameRegex = /^[a-zA-Z0-9-_]+$/;

      const [baseFilename, ...extensionParts] = filename.split('.');
      const extension = extensionParts.length > 0 ? '.' + extensionParts.join('.') : '';

      if (!validFilenameRegex.test(baseFilename)) {
        const updatedBaseFilename = baseFilename
          .toLowerCase()
          .replace(/[^a-zA-Z0-9-_]/g, '_')
          .replace(/^[-_]+|[-_]+$/g, '');

        const updatedFileName = updatedBaseFilename + extension;
        confirm(`Invalid filename. Use this filename: ${updatedFileName}`);
        window.location.reload();
        return;
      }
      setImageUploading(true);
      formik?.setFieldValue(field, file);
      uploadFile(file);
    }
  };

  return (
    <>
      <Loader isActive={imageUploading} />
      <Row className="align-items-center">
        <Col md={12}>
          <div>
            <Label className="col-form-label">Upload Image</Label>
            <FormGroup>
              <Dropzone
                acceptedFiles={{
                  'image/*': [],
                  'video/*': [],
                }}
                onDrop={(acceptedFile) => {
                  handleFileChange(acceptedFile);
                }}>
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick p-0" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="dz-message needsclick">
                        <div className="mb-2">
                          <i className="display-2 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
              {formik.errors[field] && formik.touched[field] && (
                <FormFeedback className="d-block fs-6 mt-2  ">{formik.errors[field]}</FormFeedback>
              )}
              <div className="dropzone-previews mt-3 row " id="file-previews">
                {!isEmpty(uploadedImagePreview || formik.values[field]) &&
                  ImageUploadPreview(
                    !isEmpty(uploadedImagePreview)
                      ? getCurrentUserData()?.stgURL + '/upload/' + uploadedImagePreview?.filename
                      : formik.values[field]?.url,
                    field,
                    formik,
                    setUploadedImagePreview,
                    12
                  )}
              </div>
            </FormGroup>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UploadMedia;
