import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

// i18n

// links
import { isLoggedInSelector, userSelector } from 'common/globalComponents/selectors';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { SMKLinks } from '../../utils/links';

const stateSelector = createStructuredSelector({
  isLoggedIn: isLoggedInSelector,
  user: userSelector,
});

const MediwatcherMenuBar = (props) => {
  const { location, t } = props;
  const ref = useRef();
  const { user, isLoggedIn } = useSelector(stateSelector);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link replace to="/dashboard" className="waves-effect">
                <i className="bx bx-home-circle" />
                <span>{t('Dashboard')}</span>
              </Link>
            </li>
            {user?.entityPermissions?.masterdata?.read && (
              <>
                <li>
                  <Link replace to="/#" className="has-arrow waves-effect">
                    <i className="bx bx-mobile" />
                    <span>App Banners</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link replace to={SMKLinks.APP_BANNERS_LISTING}>
                        {t('List')}
                      </Link>
                    </li>
                    <li>
                      <Link replace to={SMKLinks.APP_BANNERS_ADD}>
                        {t('Add')}
                      </Link>
                    </li>
                  </ul>
                </li>
                <hr className="border border-dark" />
                {user?.entityPermissions?.contact_inquiry?.read && (
                  <li>
                    <Link replace to={SMKLinks.CONTACT_INQUIRY_LISTING}>
                      <i className="bx bxs-contact" />
                      <span>{t('Contact Inquiry')}</span>
                    </Link>
                  </li>
                )}
                <hr className="border border-dark" />

                {user.entityPermissions?.forums?.create && (
                  <li>
                    <Link replace to="/#" className="has-arrow waves-effect">
                      <i className="bx bxs-user" />
                      <span>{t('Community')}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link replace to={SMKLinks.COMMUNITY_GROUPS_LIST}>
                          {t('Group List')}
                        </Link>
                      </li>

                      <li>
                        <Link replace to={SMKLinks.COMMUNITY_GROUPS_ADD}>
                          {t('Add Group')}
                        </Link>
                      </li>
                      <li>
                        <Link replace to={SMKLinks.COMMUNITY_REPORTED_POSTS_LIST}>
                          {t('Reported Posts List')}
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
              </>
            )}

            {user.entityPermissions?.cms?.create && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-book-content" />
                  <span>{t('CMS')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.FAQ_LIST}>
                      {t('FAQ')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.FAQ_METADATA_LIST}>
                      {t('FAQ metadata')}
                    </Link>
                  </li>

                  <li>
                    <Link replace to={SMKLinks.STATIC_PAGES_LIST}>
                      {t('Page')}
                    </Link>
                  </li>

                  <li>
                    <Link replace to={SMKLinks.STATIC_METADATA_PAGES_LIST}>
                      {t('Page metadata')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user.entityPermissions?.masterdata?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-grid" />
                  <span>{t('Taxonomy')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.MEDIWATCHER_TAXONOMY_LISTING}>
                      {t('Taxonomy List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.ADD_MEDIWATCHER_TAXONOMY}>
                      {t('Add taxonomy')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {user?.entityPermissions?.article?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxl-blogger" />
                  <span>{t('Blog')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.BLOG_LIST}>
                      {t('Blog List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.EXPERTS_INVOLVED_BLOG_LIST}>
                      {t('Expert Involved Blogs')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.BLOG_CATEGORIES_LIST}>
                      {t('Categories List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.BLOG_TAGS_LIST}>
                      {t('Tags List')}
                    </Link>
                  </li>
                  {user?.entityPermissions?.article?.create && (
                    <li>
                      <Link replace to={SMKLinks.ADD_BLOG}>
                        {t('Add Blog')}
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            <hr className="border border-dark" />
            {user.entityPermissions?.user?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-user-detail" />
                  <span>{t('Users')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.MEDIWATCHER_USERS_LISTING}>
                      {t('Users List')}
                    </Link>
                  </li>

                  <li>
                    <Link replace to={SMKLinks.MEDIWATCHER_ADD_USER}>
                      {t('Add User')}
                    </Link>
                  </li>
                  {user.entityPermissions?.user?.admin && (
                    <li>
                      <Link replace to={SMKLinks.USER_SUBSCRIPTIONS_LISTING}>
                        {t('User Subscriptions List')}
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link replace to="/roles/list">
                      {t('Roles List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to="/roles/add">
                      {t('Add Role')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user?.entityPermissions?.masterdata?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-dollar-circle" />
                  <span>{t('Subscription Plans')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.SUBSCRIPTION_PLANS}>
                      {t('Subscription List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.SUBSCRIPTION_OFFERS}>
                      {t('Subscription Offers')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.ADD_SUBSCRIPTION_PLAN}>
                      {t('Add Subscription')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            <hr className="border border-dark" />
            <li className="menu-title text-white">Mediwatcher App</li>
            {user.entityPermissions?.user?.admin && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-first-aid" />
                  <span>{t('Doctors')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.DOCTORS_LISTING}>
                      {t('Doctors List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.HEALTH_CARE_LISTING}>
                      {t('Healthcare facility List')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user.entityPermissions?.user?.admin && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-capsule" />
                  <span>{t('Medicines')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.MEDICINES_LISTING}>
                      {t('Medicines List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.ADD_MEDICINE}>
                      {t('Add medicine')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user.entityPermissions?.user?.admin && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-plus-medical" />
                  <span>{t('Vaccinations')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.VACCINATION_LISTING}>
                      {t('Vaccinations List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.ADD_VACCINATION}>
                      {t('Add vaccination')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {/* <hr className="border border-dark" />

            <li className="menu-title text-white">Mediwatcher Web</li> */}
          </ul>
        </div>
      </SimpleBar>
    </>
  );
};

MediwatcherMenuBar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(MediwatcherMenuBar));
