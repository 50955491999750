import dummyUserIcon from 'assets/images/dummy-user.svg';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import MiniCards from 'components/MiniCard/mini-card';
import Paginate from 'components/Paginate';
import TableError from 'components/TableError';
import useRequest from 'hooks/useRequest';
import { isEmpty } from 'lodash';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Badge, Card, CardBody, CardTitle, Col, Container, Row, Table } from 'reactstrap';
import { GET_GENDER } from 'utils/constants';
import { buildURL, printSerialNumbers, showImage } from 'utils/helper';
import { SMKLinks } from 'utils/links';

const HealthCareFacilityDetails = () => {
  const { id: healthCareFacilityId } = useParams();

  const [healthCarefacilityDetail, setHealthCarefacilityDetail] = useState([]);
  const [doctorsList, setDoctorsList] = useState([]);
  const [doctorsPagination, setDoctorsPagination] = useState({});

  const [getHealthcareRequest, { loading: getHealthcareLoading }] = useRequest({
    url: `/healthcare/doctor/admin/healthcareFacility/${healthCareFacilityId}`,
    method: 'GET',
    onSuccess: (data) => {
      setHealthCarefacilityDetail(data);
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  const [getDoctorsList, { loading: getDoctorLoading }] = useRequest({
    url: `/healthcare/doctor/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setDoctorsList(data?.results);
      setDoctorsPagination(data?.pagination);
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  const handleChangePage = (page) => {
    getDoctorsList({
      body: {
        page: page ? page : 1,
        size: 500,
        sortBy: 'id',
        sortOrder: 0,
        healthFacilityId: healthCareFacilityId,
      },
    });
  };

  useEffect(() => {
    if (healthCareFacilityId) {
      getHealthcareRequest();
      handleChangePage(1);
    }
  }, [healthCareFacilityId]);

  const miniCards = [
    {
      title: 'isSystemDefined',
      iconClass: 'bx-power-off',
      text: `${!isEmpty(healthCarefacilityDetail?.isSystemDefined) ? 'Yes' : 'No'}`,
      textColor: `${!isEmpty(healthCarefacilityDetail?.isSystemDefined) ? 'text-success' : 'text-danger'}`,
    },
    {
      title: 'Active',
      iconClass: 'bx-power-off',
      text: `${healthCarefacilityDetail?.active ? 'Yes' : 'No'}`,
      textColor: `${healthCarefacilityDetail?.active ? 'text-success' : 'text-danger'}`,
    },
    {
      title: 'Deleted',
      iconClass: 'bx-trash',
      text: `${healthCarefacilityDetail?.deleted ? 'Yes' : 'No'}`,
      textColor: `${healthCarefacilityDetail?.deleted ? 'text-success' : 'text-danger'}`,
    },
  ];

  return (
    <div className="page-content">
      <Loader isActive={getHealthcareLoading || getDoctorLoading} />
      <Container fluid>
        <Breadcrumbs title="Health care facility " breadcrumbItem="Health care facility Detail" />

        <Row>
          <Col xl="4">
            <Card className="overflow-hidden">
              <CardBody className="p-3">
                <Row className="align-items-center">
                  <Col sm="3">
                    <div className="avatar-md profile-doctorDetails-wid m-auto">
                      <img
                        src={
                          !isEmpty(healthCarefacilityDetail?.images)
                            ? showImage(healthCarefacilityDetail?.images[0]?.filename)
                            : dummyUserIcon
                        }
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                  </Col>
                  <Col sm="9">
                    <h5 className="font-size-15 text-truncate">{healthCarefacilityDetail?.name}</h5>
                    <p className="text-muted mb-0 text-truncate">{healthCarefacilityDetail?.email}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <CardTitle className="py-3 card-title text-center bg-light mb-0">Basic Information</CardTitle>

                <div className="table-responsive" style={{ minHeight: 200 }}>
                  <Table className="table-wrap mb-0 table-striped table-white">
                    <tbody>
                      <tr>
                        <th>Name :</th>
                        <td>{healthCarefacilityDetail?.name}</td>
                      </tr>
                      <tr>
                        <th>Email :</th>
                        <td>{healthCarefacilityDetail?.email}</td>
                      </tr>
                      <tr>
                        <th>Phone number :</th>
                        <td>{healthCarefacilityDetail?.phoneNumber}</td>
                      </tr>
                      <tr>
                        <th>Landmark :</th>
                        <td>{healthCarefacilityDetail?.address?.landmark}</td>
                      </tr>
                      <tr>
                        <th>PostalCode :</th>
                        <td>{healthCarefacilityDetail?.address?.postalCode}</td>
                      </tr>
                      <tr>
                        <th>Country :</th>
                        <td>{healthCarefacilityDetail?.address?.country}</td>
                      </tr>
                      <tr>
                        <th>City :</th>
                        <td>{healthCarefacilityDetail?.address?.city}</td>
                      </tr>
                      <tr>
                        <th>SubArea :</th>
                        <td>{healthCarefacilityDetail?.address?.subArea}</td>
                      </tr>
                      <tr>
                        <th>Line 1 :</th>
                        <td>{healthCarefacilityDetail?.address?.line1}</td>
                      </tr>
                      <tr>
                        <th>Line 2 :</th>
                        <td>{healthCarefacilityDetail?.address?.line2}</td>
                      </tr>
                      <tr>
                        <th>Latitude:</th>
                        <td>{healthCarefacilityDetail?.address?.location?.latitude}</td>
                      </tr>
                      <tr>
                        <th>Longitude:</th>
                        <td>{healthCarefacilityDetail?.address?.location?.longitude}</td>
                      </tr>
                      <tr>
                        <th>Notes :</th>
                        <td>{healthCarefacilityDetail?.notes}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl="8">
            <Row>
              {map(miniCards, (card, key) => (
                <MiniCards
                  title={card?.title}
                  text={card?.text}
                  textColor={card?.textColor}
                  iconClass={card?.iconClass}
                  key={`_card_${key}`}
                />
              ))}
            </Row>
            <Card>
              <CardBody>
                <CardTitle className="py-3 card-title text-center bg-light mb-4">
                  {doctorsPagination?.totalCount} Doctors Found{' '}
                </CardTitle>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive" style={{ minHeight: 200, maxHeight: 430 }}>
                      <Table className="table-nowrap mb-0 table-striped table-white">
                        <thead>
                          <tr className="table-warning">
                            <th>S.No.</th>
                            <th>Name</th>
                            <th>Gender</th>
                            <th>Area of expertise</th>
                            <th>Active</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(doctorsList) ? (
                            doctorsList?.map((doctor, index) => (
                              <tr key={`doctor_${index}`}>
                                <td>{printSerialNumbers(doctorsPagination)[index]}</td>
                                <td>
                                  <Link
                                    to={buildURL(SMKLinks.DOCTOR_DETAILS, {
                                      id: doctor?.id,
                                    })}>
                                    {doctor?.firstName} {doctor?.lastName}
                                  </Link>
                                  <br />
                                  {doctor?.slug}
                                </td>
                                <td>{GET_GENDER[doctor?.gender]}</td>
                                <td>{doctor?.areaOfExpertise}</td>
                                <td>
                                  <Badge className={doctor?.active ? 'bg-success' : 'bg-danger'}>
                                    {doctor?.active ? 'Yes' : 'No'}
                                  </Badge>
                                </td>
                                <td>
                                  <Badge className={doctor?.deleted ? 'bg-success' : 'bg-danger'}>
                                    {doctor?.deleted ? 'Yes' : 'No'}
                                  </Badge>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <TableError />
                          )}
                        </tbody>
                      </Table>
                    </div>

                    {!isEmpty(doctorsList) && <Paginate pageInfo={doctorsPagination} onChangePage={handleChangePage} />}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HealthCareFacilityDetails;
