import { useLazyQuery, useMutation } from '@apollo/client';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import MiniCards from 'components/MiniCard/mini-card';
import TextEditor from 'components/TextEditor';
import { DELETE_POST_COMMENT, UPDATE_POST_COMMENT } from 'containers/community/graphql/community.mutation';
import { COMMUNITY_POSTS_SEARCH, GET_COMMUNITY_COMMENTS_LIST } from 'containers/community/graphql/community.query';
import { useRouter } from 'hooks/useRouter';
import { isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import confirm from 'reactstrap-confirm';
import Comment from './comment';

const UpdatePostComments = () => {
  const router = useRouter();
  const postId = router.query?.id;
  const commentSize = 10;

  const [comments, setComments] = useState([]);
  const [commentsPagination, setCommentsPagination] = useState({});
  const [commentCounts, setCommentCounts] = useState({});
  const [nestedCommentsMap, setNestedCommentsMap] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedComment, setSelectedComment] = useState('');
  const [updatedText, setUpdatedText] = useState('');

  const [postDetails, setPostDetails] = useState(null);

  const [getPostDetailsRequest, { loading: getPostDetailsLoading }] = useLazyQuery(COMMUNITY_POSTS_SEARCH, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const payload = data?.admin_community_post_search?.results[0];
      setPostDetails(payload);
    },
  });

  const [getNestedCommentsList, { loading: nestedCommentsLoading }] = useLazyQuery(GET_COMMUNITY_COMMENTS_LIST, {
    fetchPolicy: 'no-cache',
  });

  const [getCommentsList, { loading: commentsLoading }] = useLazyQuery(GET_COMMUNITY_COMMENTS_LIST, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setIsLoadingMore(false);
      const payload = data?.admin_community_post_comment_search;
      if (payload && !isEmpty(payload?.results)) {
        setComments((prev) => [...prev, ...(payload?.results || [])]);
        setCommentsPagination(payload?.pagination);
        setCommentCounts(payload?.commentCounts);

        const nestedCommentsGrouped = payload?.comments?.reduce((acc, comment) => {
          acc[comment.commentId] = [...(acc[comment.commentId] || []), comment];
          return acc;
        }, {});

        setNestedCommentsMap((prev) => ({ ...prev, ...nestedCommentsGrouped }));
      }
    },
    onError: (err) => {
      setIsLoadingMore(false);
      console.warn('Error fetching comments', err);
    },
  });

  const [updateUserCommentRequest, { loading: updateUserCommentsLoading }] = useMutation(UPDATE_POST_COMMENT, {
    onCompleted: () => {
      window.location.reload();
    },
    onError: (err) => {
      console.error('Error:', err);
    },
  });

  const [deleteUserCommentRequest, { loading: deleteUserCommentLoading }] = useMutation(DELETE_POST_COMMENT, {
    onCompleted: () => {
      setIsLoading(true);
      window.location.reload();
    },
    onError: (err) => {
      console.error('Error:', err);
    },
  });

  const fetchComments = async (filters) => {
    await getCommentsList({
      variables: {
        searchDTO: {
          page: 1,
          size: commentSize,
          sortBy: 'createdDate',
          sortOrder: 1,
          postId,
          ...filters,
        },
      },
    });
  };

  const handleLoadMoreComments = () => {
    setIsLoadingMore(true);
    setCurrentPage((prevPage) => {
      const nextPage = prevPage + 1;
      fetchComments({
        page: nextPage,
        size: commentSize,
      });
      return nextPage;
    });
  };

  const handleLoadMoreNestedComments = async (commentId, page) => {
    const existingComments = nestedCommentsMap[commentId] || [];
    const nextPage = page ? page : Math.floor(existingComments.length / commentSize) + 1;

    const response = await getNestedCommentsList({
      variables: {
        searchDTO: {
          page: nextPage,
          postId,
          size: commentSize,
          sortBy: 'createdDate',
          sortOrder: 1,
          commentId,
        },
      },
    });

    const payload = response?.data?.admin_community_post_comment_search;
    if (!isEmpty(payload)) {
      const nestedComments = payload?.results || [];
      const mergedComments = [...existingComments, ...nestedComments]?.filter(
        (value, index, self) => index === self.findIndex((t) => t.id === value.id)
      );

      const updatedCounts = commentCounts.some((item) => item.comment_id === commentId)
        ? commentCounts.map((item) =>
            item.comment_id === commentId ? { ...item, count: payload?.pagination?.totalCount } : item
          )
        : [...commentCounts, { comment_id: commentId, count: payload?.pagination?.totalCount }];

      setCommentCounts(updatedCounts);

      const updatedComments = {
        ...nestedCommentsMap,
        [commentId]: mergedComments,
      };
      setNestedCommentsMap(updatedComments);
    }
  };

  const toggleModal = (data) => {
    if (!isEmpty(data)) {
      setSelectedComment(data);
    } else {
      setSelectedComment(null);
    }
  };

  const handleDeleteComment = async (data) => {
    const result = await confirm({
      title: 'Remove comment',
      message: 'Are you sure, you want to remove this comment?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      toggleModal();
      await deleteUserCommentRequest({
        variables: {
          commentId: data?.id,
        },
      });
    }
  };

  const handleSave = async () => {
    if (!isEmpty(selectedComment)) {
      toggleModal();
      await updateUserCommentRequest({
        variables: {
          postId,
          commentId: selectedComment?.id,
          createDTO: {
            content: updatedText,
          },
        },
      });
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  useEffect(() => {
    if (postId) {
      getPostDetailsRequest({
        variables: {
          searchDTO: {
            page: 1,
            size: 1,
            sortBy: 'id',
            sortOrder: 1,
            id: postId,
          },
        },
      });
    }
  }, [postId]);

  const miniCards = [
    {
      title: 'Group name',
      text: `${postDetails?.group?.name} `,
    },
    {
      title: 'Comment count',
      text: `${postDetails?.stats?.commentCount}`,
    },
    {
      title: 'Reaction count',
      text: `${postDetails?.stats?.reactionCount}`,
    },
    {
      title: 'isAnonymous',
      text: `${postDetails?.isAnonymous ? 'Yes' : 'No'} `,
      textColor: `${postDetails?.isAnonymous ? 'text-success' : 'text-danger'}`,
    },
  ];

  return (
    <div className="page-content">
      <Loader
        isActive={
          updateUserCommentsLoading ||
          deleteUserCommentLoading ||
          nestedCommentsLoading ||
          commentsLoading ||
          isLoading ||
          getPostDetailsLoading
        }
      />
      <Breadcrumbs title="Community Post Details" breadcrumbItem="Community Post Details" />

      <Container fluid>
        <Row>
          <Col xl="4">
            <Card className="overflow-hidden">
              <CardBody className="p-3">
                <Row className="align-items-center">
                  <Col sm="2">
                    <div className="avatar-md profile-user-wid m-auto d-flex align-items-center justify-content-center">
                      <i className="bx bxs-message-square-detail" style={{ fontSize: '50px', color: '#3460BB' }} />
                    </div>
                  </Col>
                  <Col sm="9" className="ms-3">
                    <h5 className="font-size-15 text-truncate mb-1">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={process.env.REACT_APP_THEPARENTZ_WEB_URL + `/community/post/${postDetails?.id}`}>
                        {postDetails?.title}{' '}
                      </a>
                    </h5>
                    <p className="text-muted mb-0 text-truncate">{postDetails?.slug}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xl="8">
            <Row>
              {map(miniCards, (card, key) => (
                <MiniCards
                  mdCol={3}
                  title={card?.title}
                  text={card?.text}
                  textColor={card?.textColor}
                  iconClass={card?.iconClass}
                  key={`_card_${key}`}
                  removeIcon={true}
                />
              ))}
            </Row>
          </Col>

          <div className="mt-4">
            <h5 className="mb-3">Comments List</h5>

            {!isEmpty(comments) ? (
              comments.map((comment) => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  toggleModal={toggleModal}
                  handleDeleteComment={handleDeleteComment}
                  loading={isLoading}
                  setUpdatedText={setUpdatedText}
                  nestedComments={nestedCommentsMap[comment.id] || []}
                  commentCounts={
                    !isEmpty(commentCounts) ? commentCounts?.find((e) => e?.comment_id === comment?.id) : null
                  }
                  onLoadMoreNested={(page) => {
                    handleLoadMoreNestedComments(comment?.id, page);
                  }}
                />
              ))
            ) : (
              <Card>
                <CardBody>
                  <div style={{ minHeight: 170 }} className="d-flex justify-content-center align-items-center">
                    <div className="text-center">
                      <i className="bx bxs-error" style={{ fontSize: '50px', color: '#ffbc42' }} />
                      <h5 className="mt-3 text-secondary">No Comment Found!</h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </div>

          {comments?.length < commentsPagination?.totalCount && (
            <div className="mt-3 mb-5">
              <button
                type="button"
                disabled={isLoadingMore}
                onClick={handleLoadMoreComments}
                className="btn btn-link p-0 load-more-link text-primary">
                {commentsPagination?.totalCount - comments?.length == '1'
                  ? `${commentsPagination?.totalCount - comments?.length} previous reply`
                  : `${commentsPagination?.totalCount - comments?.length} previous replies`}
              </button>
            </div>
          )}
        </Row>
      </Container>

      <Modal centered size="lg" isOpen={!isEmpty(selectedComment)}>
        <ModalHeader toggle={() => toggleModal()}>Update Comment</ModalHeader>
        <ModalBody className="p-4">
          <div className="mb-4">
            <h6 className="fw-500">
              Created by: <b>{selectedComment?.createdBy?.displayName}</b>
            </h6>
            <h6 className="fw-500">
              Created date: <b>{moment(selectedComment?.createdDate)?.format('DD-MMM-YYYY')}</b>
            </h6>
            <h6 className="mb-0 fw-500">
              Updated date: <b>{moment(selectedComment?.updatedDate)?.format('DD-MMM-YYYY')}</b>
            </h6>
          </div>

          <FormGroup>
            <Label>Content</Label>
            <TextEditor
              simple
              features={[['bold', 'underline', 'italic', 'list']]}
              height={200}
              initialValue={updatedText}
              onChange={(val) => setUpdatedText(val)}
            />
          </FormGroup>

          <div className="mt-4 mb-2">
            <Button color="primary" className="me-3" onClick={handleSave}>
              Save Comment
            </Button>
            <Button color="light" onClick={() => toggleModal()}>
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UpdatePostComments;
