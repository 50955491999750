import Loader from 'components/Loader';
import TableError from 'components/TableError';
import useRequest from 'hooks/useRequest';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from 'reactstrap';
import { buildURL } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import confirm from 'reactstrap-confirm';
import { Divider } from '@material-ui/core';

const DoctorClinics = ({ doctorId }) => {
  const [clinicList, setClinicList] = useState();

  const [getDoctorclinicList, { loading: getDoctorLocationLoading }] = useRequest({
    url: `/healthcare/doctor/admin/${doctorId}/locations`,
    method: 'GET',
    onSuccess: (data) => {
      setClinicList(data);
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  const [updateDoctorClinicRequest, { loading: updateDoctorClinicLoading }] = useRequest({
    url: `/healthcare/doctor/admin/${doctorId}/locations`,
    method: 'PUT',
    onSuccess: () => {
      window.location.reload();
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  const [deleteDoctorClinicRequest, { loading: deleteDoctorClinicLoading }] = useRequest({
    method: 'DELETE',
    onSuccess: () => {
      window.location.reload();
      toast.success(`Success! Doctor has been deleted.`);
    },
  });

  useEffect(() => {
    getDoctorclinicList();
  }, []);

  const handleDeleteClinic = async (data) => {
    const result = await confirm({
      title: `${data?.deleted ? 'Undelete' : 'Delete'} Clinic`,
      message: `Are you sure, you want to ${data?.deleted ? 'Undelete' : 'Delete'} this Clinic?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      if (data?.deleted) {
        updateDoctorClinicRequest({
          url: `/healthcare/doctor/admin/${doctorId}/location/${data?.id}`,
          body: {
            ...data,
            deleted: !data?.deleted,
          },
        });
      } else {
        deleteDoctorClinicRequest({ url: `/healthcare/doctor/admin/${doctorId}/location/${data.id}` });
      }
    }
  };

  const handleActiveClinic = (data) => {
    if (data?.active) {
      updateDoctorClinicRequest({
        url: `/healthcare/doctor/admin/${doctorId}/location/${data?.id}`,
        body: {
          ...data,
          active: false,
        },
      });
    } else {
      updateDoctorClinicRequest({
        url: `/healthcare/doctor/admin/${doctorId}/location/${data?.id}`,
        body: {
          ...data,
          active: true,
        },
      });
    }
  };

  return (
    <>
      <Loader isActive={getDoctorLocationLoading || updateDoctorClinicLoading || deleteDoctorClinicLoading} />

      <div className="pb-3 mt-2 mb-0 d-flex justify-content-between">
        <CardTitle className="card-title text-center ">Clinics List</CardTitle>
        <Link
          to={buildURL(SMKLinks.ADD_CLINIC, {
            doctorId: doctorId,
          })}
          className="btn btn-sm btn-success">
          Add Clinic
        </Link>
      </div>
      <div className="table-responsive" style={{ minHeight: 400 }}>
        <Table className="project-list-table table-centered table-borderless ">
          <thead>
            <tr className="table-warning">
              <th>S.No.</th>
              <th>Healthcare facility name</th>
              <th>DefaultCharges</th>
              <th>Active</th>
              <th>Deleted</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(clinicList) ? (
              clinicList?.map((clinic, index) => (
                <tr key={clinic?.id}>
                  <td>{index + 1}.</td>
                  <td>
                    <Link
                      to={buildURL(SMKLinks.EDIT_CLINIC, {
                        doctorId: doctorId,
                        id: clinic?.id,
                      })}>
                      {clinic?.healthcareFacility?.name}
                    </Link>
                  </td>
                  <td>{clinic?.defaultCharges}</td>
                  <td>
                    <Badge className={clinic?.active ? 'bg-success' : 'bg-danger'}>
                      {clinic?.active ? 'Yes' : 'No'}
                    </Badge>
                  </td>
                  <td>
                    <Badge className={clinic?.deleted ? 'bg-success' : 'bg-danger'}>
                      {clinic?.deleted ? 'Yes' : 'No'}
                    </Badge>
                  </td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle href="#" className="card-drop " tag="i">
                        <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem onClick={() => handleActiveClinic(clinic)}>
                          <i
                            className={`fas  ${
                              clinic?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                            }   m-1 pe-3`}
                          />
                          {clinic?.active ? 'Disable' : 'Enable'}
                        </DropdownItem>
                        <DropdownItem onClick={() => handleDeleteClinic(clinic)}>
                          <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                          {clinic?.deleted ? 'Undelete' : 'Delete'}
                        </DropdownItem>
                        <Divider className="my-2" />

                        <Link
                          to={buildURL(SMKLinks.EDIT_HEALTH_CARE, {
                            id: clinic?.healthcareFacilityId,
                          })}>
                          <DropdownItem>
                            <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                            Edit Healthcare
                          </DropdownItem>
                        </Link>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <TableError />
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default DoctorClinics;
