import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from 'components/Loader';
import Paginate from 'components/Paginate';
import TableError from 'components/TableError';
import { DELETE_COMMUNITY_POST, UPDATE_COMMUNITY_POST } from 'containers/community/graphql/community.mutation';
import { COMMUNITY_REPORTED_POSTS_SEARCH } from 'containers/community/graphql/community.query';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from 'reactstrap';
import confirm from 'reactstrap-confirm';
import { SORT_VALUES_DATE } from 'utils/constants';
import { buildURL, printSerialNumbers } from 'utils/helper';
import { SMKLinks } from 'utils/links';

const ReportedPostsList = () => {
  const router = useRouter();
  const { query } = router;
  const [reportedPosts, setReportedPosts] = useState([]);
  const [reportedPostsPagination, setReportedPostsPagination] = useState({});

  const [getReportedPostsRequest, { loading: reportedPostsRequestLoading }] = useLazyQuery(
    COMMUNITY_REPORTED_POSTS_SEARCH,
    {
      onCompleted: (data) => {
        const payload = data?.admin_community_post_report_search;
        if (!isEmpty(payload)) {
          setReportedPosts(payload?.results);
          setReportedPostsPagination(payload?.pagination);
        }
      },
      onError: () => {
        toast.error('Something went wrong!');
      },
    }
  );

  const [markPostActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useMutation(UPDATE_COMMUNITY_POST, {
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_community_post_update)) {
        handleChangePage(parseInt(router?.query?.page, 10) || 1);
        toast.success(`Success! Community Post has been Updated `);
      }
    },
    onError: (error) => {
      toast.error('Something went wrong!');
    },
  });

  const [markPostDeleted, { loading: markDeletedLoading }] = useMutation(DELETE_COMMUNITY_POST, {
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_community_post_remove)) {
        handleChangePage(parseInt(router?.query?.page, 10) || 1);
        toast.success(`Success! Community Post has been Updated `);
      }
    },
    onError: (error) => {
      toast.error('Something went wrong!');
    },
  });

  const handleChangePage = (page) => {
    getReportedPostsRequest({
      variables: {
        searchDTO: {
          page: page ? page : 1,
          size: 20,
          groupId: query?.id,
          ...SORT_VALUES_DATE,
        },
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(query?.id)) {
      handleChangePage();
    }
  }, [query?.id]);

  const handlePostActive = (data) => {
    // toggle post active
    markPostActiveInActiveRequest({
      variables: {
        postId: data?.id,
        createDTO: {
          ...data,
        },
      },
    });
  };

  const handlePostDelete = async (data) => {
    const result = await confirm({
      title: `${data?.deleted ? 'Undelete' : 'Delete'} Group`,
      message: `Are you sure, you want to ${data?.deleted ? 'Undelete' : 'Delete'} this Post?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      await markPostDeleted({
        variables: {
          postId: data?.id,
        },
      });
    }
  };

  return (
    <>
      <Loader isActive={reportedPostsRequestLoading || markActiveInActiveLoading || markDeletedLoading} />
      <div className="table-responsive mt-4" style={{ minHeight: 300 }}>
        <Table className="project-list-table   table-centered table-borderless">
          <thead>
            <tr className="table-warning">
              <th scope="col">S.No.</th>
              <th scope="col">Post</th>
              <th scope="col">Comments</th>
              <th scope="col">Reason</th>
              <th scope="col">Created Date</th>
              <th scope="col">Active</th>
              <th scope="col">Deleted</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(reportedPosts) ? (
              reportedPosts?.map((item, index) => {
                return (
                  <tr key={`_test_${item?.id}_`} className={!item?.active ? 'bg-danger' : ''}>
                    <td>{printSerialNumbers(reportedPostsPagination)[index]}</td>
                    <td>
                      {
                        <Link
                          to={buildURL(SMKLinks.COMMUNITY_POSTS_EDIT, {
                            id: item?.post?.d,
                          })}>
                          {item?.post?.title}
                          <br />
                          {item?.post?.id}
                        </Link>
                      }
                    </td>
                    <td>{item.comments}</td>
                    <td>{item.reason}</td>
                    <td>{item.createdDate}</td>
                    <td>
                      <Badge className={item?.active ? 'bg-success' : 'bg-danger'}>
                        {item?.post?.active ? 'Yes' : 'No'}
                      </Badge>
                    </td>
                    <td>
                      <Badge className={item?.deleted ? 'bg-success' : 'bg-danger'}>
                        {item?.post?.deleted ? 'Yes' : 'No'}
                      </Badge>
                    </td>
                    <td>
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="card-drop" tag="i">
                          <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <Link
                            to={buildURL(SMKLinks.COMMUNITY_POSTS_EDIT, {
                              id: item?.post?.id,
                            })}>
                            <DropdownItem>
                              <i className="fas fa-pencil-alt text-success m-1 pe-3" />
                              Edit Comments
                            </DropdownItem>
                          </Link>
                          <DropdownItem onClick={() => handlePostActive(item?.post)}>
                            <i
                              className={`fas  ${
                                item?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                              }   m-1 pe-3`}
                            />
                            {item?.active ? 'Disable' : 'Enable'}
                          </DropdownItem>
                          <DropdownItem onClick={() => handlePostDelete(item?.post)}>
                            <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                            {item?.deleted ? 'Undelete' : 'Delete'}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                );
              })
            ) : (
              <TableError />
            )}
          </tbody>
        </Table>

        {!isEmpty(reportedPosts) && <Paginate pageInfo={reportedPostsPagination} onChangePage={handleChangePage} />}
      </div>
    </>
  );
};

export default ReportedPostsList;
