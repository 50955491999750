import { Divider } from '@material-ui/core';
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import confirm from 'reactstrap-confirm';
import { buildURL, checkIfValueExist, printSerialNumbers, showImage } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { ACTIVE_AND_DELETED_ARRAY, GET_GENDER, taxonomyType, USER_GENDER_ARRAY } from '../../../utils/constants';
import { createStructuredSelector } from 'reselect';
import { citiesSelector, countriesSelector, regionsSelector, subAreasSelector } from 'containers/school/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getCities, getCountries, getRegions, getSubAreas } from 'containers/school/actions';
import AuthService from 'common/auth.service';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
});

const DoctorsListing = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const history = useHistory();
  toast.configure();
  const authservice = new AuthService();
  const token = authservice.getSessionCookie();

  const { countries, regions, cities, subAreas } = useSelector(stateSelector);

  const [doctorsList, setdoctorsList] = useState([]);
  const [doctorsPagination, setdoctorsPagination] = useState({});

  const [specialities, setSpecialities] = useState([]);

  const EXPERIENCE_YEARS = Array.from({ length: 60 }, (_, i) => ({ label: `${i + 1} Years`, value: i + 1 }));

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    isClaimed: { value: null, label: 'All' },
    isVerified: { value: null, label: 'All' },
    isChildRelated: { value: null, label: 'All' },
    gender: null,
    experience: null,
    name: '',
    areaOfExpertise: '',
    country: null,
    region: null,
    city: null,
    subArea: null,
    specialityId: null,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getSpecialitiesList, { loading: getSpecialitiesLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_HEALTHCARE_API_KEY,
      Authorization: `Bearer ${token}`,
    },
    onSuccess: (data) => {
      setSpecialities(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getDoctorsListRequest, { loading: getDoctorsListLoading }] = useRequest({
    url: `/healthcare/doctor/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setdoctorsList(data?.results);
      setdoctorsPagination(data?.pagination);
    },
    onError: () => {
      setdoctorsList([]);
      setdoctorsPagination({});
    },
  });

  const [deleteDoctorRequest, { loading: deleteDoctorLoading }] = useRequest({
    method: 'DELETE',
    onSuccess: () => {
      handleChangePage(1);
      toast.success(`Success! Doctor has been deleted.`);
    },
  });

  const [updateDoctorRequest, { loading: updateDoctorLoading }] = useRequest({
    method: 'PUT',
    onSuccess: () => {
      handleChangePage(1);
      toast.success(`Success! Doctor has been updated.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getDoctorsListRequest({
      body: {
        page,
        sortBy: 'updatedDate',
        sortOrder: -1,
        size: 20,
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        isClaimed: checkIfValueExist(filters.isClaimed?.value),
        isVerified: checkIfValueExist(filters.isVerified?.value),
        isChildRelated: checkIfValueExist(filters.isChildRelated?.value),
        name: checkIfValueExist(filters.name),
        areaOfExpertise: checkIfValueExist(filters.areaOfExpertise),
        country: checkIfValueExist(filters.country?.slug),
        region: checkIfValueExist(filters.region?.slug),
        city: checkIfValueExist(filters.city?.slug),
        subArea: checkIfValueExist(filters.subArea?.slug),
        gender: filters.gender !== null ? filters.gender : undefined,
        experience: filters.experience !== null ? filters.experience : undefined,
        specialityId: filters.specialityId,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getDoctorsListRequest({
      body: {
        page: 1,
        size: 20,
        sortBy: 'updatedDate',
        sortOrder: -1,
      },
    });
  };

  const handleDeleteDoctor = async (data) => {
    const result = await confirm({
      title: `${data?.deleted ? 'Undelete' : 'Delete'} Doctor`,
      message: `Are you sure, you want to ${data?.deleted ? 'Undelete' : 'Delete'} this Doctor?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      if (data?.deleted) {
        updateDoctorRequest({
          url: `/healthcare/doctor/admin/${data?.id}`,
          body: {
            deleted: !data?.deleted,
          },
        });
      } else {
        deleteDoctorRequest({ url: `/healthcare/doctor/admin/${data.id}` });
      }
    }
  };

  const handleActiveDoctor = (data) => {
    if (data?.active) {
      updateDoctorRequest({
        url: `/healthcare/doctor/admin/${data?.id}`,
        body: {
          active: false,
        },
      });
    } else {
      updateDoctorRequest({
        url: `/healthcare/doctor/admin/${data?.id}`,
        body: {
          active: true,
        },
      });
    }
  };

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      setFilters({ ...filters, country: country, region: null, city: null, subArea: null });
    } else {
      setFilters({ ...filters, country: undefined, region: null, city: null, subArea: null });
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: true,
          region: region.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );

      setFilters({ ...filters, region: region, city: null, subArea: null });
    } else {
      setFilters({ ...filters, region: undefined, city: null, subArea: null });
    }
  };
  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      dispatch(getSubAreas.trigger({ level: 4, city: city.name, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));

      setFilters({ ...filters, city: city, subArea: null });
    } else {
      setFilters({ ...filters, city: undefined, subArea: null });
    }
  };
  const onSubAreaSelect = (subArea) => {
    if (!isEmpty(subArea)) {
      setFilters({ ...filters, subArea: subArea });
    } else {
      setFilters({ ...filters, subArea: undefined });
    }
  };

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
    getSpecialitiesList({
      body: {
        page: 1,
        size: 1000,
        sortBy: 'name',
        sortOrder: 0,
        type: taxonomyType.DOCTOR_SPECIALITY,
      },
    });
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={getDoctorsListLoading || updateDoctorLoading || deleteDoctorLoading || getSpecialitiesLoading}
        />
        <Container fluid>
          <Breadcrumbs title="Doctors" breadcrumbItem="Doctors List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">isClaimed</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.isClaimed}
                      onChange={(value) => setFilters({ ...filters, isClaimed: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">isVerified</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.isVerified}
                      onChange={(value) => setFilters({ ...filters, isVerified: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">isChildRelated</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.isChildRelated}
                      onChange={(value) => setFilters({ ...filters, isChildRelated: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Gender</label>
                    <Select
                      options={USER_GENDER_ARRAY}
                      value={
                        filters.gender !== null ? USER_GENDER_ARRAY?.find((e) => e?.value === filters.gender) : null
                      }
                      onChange={(e) => setFilters({ ...filters, gender: e?.value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Name</label>
                    <Input
                      type="text"
                      placeholder="name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Area Of Expertise</label>

                    <Input
                      type="text"
                      placeholder="Area Of Expertise..."
                      value={filters.areaOfExpertise}
                      onChange={(e) => setFilters({ ...filters, areaOfExpertise: e.target.value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Experience years</label>
                    <Select
                      options={EXPERIENCE_YEARS}
                      value={
                        filters.experience !== null
                          ? EXPERIENCE_YEARS?.find((e) => e?.value === filters.experience)
                          : null
                      }
                      onChange={(e) => setFilters({ ...filters, experience: e?.value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <Label className="form-label">Speciality</Label>
                    <Select
                      options={specialities}
                      value={
                        filters.specialityId !== null
                          ? specialities?.find((e) => e?.value === filters.specialityId)
                          : null
                      }
                      onChange={(e) => setFilters({ ...filters, specialityId: e?.value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <Label className="form-label">Country</Label>
                    <Select isClearable options={countries} value={filters.country} onChange={onCountrySelect} />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <Label className="form-label  ">Region/State</Label>
                    <Select
                      isClearable
                      placeholder="Select..."
                      value={filters.region}
                      onChange={onRegionSelect}
                      options={regions}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <Label className="form-label  ">City</Label>
                    <Select
                      isClearable
                      placeholder="Select..."
                      value={filters.city}
                      onChange={onCitySelect}
                      options={cities}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <Label className="form-label  ">SubArea</Label>
                    <Select
                      isClearable
                      placeholder="Select..."
                      value={filters.subArea}
                      onChange={onSubAreaSelect}
                      options={subAreas}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{doctorsPagination?.totalCount} Doctors Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_DOCTOR}>
                  <i className="mdi mdi-plus me-1" />
                  Add Doctor
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-centered table-borderless ">
                    <thead>
                      <tr className="table-warning">
                        <th>S.No.</th>
                        <th></th>
                        <th>Doctor Name</th>
                        <th>Gender</th>
                        <th>Area of expertise</th>
                        <th>Experience</th>
                        <th>isChildRelated</th>
                        <th>isClaimed</th>
                        <th>isVerified</th>
                        <th>Active</th>
                        <th>Deleted</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(doctorsList) ? (
                        doctorsList?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{printSerialNumbers(doctorsPagination)[index]}</td>
                              <td>
                                {!isEmpty(data?.profileImage) && (
                                  <a
                                    target="_blank"
                                    rel="noreffer noreferrer"
                                    href={showImage(data?.profileImage?.original)}>
                                    <img src={showImage(data?.profileImage?.original)} width={40} height={40} />
                                  </a>
                                )}
                              </td>
                              <td>
                                <div className="d-flex">
                                  <div>
                                    <Link
                                      to={buildURL(SMKLinks.DOCTOR_DETAILS, {
                                        id: data?.id,
                                      })}>
                                      {data?.firstName} {data?.lastName}
                                    </Link>
                                    <br />
                                    {data?.slug}
                                  </div>

                                  {!isEmpty(data.slug) && (
                                    <div className="ms-2">
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${process.env.REACT_APP_THEPARENTZ_WEB_URL}/healthcare/${data.slug}-d500${data?.uid}`}>
                                        <i className="bx bx-link-external fs-4" />
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>{GET_GENDER[data?.gender]}</td>
                              <td>{data?.areaOfExpertise}</td>
                              <td>{data?.experience}</td>
                              <td>
                                <Badge className={data?.isChildRelated ? 'bg-success' : 'bg-danger'}>
                                  {data?.isChildRelated ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.isClaimed ? 'bg-success' : 'bg-danger'}>
                                  {data?.isClaimed ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.isVerified ? 'bg-success' : 'bg-danger'}>
                                  {data?.isVerified ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                  {data?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                  {data?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop " tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleActiveDoctor(data)}>
                                      <i
                                        className={`fas  ${
                                          data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                        }   m-1 pe-3`}
                                      />
                                      {data?.active ? 'Disable' : 'Enable'}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleDeleteDoctor(data)}>
                                      <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                      {data?.deleted ? 'Undelete' : 'Delete'}
                                    </DropdownItem>
                                    <Divider className="my-2" />

                                    <Link
                                      to={buildURL(SMKLinks.EDIT_DOCTOR, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </Link>
                                    <Link
                                      to={buildURL(SMKLinks.DOCTOR_DETAILS, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-eye text-primary m-1 pe-3 " />
                                        Details
                                      </DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          {!isEmpty(doctorsList) && <Paginate pageInfo={doctorsPagination} onChangePage={handleChangePage} />}
        </Container>
      </div>
    </>
  );
};

export default DoctorsListing;
