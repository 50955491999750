import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import Select from 'react-select';
import confirm from 'reactstrap-confirm';

import {
  buildURL,
  checkIfValueExist,
  printDate,
  printDateTime,
  printDateTimeTwoLines,
  printSerialNumbers,
  titleCaseIfExists,
} from 'utils/helper';
import { SMKLinks } from 'utils/links';
import { Divider } from '@material-ui/core';
import { toast } from 'react-toastify';
import TableError from 'components/TableError';
import { invert, isEmpty } from 'lodash';
import Loader from '../../../components/Loader';
import { useRouter } from '../../../hooks/useRouter';
import useRequest from '../../../hooks/useRequest';
import Paginate from '../../../components/Paginate';
import {
  ACTIVE_AND_DELETED_ARRAY,
  GET_GENDER,
  medicineTypes,
  sortBySearchData,
  taxonomyType,
} from '../../../utils/constants';

const MedicinesListing = (props) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [medicinesList, setmedicinesList] = useState([]);
  const [medicinesPagination, setmedicinesPagination] = useState({});

  // filters
  const [medicineTypeList, setmedicineTypeList] = useState([]);
  const [medicineSystemList, setmedicineSystemList] = useState([]);
  const [medicineClassificationList, setmedicineClassificationList] = useState([]);

  // Dropdown Api Call
  const [getmedicineTypeListRequest, { loading: getmedicineTypeListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setmedicineTypeList(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getmedicineSystemListRequest, { loading: getmedicineSystemListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setmedicineSystemList(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [getmedicineClassificationListRequest, { loading: getmedicineClassificationListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setmedicineClassificationList(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    sortBy: { value: 'createdDate', label: 'Created Date' },
    name: '',
    type: '',
    medicineTypeId: null,
    medicineSystemId: null,
    classificationId: null,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getmedicinesListRequest, { loading: getmedicinesListLoading }] = useRequest({
    url: `/healthcare/medicine/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setmedicinesList(data?.results);
      setmedicinesPagination(data?.pagination);
    },
  });

  const [updateMedicinesRequest, { loading: updateMedicinesLoading }] = useRequest({
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success(`Success! Medicine has been updated.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getmedicinesListRequest({
      body: {
        page,
        size: 20,
        sortOrder: 1,
        sortBy: filters.sortBy?.value,
        active: filters.active?.value,
        deleted: filters.deleted?.value,
        name: filters.name,
        type: filters.type?.value,
        medicineTypeId: filters.medicineTypeId?.value,
        medicineSystemId: filters.medicineSystemId?.value,
        classificationId: filters.classificationId?.value,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getmedicinesListRequest({
      body: {
        page: 1,
        size: 20,
        sortOrder: 1,
        active: defaultFilters.active?.value,
        deleted: defaultFilters.deleted?.value,
        sortBy: defaultFilters.sortBy?.value,
      },
    });
  };

  // Dropdowns data api call
  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);

    const searchPayload = {
      page: 1,
      size: 1000,
      sortBy: 'name',
      sortOrder: 0,
    };

    getmedicineTypeListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.MEDICINE_TYPE,
      },
    });
    getmedicineSystemListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.MEDICINE_SYSTEM,
      },
    });
    getmedicineClassificationListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.MEDICINE_CLASSFICATION,
      },
    });
  }, []);

  const handleUpdateMedicines = (data, key) => {
    if (data[key]) {
      updateMedicinesRequest({
        method: 'PUT',
        url: `/healthcare/medicine/admin/${data?.id}`,
        body: {
          [key]: false,
        },
      });
    } else {
      updateMedicinesRequest({
        method: key === 'deleted' ? 'DELETE' : 'PUT',
        url: `/healthcare/medicine/admin/${data?.id}`,
        body: {
          [key]: true,
        },
      });
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getmedicinesListLoading ||
            updateMedicinesLoading ||
            getmedicineTypeListLoading ||
            getmedicineSystemListLoading ||
            getmedicineClassificationListLoading
          }
        />
        <Container fluid>
          <Breadcrumbs title="Medicines" breadcrumbItem="Medicines List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Sort By</label>
                    <Select
                      options={sortBySearchData}
                      placeholder="Select sortBy..."
                      value={filters.sortBy}
                      onChange={(value) => setFilters({ ...filters, sortBy: value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Type</label>
                    <Select
                      options={Object.keys(medicineTypes)?.map((e) => ({
                        label: titleCaseIfExists(e),
                        value: medicineTypes[e],
                      }))}
                      placeholder="Select type..."
                      value={filters.type}
                      onChange={(value) => setFilters({ ...filters, type: value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Medicine type</label>
                    <Select
                      placeholder="Select medicine type..."
                      options={medicineTypeList}
                      value={filters.medicineTypeId}
                      onChange={(value) => setFilters({ ...filters, medicineTypeId: value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Medicine system</label>
                    <Select
                      placeholder="Select medicine system..."
                      options={medicineSystemList}
                      value={filters.medicineSystemId}
                      onChange={(value) => setFilters({ ...filters, medicineSystemId: value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Classifications</label>
                    <Select
                      placeholder="Select classifications..."
                      options={medicineClassificationList}
                      value={filters.classificationId}
                      onChange={(value) => setFilters({ ...filters, classificationId: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Name</label>
                    <Input
                      type="text"
                      placeholder="name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{medicinesPagination?.totalCount} Medicines Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_MEDICINE}>
                  <i className="mdi mdi-plus me-1" />
                  Add Medicine
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table  table-borderless ">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col" style={{ width: '100px' }}>
                          S.No.
                        </th>
                        <th scope="col"></th>
                        <th scope="col">Medicine Name / Slug</th>
                        <th scope="col">Type</th>
                        <th scope="col">Approved status</th>
                        <th scope="col">Medicine type</th>
                        {/* <th scope="col">Pack Size</th> */}
                        {/* <th scope="col">Manufacturer Name</th> */}
                        {/* <th scope="col">Composition</th> */}
                        {/* <th scope="col">rxRequried</th> */}
                        {/* <th scope="col">Medicine Systems</th> */}
                        {/* <th scope="col">Classifications</th> */}
                        <th scope="col">Created Date</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(medicinesList) ? (
                        medicinesList?.map((data, index) => {
                          return (
                            <tr key={index} className={!data?.active || data?.deleted ? 'bg-danger' : ''}>
                              <td>{printSerialNumbers(medicinesPagination)[index]}</td>
                              <td>
                                {data?.imageUrl && (
                                  <a href={data?.imageUrl} target="_blank">
                                    <img src={data?.imageUrl} width={40} height={40} />
                                  </a>
                                )}
                              </td>
                              <td>
                                <Link
                                  to={buildURL(SMKLinks.EDIT_MEDICINE, {
                                    id: data?.id,
                                  })}>
                                  {data?.name}
                                </Link>
                                <br />
                                {data?.slug}
                              </td>

                              <td>
                                <Badge className="bg-light p-2">{data?.type}</Badge>
                              </td>
                              <td align="center">
                                <i
                                  className={`fas  ${
                                    data?.approved ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'
                                  }   m-1 pe-3 fs-4`}
                                />
                              </td>
                              <td>{data?.medicineTypeString}</td>
                              {/* <td>{data?.packSize}</td> */}
                              {/* <td>{data?.manufacturerName}</td> */}
                              {/* <td>{data?.composition}</td> */}
                              {/* <td>{data?.rxRequried}</td> */}
                              {/* <td>
                                {data?.medicineSystems?.map((elem) => (
                                  <Badge className="bg-light m-1 p-2">{elem?.name}</Badge>
                                ))}
                              </td>
                              <td>
                                {data?.classifications?.map((elem) => (
                                  <Badge className="bg-light m-1 p-2">{elem?.name}</Badge>
                                ))}
                              </td> */}

                              <td>{printDate(data?.createdDate)}</td>

                              <td>
                                <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                  {data?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                  {data?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop " tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleUpdateMedicines(data, 'approved')}>
                                      <i
                                        className={`fas  ${
                                          data?.approved ? 'fa-thumbs-down text-danger' : 'fa-thumbs-up text-primary'
                                        }   m-1 pe-3`}
                                      />
                                      {data?.approved ? 'UnApprove' : 'Approve'}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleUpdateMedicines(data, 'active')}>
                                      <i
                                        className={`fas  ${
                                          data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                        }   m-1 pe-3`}
                                      />
                                      {data?.active ? 'Disable' : 'Enable (Active)'}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleUpdateMedicines(data, 'deleted')}>
                                      <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                      {data?.deleted ? 'Undelete' : 'Delete'}
                                    </DropdownItem>
                                    <Divider className="my-2" />

                                    <Link
                                      to={buildURL(SMKLinks.EDIT_MEDICINE, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={medicinesPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default MedicinesListing;
