import AuthService from 'common/auth.service';
import { userSelector } from 'common/globalComponents/selectors';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { convertEditorBlocksToHTML } from 'components/ContentCompiler';
import Loader from 'components/Loader';
import PageDetailCard from 'components/PageDetailsCard';
import SlugInput from 'components/SlugInput';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import config from 'config/config';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import SeoMetaData from 'containers/blog/BlogList/components/SeoMetaData';
import ContentEditor from 'containers/blog/BlogList/editor';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useRef, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import Sticky from 'react-stickynode';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { api } from 'utils/axios';
import { API_BASE_URL, LANGUAGE_ARRAY_WITH_STRING, MODULE_TYPE, STAGING_API_URL } from 'utils/constants';
import { getCurrentUserData, getTranslationLabel, titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';
import FaqAndPageSearch from '../components/FaqAndPageSearch';
import StaticPageSections from './StaticPageSections';
import confirm from 'reactstrap-confirm';

const stateSelector = createStructuredSelector({
  user: userSelector,
});

const AddUpdateStaticPage = () => {
  const history = useHistory();
  const { id: pageID } = useParams();
  const auth = new AuthService();
  const { apiKey } = getCurrentUserData();
  const isProd = process.env.REACT_APP_BACKEND_BASE_API_URL?.includes('schoolmykids.com');

  const { user } = useSelector(stateSelector);

  toast.configure();

  const [showContent, setShowContent] = useState(false);

  const [sections, setSections] = useState([]);
  const editorInstance = useRef(null);

  const [staticPageDetails, setStaticPageDetails] = useState();
  const [pageModuleList, setPageModuleList] = useState([]);
  const [pageSubModuleList, setPageSubModuleList] = useState([]);

  const validateSections = () => {
    if (!isEmpty(sections)) {
      for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
        const elem = sections[sectionIndex];

        // Check section metadata
        if (!isEmpty(elem?.metadata) && !isJSON(elem?.metadata)) {
          toast.error(`Please enter a valid metadata JSON in section: ${sectionIndex}`);
          return false;
        }

        // Check listItems metadata
        if (!isEmpty(elem?.listItems)) {
          for (let itemIndex = 0; itemIndex < elem.listItems.length; itemIndex++) {
            const item = elem.listItems[itemIndex];

            if (!isEmpty(item?.metadata) && !isJSON(item?.metadata)) {
              toast.error(`Please enter a valid metadata JSON in section: ${sectionIndex}, Item :${itemIndex}`);
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  const [getPageModule, { loading: getPageModuleLoading }] = useRequest({
    url: `/cms/admin/page/modules`,
    method: 'GET',
    onSuccess: (data) => {
      setPageModuleList(
        data?.map((e) => ({
          label: e.module,
          value: e.module,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [getPageSubModule, { loading: getPageSubModuleLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      setPageSubModuleList(
        data?.map((e) => ({
          label: e.subModule,
          value: e.subModule,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getStaticPageRequest, { loading: getStaticPageLoading }] = useRequest({
    url: `/cms/admin/page/${pageID}`,
    method: 'GET',
    onSuccess: async (data) => {
      setStaticPageDetails(data);
      setShowContent(!isEmpty(data?.content?.replaceAll('<div class="content-wrapper"></div>', '')?.trim()));

      const payload = !isEmpty(data?.sections)
        ? data?.sections
            ?.slice()
            ?.sort((a, b) => +a?.order - +b?.order)
            ?.map((elem) => {
              return {
                ...elem,
                metadata: !isEmpty(elem?.metadata) ? JSON.stringify(elem?.metadata, null, 2) : '',
                articleIds: !isEmpty(elem?.articleIds)
                  ? elem?.articleIds?.map((articleId) => {
                      const currentArticles = data?.articles?.find((a) => a?.id === articleId);
                      return !isEmpty(currentArticles)
                        ? {
                            id: currentArticles?.id,
                            title: currentArticles?.title,
                            slug: currentArticles?.slug,
                          }
                        : null;
                    })
                  : [],
                listItems: !isEmpty(elem?.listItems)
                  ? elem?.listItems
                      ?.slice()
                      ?.sort((a, b) => +a?.order - +b?.order)
                      ?.map((item) => ({
                        ...item,
                        metadata: !isEmpty(item?.metadata) ? JSON.stringify(item?.metadata, null, 2) : '',
                        articleIds: !isEmpty(item?.articleIds)
                          ? item?.articleIds?.map((articleId) => {
                              const currentArticles = data?.articles?.find((a) => a?.id === articleId);
                              return !isEmpty(currentArticles)
                                ? {
                                    id: currentArticles?.id,
                                    title: currentArticles?.title,
                                    slug: currentArticles?.slug,
                                  }
                                : null;
                            })
                          : [],
                      }))
                  : [],
              };
            })
        : [];

      setSections(payload);

      if (!editorInstance.current || !editorInstance.current.isReady) {
        return;
      }
      await editorInstance.current.isReady;
      if (!isEmpty(data?.contentJSON)) {
        await editorInstance.current.blocks.render({ blocks: data.contentJSON?.blocks });
      }
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [addStaticPageRequest, { loading: addStaticPageLoading }] = useRequest({
    url: `/cms/admin/page`,
    method: 'POST',
    onSuccess: () => {
      const url = SMKLinks.STATIC_PAGES_LIST;
      history.replace(url);
      toast.success('Success! Staic Page has been added.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [updatePageRequest, { loading: updatePageLoading }] = useRequest({
    url: `/cms/admin/page/${pageID}`,
    method: 'PUT',
    onSuccess: () => {
      toast.success('Success! Staic Page has been updated.');
      window.location.reload();
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const isJSON = (val) => {
    try {
      return typeof val === 'string' && JSON.parse(val);
    } catch (err) {
      toast.error(err);
      return false;
    }
  };

  const convertJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return parseObj;
    } catch (err) {
      return 'error';
    }
  };

  const getFormikValues = () => {
    return {
      active: staticPageDetails?.active !== undefined ? staticPageDetails.active : false,
      deleted: staticPageDetails?.deleted !== undefined ? staticPageDetails.deleted : false,
      isStaticContent: staticPageDetails?.isStaticContent !== undefined ? staticPageDetails.isStaticContent : false,
      module: !isEmpty(staticPageDetails?.module)
        ? {
            label: staticPageDetails?.module,
            value: staticPageDetails?.module,
          }
        : null,
      subModule: !isEmpty(staticPageDetails?.subModule)
        ? {
            label: staticPageDetails?.subModule,
            value: staticPageDetails?.subModule,
          }
        : null,

      title: staticPageDetails?.title || '',
      slug: staticPageDetails?.slug || '',
      contentJSON: staticPageDetails?.contentJSON || '',

      language: staticPageDetails?.language || LANGUAGE_ARRAY_WITH_STRING[0]?.value,
      translations: !isEmpty(staticPageDetails?.translations) ? staticPageDetails?.translations[0] : {},
      seoTitle: staticPageDetails?.seo?.title || '',
      seoDescription: staticPageDetails?.seo?.description || '',
      seoKeywords: !isEmpty(staticPageDetails?.seo?.keywords)
        ? staticPageDetails?.seo?.keywords?.split(', ')?.map((e) => ({
            label: e,
            value: e,
          }))
        : '',
      ogImage: staticPageDetails?.seo?.ogImage || {},
      image: staticPageDetails?.image || {},

      sections: staticPageDetails?.sections || [],
      metadata: !isEmpty(staticPageDetails?.metadata) ? JSON.stringify(staticPageDetails?.metadata, null, 2) : '{}',

      faqs: staticPageDetails?.faqs || null,
      pages: staticPageDetails?.pages || null,
    };
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getFormikValues(),
    onSubmit: async (values) => {
      if (!isJSON(values?.metadata)) {
        toast.error('Please enter a valid metadata JSON');
        return;
      }

      if (validateSections()) {
        const section_payload = !isEmpty(sections)
          ? sections?.map((elem) => ({
              ...elem,
              ...(!isEmpty(elem?.articleIds)
                ? {
                    articleIds: !isEmpty(elem?.articleIds) ? elem?.articleIds?.map((e) => e?.id) : [],
                  }
                : {}),
              metadata: !isEmpty(elem?.metadata) ? convertJSON(elem?.metadata) : null,
              listItems: !isEmpty(elem?.listItems)
                ? elem?.listItems?.map((item) => ({
                    ...item,
                    metadata: !isEmpty(item?.metadata) ? convertJSON(item?.metadata) : null,
                    articleIds: !isEmpty(item?.articleIds) ? item?.articleIds?.map((e) => e?.id) : [],
                  }))
                : [],
            }))
          : [];

        const updatedContentJSON = await editorInstance.current.save();

        const data = {
          ...values,
          content: convertEditorBlocksToHTML(updatedContentJSON),
          contentJSON: updatedContentJSON,
          module: values?.module?.value,
          subModule: values?.subModule?.value,
          imageId: !isEmpty(values?.image) ? values?.image?.id : !isEmpty(staticPageDetails) ? -1 : null,
          sections: section_payload,
          metadata: convertJSON(values?.metadata),
          translations: !isEmpty(values?.translations) ? [values?.translations?.id] : [],
          faqs: !isEmpty(values?.faqs) ? values?.faqs?.map((e) => ({ id: e?.id })) : null,
          pages: !isEmpty(values?.pages) ? values?.pages?.map((e) => ({ id: e?.id })) : null,
          seo: {
            title: !isEmpty(values?.seoTitle) ? values?.seoTitle : values?.title,
            description: !isEmpty(values?.seoDescription) ? values?.seoDescription : '',
            keywords: !isEmpty(values?.seoKeywords) ? values?.seoKeywords?.map((e) => e?.value).join(', ') : '',
            ...(!isEmpty(values?.ogImage)
              ? {
                  ogImageId: values?.ogImage?.id,
                  ogImage: undefined,
                }
              : { ogImageId: !isEmpty(staticPageDetails) ? -1 : null }),
          },
          seoTitle: undefined,
          seoDescription: undefined,
          seoKeywords: undefined,
          ogImage: undefined,
          image: undefined,
        };

        if (isEmpty(pageID)) {
          addStaticPageRequest({
            body: {
              ...data,
            },
          });
        } else {
          updatePageRequest({
            body: {
              ...data,
            },
          });
        }
      }
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Please provide title'),
      slug: Yup.string().required('Please provide slug'),
      module: Yup.object()
        .required('Please provide module ')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(pageID)) {
      getStaticPageRequest();
    }
  }, [pageID]);

  useEffect(() => {
    getPageModule();
  }, []);

  useEffect(() => {
    if (!isEmpty(formik.values.module?.value) && !formik.values.module?.__isNew__) {
      getPageSubModule({
        url: `/cms/admin/page/${formik?.values?.module?.value}/sub-modules`,
      });
    }
  }, [formik.values.module?.value]);

  const handleCopyDataOnStaging = async () => {
    const tokenResponse = await api({
      method: 'POST',
      url: `${config.apiEndpoint}/cms/admin/page/get-push-token`,
    });

    if (tokenResponse?.success !== false) {
      const customHeaders = {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
        Authorization: `Bearer ${tokenResponse}`,
      };

      const searchResponse = await fetch(`${STAGING_API_URL}/cms/admin/page/push`, {
        method: 'POST',
        headers: customHeaders,
        body: JSON.stringify({
          ...staticPageDetails,
        }),
      });

      const searchResponseData = await searchResponse.json();

      if (searchResponseData?.success !== false) {
        toast.success('Page added successfully!');
      } else {
        toast.error('Failed to get page details.');
      }
    } else {
      toast.error('Failed to get page details.');
    }
  };

  const handlePublishPage = async () => {
    const result = await confirm({
      title: 'Publish Page',
      message: 'Are you sure, you want to publish this page?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-primary',
    });
    if (result) {
      formik.setFieldValue('active', true);
      formik.setFieldValue('deleted', false);
      formik.handleSubmit();
    }
  };

  const handleUnPublishPage = async () => {
    const result = await confirm({
      title: 'UnPublish Page',
      message: 'Are you sure, you want to unpublish this page?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      formik.setFieldValue('active', false);
      formik.handleSubmit();
    }
  };
  const handleMoveToTrash = async () => {
    const result = await confirm({
      title: 'Delete Page',
      message: 'Are you sure, you want to delete this page?',
      confirmText: 'Confirm',
      confirmColor: 'danger',
      cancelColor: 'link text-danger',
    });
    if (result) {
      formik.setFieldValue('active', false);
      formik.setFieldValue('deleted', true);
      formik.handleSubmit();
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getStaticPageLoading ||
            addStaticPageLoading ||
            updatePageLoading ||
            getPageModuleLoading ||
            getPageSubModuleLoading
          }
        />
        <Container fluid>
          <Breadcrumbs title="Page" breadcrumbItem={`${isEmpty(pageID) ? 'Add' : 'Edit'}  Page`} />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <div id="static_page">
                  <Card>
                    <CardBody>
                      <Row className="align-items-center">
                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="title" className="form-label  ">
                              Title <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="title"
                              name="title"
                              type="text"
                              className="form-control"
                              placeholder="Enter title..."
                              invalid={!!(formik.touched.title && formik.errors.title)}
                              {...formik.getFieldProps('title')}
                            />
                            {formik.errors.title && formik.touched.title && (
                              <FormFeedback className="d-block">{formik.errors.title}</FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={12} className="mb-4">
                          <SlugInput
                            required={true}
                            formik={formik}
                            ID={pageID}
                            nameValue={formik.values.title}
                            formGroupClassName={'mt-0'}
                          />
                        </Col>

                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <div>
                              <Label className="form-label me-2" htmlFor={`content`}>
                                Content
                              </Label>
                              <Input
                                id="content"
                                type="checkbox"
                                checked={showContent}
                                onChange={() => setShowContent(!showContent)}
                              />
                            </div>
                            <div className={`${showContent ? '' : 'd-none'} p-3 rounded-2 form-control`}>
                              <ContentEditor editorInstance={editorInstance} forType={MODULE_TYPE.METADATA} />
                            </div>

                            {formik.errors.content && formik.touched.content && (
                              <FormFeedback className="d-block">{formik.errors.content}</FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="metadata" className="form-label  ">
                              Metadata
                            </Label>
                            <Input
                              rows={6}
                              id="metadata"
                              name="metadata"
                              type="textarea"
                              className="form-control"
                              placeholder="Meta Data..."
                              invalid={!!(formik.touched.metadata && formik.errors.metadata)}
                              {...formik.getFieldProps('metadata')}
                            />
                            {formik.errors.metadata && formik.touched.metadata && (
                              <FormFeedback className="d-block">{formik.errors.metadata}</FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <SeoMetaData formik={formik} forType={MODULE_TYPE.METADATA} />

                  <FaqAndPageSearch
                    formik={formik}
                    label={'faqs'}
                    type={'Faq'}
                    heading={'Link FAQs'}
                    apiURL={`${API_BASE_URL}/cms/admin/faq/search`}
                    filters={{
                      language: formik.values.language,
                    }}
                  />

                  <FaqAndPageSearch
                    formik={formik}
                    label={'pages'}
                    type={'Page'}
                    heading={'Link Static Contents'}
                    apiURL={`${API_BASE_URL}/cms/admin/page/search`}
                    filters={{
                      isStaticContent: true,
                      language: formik.values.language,
                    }}
                  />

                  <StaticPageSections sections={sections} setSections={setSections} />
                </div>
              </Col>
              <Col md={3}>
                {isBrowser && (
                  <Sticky enabled={isBrowser} top={100} bottomBoundary={`#static_page`} innerZ={99}>
                    <div>
                      <Card>
                        <CardBody>
                          <div>
                            {user.entityPermissions?.cms?.create && (
                              <Button
                                type="button"
                                onClick={() => {
                                  formik.handleSubmit();
                                }}
                                color="primary"
                                className="mb-3 w-100">
                                {pageID ? 'Save' : 'Add'}
                              </Button>
                            )}

                            <Button
                              type="button"
                              className="w-100"
                              color="light"
                              onClick={() => {
                                history.replace(SMKLinks.STATIC_PAGES_LIST);
                              }}>
                              Cancel
                            </Button>
                          </div>
                        </CardBody>
                      </Card>

                      {pageID && (
                        <Card>
                          <CardBody>
                            {user.entityPermissions?.cms?.admin && pageID && !staticPageDetails?.active && (
                              <Button type="button" color="success" className="mb-3 w-100" onClick={handlePublishPage}>
                                Publish
                              </Button>
                            )}
                            {user.entityPermissions?.cms?.publish && pageID && staticPageDetails?.active && (
                              <Button
                                type="button"
                                outline
                                color="primary"
                                className="mb-3 w-100"
                                onClick={handleUnPublishPage}>
                                UnPublish
                              </Button>
                            )}
                            {user.entityPermissions?.cms?.delete && pageID && !staticPageDetails?.deleted && (
                              <Button type="button" color="danger" className="mb-3 w-100" onClick={handleMoveToTrash}>
                                Move to trash
                              </Button>
                            )}
                          </CardBody>
                        </Card>
                      )}

                      <PageDetailCard data={staticPageDetails} />

                      <Card>
                        <CardBody>
                          <Row>
                            <Col md={12}>
                              <FormGroup className="mb-4">
                                <Label for="module" className="form-label  ">
                                  Module <span className="text-danger">*</span>
                                </Label>
                                <CreatableSelect
                                  id="module"
                                  options={pageModuleList}
                                  value={formik.values.module}
                                  onChange={(e) => formik.setFieldValue('module', e)}
                                />
                                {formik.errors.module && formik.touched.module && (
                                  <FormFeedback className="d-block">{formik.errors.module}</FormFeedback>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup className="mb-4">
                                <Label for="subModule" className="form-label  ">
                                  Sub module
                                </Label>
                                <CreatableSelect
                                  id="subModule"
                                  options={pageSubModuleList}
                                  value={formik.values.subModule}
                                  onChange={(e) => formik.setFieldValue('subModule', e)}
                                />
                                {formik.errors.subModule && formik.touched.subModule && (
                                  <FormFeedback className="d-block">{formik.errors.subModule}</FormFeedback>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <Col md={12}>
                            <CardTitle className="mb-3">Language </CardTitle>
                            {LANGUAGE_ARRAY_WITH_STRING?.map((option, i) => (
                              <div key={`language_${i}`} className="form-check mb-3 d-inline-block me-4">
                                <input
                                  type="radio"
                                  id={`language_${option?.value}`}
                                  name="language"
                                  className="form-check-input"
                                  checked={formik.values.language === option?.value}
                                  onChange={() => formik.setFieldValue('language', option?.value)}
                                />
                                <label className="form-check-label" htmlFor={`language_${option?.value}`}>
                                  {option?.label}
                                </label>
                              </div>
                            ))}
                          </Col>

                          {['isStaticContent']?.map((elem, index) => (
                            <FormGroup className={`mb-3`} key={index}>
                              <Label className="mb-3">
                                {elem === 'isStaticContent' ? 'isStaticContent' : titleCaseIfExists(elem)}{' '}
                              </Label>
                              <div className="d-flex align-items-center">
                                {[true, false].map((option, i) => (
                                  <div key={`${elem}_${i}`} className="form-check d-inline-block me-4">
                                    <input
                                      type="radio"
                                      id={`${elem}_${option}`}
                                      name={elem}
                                      className="form-check-input"
                                      checked={formik.values[elem] === option}
                                      onChange={() => formik.setFieldValue(`${elem}`, option)}
                                    />
                                    <label className="form-check-label" htmlFor={`${elem}_${option}`}>
                                      {option ? 'Yes' : 'No'}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </FormGroup>
                          ))}
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <div className="mb-3">
                            <CardTitle className="mb-3">Translation </CardTitle>
                            <SmkAsyncSelect
                              isDisabled={formik.values.language === 'hi'}
                              acceptedKey={'title'}
                              acceptedValue={'id'}
                              placeholder="Type page name.."
                              onChange={(res) => {
                                formik.setFieldValue('translations', res);
                              }}
                              fetchUrl={`${API_BASE_URL}/cms/admin/page/search`}
                              filters={{
                                autoComplete: true,
                                active: true,
                                page: 1,
                                size: 500,
                                sortBy: 'id',
                                sortOrder: 0,
                                isStaticContent: formik.values.isStaticContent,
                                language: formik.values.language === 'en' ? 'hi' : 'en',
                              }}
                              searchKeyName="name"
                              value={
                                !isEmpty(formik?.values?.translations)
                                  ? {
                                      label: getTranslationLabel(formik?.values?.translations),
                                      value: formik?.values?.translations?.id,
                                    }
                                  : ''
                              }
                            />

                            {!isEmpty(formik?.values?.translations) && (
                              <div className="mt-3">
                                <div className="card bg-light">
                                  <div className="card-body rounded-2">
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`/pages/edit/${formik?.values?.translations?.id}`}>
                                      <p className="mb-1">
                                        <span className="text-dark">Language:</span>{' '}
                                        {formik?.values?.translations?.language}
                                      </p>
                                      <p className="mb-1">
                                        <span className="text-dark">ID:</span> {formik?.values?.translations?.id}
                                      </p>
                                      <p className="mb-1">
                                        <span className="text-dark">Title:</span>{' '}
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: getTranslationLabel(formik?.values?.translations),
                                          }}
                                        />
                                      </p>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </CardBody>
                      </Card>

                      <AssetImageUploader
                        forType={MODULE_TYPE.METADATA}
                        formik={formik}
                        fieldName={'image'}
                        label={'Select Image'}
                        showOriginalInput={true}
                      />

                      {/* {user.entityPermissions?.cms?.admin && isProd && staticPageDetails?.id && ( */}
                      {user.entityPermissions?.cms?.admin && staticPageDetails?.id && (
                        <Card>
                          <CardBody>
                            <Button type="button" onClick={handleCopyDataOnStaging} color="success" className="w-100">
                              Copied to staging
                            </Button>
                          </CardBody>
                        </Card>
                      )}
                    </div>
                  </Sticky>
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default AddUpdateStaticPage;
