import DynamicSelect from 'components/DynamicSelect';
import Loader from 'components/Loader';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { API_BASE_URL, GENDER, MODULE_TYPE, taxonomyType } from 'utils/constants';
import { handleSaveAttachement, titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';

const UpdateDoctor = () => {
  const history = useHistory();
  const { id: doctorId } = useParams();

  const [doctorDetails, setdoctorDetails] = useState();

  const genderArray = Object.keys(GENDER)?.map((elem) => ({
    label: titleCaseIfExists(elem),
    value: GENDER[elem],
  }));

  const [getDoctorRequest, { loading: getDoctorLoading }] = useRequest({
    url: `/healthcare/doctor/admin/${doctorId}`,
    method: 'GET',
    onSuccess: (data) => {
      setdoctorDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addDoctorRequest, { loading: addDoctorLoading }] = useRequest({
    url: `/healthcare/doctor/admin`,
    method: 'POST',
    onSuccess: () => {
      history.replace(SMKLinks.DOCTORS_LISTING);
      toast.success('Success! Doctor has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateDoctorRequest, { loading: updateDoctorLoading }] = useRequest({
    url: `/healthcare/doctor/admin/${doctorId}`,
    method: 'PUT',
    onSuccess: () => {
      window.location.reload();
      toast.success('Success! Doctor has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => {
    return {
      ...doctorDetails,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    isChildRelated: false,
    isClaimed: false,
    isVerified: false,
    // language: null,
    salutation: '',
    firstName: '',
    lastName: '',
    gender: GENDER.ALL_GENDER,
    email: '',
    phoneNumber: '',
    profileImage: null,
    degree: '',
    experience: '',
    experienceYear: null,
    bio: '',
    // refSource: '',
    // refId: '',
    specializations: null,
    awards: null,
    educations: null,
    services: null,
    registration: null,
    experiences: null,
    memberships: null,
    trainings: null,
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(doctorDetails) ? getInitialValues() : editInitialValues(),
    onSubmit: async (values) => {
      const payload = {
        ...values,
      };
      if (isEmpty(doctorId)) {
        addDoctorRequest({
          body: payload,
        });
      } else {
        updateDoctorRequest({
          body: payload,
        });
      }
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required('Please provide First name')
        .min(2, 'First name should be at least 2 characters long')
        .max(128, 'First name should be at most 128 characters long'),
      lastName: Yup.string()
        .required('Please provide Last name')
        .min(2, 'Last name should be at least 2 characters long')
        .max(128, 'Last name should be at most 128 characters long'),
      email: Yup.string()
        .email()
        .required('Please provide email'),
      phoneNumber: Yup.string()
        .matches(/^[1-9]\d{0,14}$/, 'Phone number must be up to 15 digits and positive')
        .required('Phone number is required'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(doctorId)) {
      getDoctorRequest();
    }
  }, [doctorId]);

  const handleRemoveSpecialization = (id) => {
    const updatedData = formik.values.specialities?.filter((item) => item.id !== id);
    formik.setFieldValue('specialities', updatedData);
  };

  const handleSelectSpecializationChange = (res) => {
    const categories = formik.values?.specialities ? [...formik.values?.specialities] : [];

    let updatedData = null;
    if (!categories.some((item) => item?.id === res?.id)) {
      updatedData = [res, ...categories];
    } else {
      toast.error('Speciality already added!');
      updatedData = categories;
      return;
    }
    formik.setFieldValue('specialities', updatedData);

    const specialityiesName = !isEmpty(updatedData) ? updatedData?.map((e) => e?.name) : [];
    const specializationsValue = !isEmpty(doctorDetails?.specializations)
      ? [...doctorDetails?.specializations, res?.name]
      : [...(!isEmpty(formik.values.specializations) ? formik.values.specializations : []), res?.name];

    formik.setFieldValue('areaOfExpertise', specialityiesName?.join(', '));
    formik.setFieldValue(
      'specializations',
      specializationsValue?.filter((item, index, self) => index === self.findIndex((t) => t === item))
    );
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={addDoctorLoading || getDoctorLoading || updateDoctorLoading} />
        <Container fluid>
          <Breadcrumbs title="Doctor" breadcrumbItem={`${isEmpty(doctorId) ? 'Add' : 'Edit'}  Doctor`} />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="salutation" className="form-label  ">
                            Salutation
                          </Label>
                          <Input
                            id="salutation"
                            type="text"
                            className="form-control"
                            placeholder="Salutation..."
                            invalid={!!(formik.touched.salutation && formik.errors.salutation)}
                            {...formik.getFieldProps('salutation')}
                          />
                          <FormFeedback className="d-block">{formik.errors.salutation}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="firstName" className="form-label  ">
                            First Name
                          </Label>
                          <Input
                            id="firstName"
                            type="text"
                            className="form-control"
                            placeholder="First Name..."
                            invalid={!!(formik.touched.firstName && formik.errors.firstName)}
                            {...formik.getFieldProps('firstName')}
                          />
                          <FormFeedback className="d-block">{formik.errors.firstName}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="lastName" className="form-label  ">
                            Last Name
                          </Label>
                          <Input
                            id="lastName"
                            type="text"
                            className="form-control"
                            placeholder="Last Name..."
                            invalid={!!(formik.touched.lastName && formik.errors.lastName)}
                            {...formik.getFieldProps('lastName')}
                          />
                          <FormFeedback className="d-block">{formik.errors.lastName}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="gender" className="form-label">
                            Gender
                          </Label>
                          <Select
                            id="gender"
                            options={genderArray}
                            value={genderArray?.find((e) => e?.value === formik.values.gender)}
                            onChange={(obj) => formik.setFieldValue('gender', obj?.value)}
                          />

                          <FormFeedback className="d-block">{formik.errors.gender}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="email" className="form-label  ">
                            Email
                          </Label>
                          <Input
                            id="email"
                            type="text"
                            className="form-control"
                            placeholder="Email..."
                            invalid={!!(formik.touched.email && formik.errors.email)}
                            {...formik.getFieldProps('email')}
                          />
                          <FormFeedback className="d-block">{formik.errors.email}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="phoneNumber" className="form-label  ">
                            Phone Number
                          </Label>
                          <Input
                            id="phoneNumber"
                            type="number"
                            className="form-control"
                            placeholder="Phone Number..."
                            invalid={!!(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                            {...formik.getFieldProps('phoneNumber')}
                          />
                          <FormFeedback className="d-block">{formik.errors.phoneNumber}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="degree" className="form-label  ">
                            Degree
                          </Label>
                          <Input
                            id="degree"
                            type="text"
                            className="form-control"
                            placeholder="Degree..."
                            invalid={!!(formik.touched.degree && formik.errors.degree)}
                            {...formik.getFieldProps('degree')}
                          />
                          <FormFeedback className="d-block">{formik.errors.degree}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={9}>
                        <FormGroup className="mb-4">
                          <Label for="experience" className="form-label  ">
                            Experience
                          </Label>
                          <Input
                            id="experience"
                            type="text"
                            className="form-control"
                            placeholder="Experience..."
                            invalid={!!(formik.touched.experience && formik.errors.experience)}
                            {...formik.getFieldProps('experience')}
                          />
                          <FormFeedback className="d-block">{formik.errors.experience}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup className="mb-4">
                          <Label for="experienceYear" className="form-label  ">
                            Experience Years
                          </Label>
                          <Input
                            id="experienceYear"
                            type="number"
                            className="form-control"
                            placeholder="Experience Years..."
                            invalid={!!(formik.touched.experienceYear && formik.errors.experienceYear)}
                            {...formik.getFieldProps('experienceYear')}
                          />
                          <FormFeedback className="d-block">{formik.errors.experienceYear}</FormFeedback>
                        </FormGroup>
                      </Col>
                      {/* <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="refSource" className="form-label  ">
                            Ref Source
                          </Label>
                          <Input
                            id="refSource"
                            type="text"
                            className="form-control"
                            placeholder="Ref Source..."
                            invalid={!!(formik.touched.refSource && formik.errors.refSource)}
                            {...formik.getFieldProps('refSource')}
                          />
                          <FormFeedback className="d-block">{formik.errors.refSource}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="refId" className="form-label  ">
                            Ref ID
                          </Label>
                          <Input
                            id="refId"
                            type="text"
                            className="form-control"
                            placeholder="Ref ID..."
                            invalid={!!(formik.touched.refId && formik.errors.refId)}
                            {...formik.getFieldProps('refId')}
                          />
                          <FormFeedback className="d-block">{formik.errors.refId}</FormFeedback>
                        </FormGroup>
                      </Col> */}

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="bio" className="form-label  ">
                            Bio
                          </Label>
                          <Input
                            id="bio"
                            type="textarea"
                            rows={6}
                            className="form-control"
                            placeholder="Bio..."
                            invalid={!!(formik.touched.bio && formik.errors.bio)}
                            {...formik.getFieldProps('bio')}
                          />
                          <FormFeedback className="d-block">{formik.errors.bio}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <div className="bg-light p-4 rounded-2 mb-4">
                          <div className="d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0">Specialities</h5>
                          </div>
                          <SmkAsyncSelect
                            isHealthcare={true}
                            acceptedKey={'name'}
                            acceptedValue={'id'}
                            searchKeyName="name"
                            placeholder="Type speciality name.."
                            fetchUrl={`${API_BASE_URL}/common/admin/taxonomy/search`}
                            filters={{
                              autoComplete: true,
                              page: 1,
                              size: 1000,
                              sortBy: 'name',
                              sortOrder: 0,
                              type: taxonomyType.DOCTOR_SPECIALITY,
                            }}
                            onChange={handleSelectSpecializationChange}
                          />

                          {!isEmpty(formik.values?.areaOfExpertise) && (
                            <h6 className="mt-3">Area of expertise : {formik.values?.areaOfExpertise}</h6>
                          )}

                          {!isEmpty(formik.values.specialities) &&
                            formik.values.specialities?.slice()?.map((item, index) => (
                              <div
                                key={item.uid}
                                className="bg-white rounded-2 p-3 d-flex justify-content-between align-items-center mt-3">
                                <h6 className="mb-0">
                                  {index + 1}. {item.name}
                                  {!isEmpty(item.slug) && ` - ( ${item.slug} )`}
                                </h6>
                                <button
                                  className="btn btn-md btn-danger"
                                  type="button"
                                  onClick={() => handleRemoveSpecialization(item.id)}>
                                  Remove
                                </button>
                              </div>
                            ))}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="bg-light p-4 rounded-2 mb-4">
                          <DynamicSelect
                            formik={formik}
                            keyName={'specializations'}
                            label={'Specializations'}
                            doctorDetails={doctorDetails}
                            defaultValues={formik.values.specializations}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="bg-light p-4 rounded-2 mb-4">
                          <DynamicSelect
                            formik={formik}
                            keyName={'awards'}
                            label={'Awards'}
                            doctorDetails={doctorDetails}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="bg-light p-4 rounded-2 mb-4">
                          <DynamicSelect
                            formik={formik}
                            keyName={'educations'}
                            label={'Educations'}
                            doctorDetails={doctorDetails}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="bg-light p-4 rounded-2 mb-4">
                          <DynamicSelect
                            formik={formik}
                            keyName={'services'}
                            label={'Services'}
                            doctorDetails={doctorDetails}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="bg-light p-4 rounded-2 mb-4">
                          <DynamicSelect
                            formik={formik}
                            keyName={'registration'}
                            label={'Registration'}
                            doctorDetails={doctorDetails}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="bg-light p-4 rounded-2 mb-4">
                          <DynamicSelect
                            formik={formik}
                            keyName={'experiences'}
                            label={'Experiences'}
                            doctorDetails={doctorDetails}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="bg-light p-4 rounded-2 mb-4">
                          <DynamicSelect
                            formik={formik}
                            keyName={'memberships'}
                            label={'Memberships'}
                            doctorDetails={doctorDetails}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="bg-light p-4 rounded-2 mb-4">
                          <DynamicSelect
                            formik={formik}
                            keyName={'trainings'}
                            label={'Trainings'}
                            doctorDetails={doctorDetails}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="w-100 mb-3">
                      {`${isEmpty(doctorId) ? 'Add' : 'Update'}`} Doctor
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.DOCTORS_LISTING);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    {[
                      {
                        name: 'Active',
                        value: 'active',
                      },
                      {
                        name: 'Deleted',
                        value: 'deleted',
                      },
                      {
                        name: 'isChildRelated',
                        value: 'isChildRelated',
                      },
                      {
                        name: 'isClaimed',
                        value: 'isClaimed',
                      },
                      {
                        name: 'isVerified',
                        value: 'isVerified',
                      },
                    ]?.map((data) => (
                      <div>
                        <CardTitle className="mb-3">{data?.name}</CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`${data?.value}_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`${data?.value}_${option}`}
                              name={data?.value}
                              className="form-check-input"
                              checked={formik.values[data?.value] === option}
                              onChange={() => formik.setFieldValue(data?.value, option)}
                            />
                            <label className="form-check-label" htmlFor={`${data?.value}_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </div>
                    ))}
                  </CardBody>
                </Card>
                {/* 
                <Card>
                  <CardBody>
                    <div>
                      <FormGroup c>
                        <Label for="language" className="form-label">
                          Language
                        </Label>
                        <Select
                          id="language"
                          options={LANGUAGE_ARRAY_WITH_STRING}
                          value={formik.values.language}
                          onChange={(language) => formik.setFieldValue('language', language)}
                        />
                        {formik.touched.language && (
                          <div className="invalid-feedback d-block">{formik.errors.language}</div>
                        )}
                      </FormGroup>
                    </div>
                  </CardBody>
                </Card> */}

                <AssetImageUploader
                  isAsset={false}
                  forType={MODULE_TYPE.METADATA}
                  formik={formik}
                  fieldName={'profileImage'}
                  label={'Select Profile Image'}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateDoctor;
