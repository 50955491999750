import AuthService from 'common/auth.service';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { getCurrentUserData, titleCaseIfExists } from 'utils/helper';
import config from '../../../config/config';

const HeartBeats = () => {
  const [heartbeatsType, setHeartbeatsType] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [errorStates, setErrorStates] = useState({});
  const isInitialFetchDone = useRef(false);

  const commonHeartbeats = ['admin-tools', 'notification', 'common', 'user', 'cms', 'forum', 'subscription'];
  const smkHeartbeats = ['school', 'college', 'ptable', 'worksheet', 'calculator', 'whiteboard', 'worksheet-generator'];
  const tpzHeartbeats = ['baby-name', 'patient-tracking', 'document', 'nutrition', 'ask-tpz', 'healthcare'];
  const rhaHeartbeats = ['rha'];

  const auth = new AuthService();
  const { apiKey } = getCurrentUserData();

  useEffect(() => {
    setHeartbeatsType(
      [
        commonHeartbeats.map((c) => ({ type: 'common', name: c })),
        smkHeartbeats.map((c) => ({ type: 'smk', name: c })),
        tpzHeartbeats.map((c) => ({ type: 'tpz', name: c })),
        rhaHeartbeats.map((c) => ({ type: 'rha', name: c })),
      ].flat()
    );
  }, []);

  // Function to handle API requests with a timeout
  const apiWithTimeout = (apiCall, timeout = 5000) => {
    return Promise.race([
      apiCall,
      new Promise((_, reject) =>
        setTimeout(() => {
          reject(new Error('Request timed out'));
        }, timeout)
      ),
    ]);
  };

  const callHeartbeatApi = async (elem) => {
    if (loadingStates[elem.name]) return;

    setLoadingStates((prev) => ({ ...prev, [elem.name]: true }));
    setErrorStates((prev) => ({ ...prev, [elem.name]: false }));

    try {
      const response = await apiWithTimeout(
        fetch(`${config.apiEndpoint}/${elem.name}/heartbeat`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.getSessionCookie()}`,
            'x-api-key': apiKey,
          },
        })
      );

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const data = await response.json();
      setHeartbeatsType((prev) => prev.map((e) => (e.name === elem.name ? { ...elem, live: data.alive || false } : e)));
    } catch (err) {
      setErrorStates((prev) => ({ ...prev, [elem.name]: true }));
    } finally {
      setLoadingStates((prev) => ({ ...prev, [elem.name]: false }));
    }
  };

  const fetchAllApis = async (heartbeats) => {
    heartbeats.forEach((elem) => {
      callHeartbeatApi(elem);
    });
  };

  useEffect(() => {
    if (!isInitialFetchDone.current && heartbeatsType.length > 0) {
      fetchAllApis(heartbeatsType);
      isInitialFetchDone.current = true;
    }
  }, [heartbeatsType]);

  const userTypeHeartBeats = [
    {
      name: 'Common',
      slug: 'common',
      col: 4,
    },
    {
      name: 'SchoolMykids',
      slug: 'smk',
      col: 4,
    },
    {
      name: 'TheParentz',
      slug: 'tpz',
      col: 4,
    },
    {
      name: 'RHA',
      slug: 'rha',
      col: 4,
    },
  ];

  return (
    <>
      {userTypeHeartBeats.map((type) => (
        <Col md={type.col} key={type.slug}>
          <Card>
            <CardBody>
              <h4 className="border-bottom mb-2 bg-light text-center py-3 rounded-2">{type.name}</h4>
              <Row>
                {heartbeatsType
                  .filter((e) => e.type === type.slug)
                  .map((elem, index) => (
                    <Col md={12} className="d-flex align-items-center py-2 mb-2" key={index}>
                      <i
                        style={{ fontSize: '24px' }}
                        className={`me-2 ${
                          elem.live ? 'bx bxs-check-circle text-success' : 'bx bxs-error-circle text-danger'
                        }`}
                      />

                      <div className="d-flex align-items-center justify-content-between w-100">
                        <h5 className="ms-3 mb-0">{titleCaseIfExists(elem.name)}</h5>
                        <Button
                          color={'light'}
                          className="btn-sm me-2"
                          onClick={() => callHeartbeatApi(elem)}
                          disabled={loadingStates[elem.name]}>
                          {loadingStates[elem.name] ? 'Checking...' : 'Check'}
                        </Button>
                      </div>
                    </Col>
                  ))}
              </Row>
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default HeartBeats;
