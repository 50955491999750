import { USER_ROLE } from '../../utils/constants';
import BlogList from './BlogList';
import ExpertsBlogList from './BlogList/expertsBlogsList';
import UpdateBlog from './BlogList/UpdateBlog';
import BlogPreviousVersion from './BlogPreviousVersion';
import BlogCategoriesList from './CategoryList';
import AddUpdateBlogCategory from './CategoryList/addCategory';
import OTHER_LINKS from './links';
import BlogTagsList from './TagsList';
import AddUpdateBlogTag from './TagsList/addTags';

export default [
  // Blog
  {
    path: OTHER_LINKS.BLOG_LIST,
    exact: true,
    component: BlogList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.EXPERTS_INVOLVED_BLOG_LIST,
    exact: true,
    component: ExpertsBlogList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.ADD_BLOG,
    exact: true,
    component: UpdateBlog,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.EDIT_BLOG,
    exact: true,
    component: UpdateBlog,
    role: [USER_ROLE.ADMIN],
  },

  // Blog Categories
  {
    path: OTHER_LINKS.BLOG_CATEGORIES_LIST,
    exact: true,
    component: BlogCategoriesList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.ADD_BLOG_CATEGORY,
    exact: true,
    component: AddUpdateBlogCategory,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.EDIT_BLOG_CATEGORY,
    exact: true,
    component: AddUpdateBlogCategory,
    role: [USER_ROLE.ADMIN],
  },
  // Blog Tags
  {
    path: OTHER_LINKS.BLOG_TAGS_LIST,
    exact: true,
    component: BlogTagsList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.ADD_BLOG_TAG,
    exact: true,
    component: AddUpdateBlogTag,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.EDIT_BLOG_TAG,
    exact: true,
    component: AddUpdateBlogTag,
    role: [USER_ROLE.ADMIN],
  },

  // blog previous version
  {
    path: OTHER_LINKS.BLOG_PREVIOUS_VERSION_VIEW,
    exact: true,
    component: BlogPreviousVersion,
    role: [USER_ROLE.ADMIN],
  },
];
