import { isEmpty } from 'lodash';
import React from 'react';
import { Badge, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { GET_GENDER } from 'utils/constants';
import { printDate, showImage } from 'utils/helper';

const DoctorSummaryBlock = ({ doctorDetails }) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={2}>
            <div className="text-muted mb-2">Profile Image</div>
            {!isEmpty(doctorDetails?.profileImage) ? (
              <a target="_blank" rel="noreffer noreferrer" href={showImage(doctorDetails?.profileImage?.original)}>
                <img src={showImage(doctorDetails?.profileImage?.original)} width={50} height={50} />
              </a>
            ) : (
              '-'
            )}
          </Col>
          <Col md={2}>
            <div className="text-muted mb-2">Name</div>
            <CardTitle>
              {doctorDetails?.salutation} {doctorDetails?.firstName} {doctorDetails?.lastName}
            </CardTitle>
          </Col>
          <Col md={2}>
            <div className="text-muted mb-2">Gender</div>
            <CardTitle>{GET_GENDER[doctorDetails?.gender]}</CardTitle>
          </Col>
          <Col md={3}>
            <div className="text-muted mb-2">Area of expertise</div>
            <CardTitle>{doctorDetails?.areaOfExpertise || '-'}</CardTitle>
          </Col>
          <Col md={3}>
            <div className="text-muted mb-2">Experience</div>
            <CardTitle>{doctorDetails?.experience || '-'}</CardTitle>
          </Col>
        </Row>
        <Row className="my-3">
          <Col md={2}>
            <div className="text-muted mb-2">Created Date</div>
            <CardTitle>{printDate(doctorDetails?.createdDate)}</CardTitle>
          </Col>
          <Col md={2}>
            <div className="text-muted mb-2">Updated Date</div>
            <CardTitle>{printDate(doctorDetails?.updatedDate)}</CardTitle>
          </Col>
          <Col md={2}>
            <div className="text-muted mb-2">Active</div>
            <CardTitle>
              <CardTitle className={doctorDetails?.active ? 'text-success' : 'text-danger'}>
                {doctorDetails?.active ? 'Yes' : 'No'}
              </CardTitle>
            </CardTitle>
          </Col>
          <Col md={2}>
            <div className="text-muted mb-2">Deleted</div>
            <CardTitle className={doctorDetails?.deleted ? 'text-success' : 'text-danger'}>
              {doctorDetails?.deleted ? 'Yes' : 'No'}
            </CardTitle>
          </Col>
          <Col md={2}>
            <div className="text-muted mb-2">isChildRelated</div>
            <CardTitle className={doctorDetails?.isChildRelated ? 'text-success' : 'text-danger'}>
              {doctorDetails?.isChildRelated ? 'Yes' : 'No'}
            </CardTitle>
          </Col>
          <Col md={2}>
            <div className="text-muted mb-2">isVerified</div>
            <CardTitle className={doctorDetails?.isVerified ? 'text-success' : 'text-danger'}>
              {doctorDetails?.isVerified ? 'Yes' : 'No'}
            </CardTitle>
          </Col>
          {/* <Col md={2}>
            <div className="text-muted mb-2">isClaimed</div>
            <CardTitle className={doctorDetails?.isClaimed ? 'text-success' : 'text-danger'}>
              {doctorDetails?.isVerified ? 'Yes' : 'No'}
            </CardTitle>
          </Col> */}
        </Row>

        {!isEmpty(doctorDetails.slug) && (
          <Row>
            <Col md={12}>
              <div className="text-muted mb-2">Permalink</div>
              <div>
                <a
                  href={`${process.env.REACT_APP_THEPARENTZ_WEB_URL}/healthcare/${doctorDetails.slug}-d500${doctorDetails?.uid}`}
                  rel="noreferrer"
                  target="_blank">
                  <span className="text-normal text-primary">{`${process.env.REACT_APP_THEPARENTZ_WEB_URL}/healthcare/${doctorDetails.slug}-d500${doctorDetails?.uid}`}</span>
                </a>
              </div>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default DoctorSummaryBlock;
