import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Select from 'react-select';
import { Divider } from '@material-ui/core';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import confirm from 'reactstrap-confirm';
import {
  buildURL,
  checkIfValueExist,
  checkValueExist,
  printDateTimeTwoLines,
  printSerialNumbers,
  showImage,
} from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { ACTIVE_AND_DELETED_ARRAY, SORT_VALUES, SORT_VALUES_DATE, createdByTypeSelect } from '../../../utils/constants';
import { getCities, getCountries, getRegions, getSubAreas } from 'containers/school/actions';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { citiesSelector, countriesSelector, regionsSelector, subAreasSelector } from 'containers/school/selectors';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
});
const HealthCareFacilityList = () => {
  const { countries, regions, cities, subAreas } = useSelector(stateSelector);

  const router = useRouter();
  const dispatch = useDispatch();
  const history = useHistory();
  toast.configure();

  const [healthCarefacilityList, sethealthCarefacilityList] = useState([]);
  const [healthCarefacilityPagination, sethealthCarefacilityPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    country: null,
    region: null,
    city: null,
    subArea: null,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getFacilityRequest, { loading: getFacilityLoading }] = useRequest({
    url: `/healthcare/doctor/admin/healthcareFacility/search`,
    method: 'POST',
    onSuccess: (data) => {
      sethealthCarefacilityList(data?.results);
      sethealthCarefacilityPagination(data?.pagination);
    },
    onError: () => {
      sethealthCarefacilityList([]);
      sethealthCarefacilityPagination({});
    },
  });

  const [deleteHealthcareFacilityRequest, { loading: deleteHealthcareFacilityLoading }] = useRequest({
    method: 'DELETE',
    onSuccess: () => {
      handleChangePage(1);
      toast.success(`Success! Healthcare facility has been updated.`);
    },
  });
  const [updateHealthcareFacilityRequest, { loading: updateHealthcareFacilityLoading }] = useRequest({
    method: 'PUT',
    onSuccess: () => {
      handleChangePage(1);
      toast.success(`Success! Healthcare facility has been updated.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getFacilityRequest({
      body: {
        page,
        ...filters,
        ...SORT_VALUES_DATE,
        size: 20,
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        country: checkIfValueExist(filters.country?.slug),
        region: checkIfValueExist(filters.region?.slug),
        city: checkIfValueExist(filters.city?.slug),
        subArea: checkIfValueExist(filters.subArea?.slug),
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getFacilityRequest({
      body: {
        page: 1,
        size: 20,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        ...SORT_VALUES_DATE,
      },
    });
  };

  const handleDeleteHealthCareFacility = async (data) => {
    const result = await confirm({
      title: `${data?.deleted ? 'Undelete' : 'Delete'} Healthcare facility`,
      message: `Are you sure, you want to ${data?.deleted ? 'Undelete' : 'Delete'} this Healthcare facility?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      if (data?.deleted) {
        updateHealthcareFacilityRequest({
          url: `/healthcare/doctor/admin/healthcareFacility/${data?.id}`,
          body: {
            deleted: false,
          },
        });
      } else {
        deleteHealthcareFacilityRequest({
          url: `/healthcare/doctor/admin/healthcareFacility/${data.id}`,
        });
      }
    }
  };

  const handleActiveHealthCareFacility = async (data) => {
    if (data?.active) {
      updateHealthcareFacilityRequest({
        url: `/healthcare/doctor/admin/healthcareFacility/${data?.id}`,
        body: {
          active: false,
        },
      });
    } else {
      updateHealthcareFacilityRequest({
        url: `/healthcare/doctor/admin/healthcareFacility/${data?.id}`,
        body: {
          active: true,
        },
      });
    }
  };

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      setFilters({ ...filters, country: country, subArea: null, city: null, subArea: null });
    } else {
      setFilters({ ...filters, country: undefined, subArea: null, city: null, subArea: null });
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: true,
          region: region.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );

      setFilters({ ...filters, region: region, city: null, subArea: null });
    } else {
      setFilters({ ...filters, region: undefined, city: null, subArea: null });
    }
  };
  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      dispatch(getSubAreas.trigger({ level: 4, city: city.name, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));

      setFilters({ ...filters, city: city, subArea: null });
    } else {
      setFilters({ ...filters, city: undefined, subArea: null });
    }
  };
  const onSubAreaSelect = (subArea) => {
    if (!isEmpty(subArea)) {
      setFilters({ ...filters, subArea: subArea });
    } else {
      setFilters({ ...filters, subArea: undefined });
    }
  };

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getFacilityLoading || updateHealthcareFacilityLoading || deleteHealthcareFacilityLoading} />
        <Container fluid>
          <Breadcrumbs title="Healthcare facility" breadcrumbItem="Healthcare facility list" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Name</label>
                    <Input
                      type="text"
                      placeholder="name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <Label className="form-label">Country</Label>
                    <Select isClearable options={countries} value={filters.country} onChange={onCountrySelect} />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <Label className="form-label  ">Region/State</Label>
                    <Select
                      isClearable
                      placeholder="Select..."
                      value={filters.region}
                      onChange={onRegionSelect}
                      options={regions}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <Label className="form-label  ">City</Label>
                    <Select
                      isClearable
                      placeholder="Select..."
                      value={filters.city}
                      onChange={onCitySelect}
                      options={cities}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <Label className="form-label  ">SubArea</Label>
                    <Select
                      isClearable
                      placeholder="Select..."
                      value={filters.subArea}
                      onChange={onSubAreaSelect}
                      options={subAreas}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {healthCarefacilityPagination?.totalCount} Healthcare facility found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_HEALTH_CARE}>
                  <i className="mdi mdi-plus me-1" />
                  Add Healthcare facility
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-centered table-borderless ">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">S.No.</th>
                        <th scope="col"></th>
                        <th scope="col">Name</th>
                        <th scope="col">Country</th>
                        <th scope="col">Region</th>
                        <th scope="col">City</th>
                        <th scope="col">SubArea</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(healthCarefacilityList) ? (
                        healthCarefacilityList?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{printSerialNumbers(healthCarefacilityPagination)[index]}</td>
                              <td>
                                {!isEmpty(data?.images) && (
                                  <a target="_blank" href={showImage(data?.images[0]?.original)}>
                                    <img src={showImage(data?.images[0]?.original)} width={40} height={40} />
                                  </a>
                                )}
                              </td>

                              <td>
                                <Link
                                  to={buildURL(SMKLinks.EDIT_HEALTH_CARE, {
                                    id: data?.id,
                                  })}>
                                  {data?.name}
                                </Link>
                                {!isEmpty(data.name) && (
                                  <span className="ms-2">
                                    <Link
                                      target="_blank"
                                      to={buildURL(SMKLinks.HEALTH_CARE_DETAILS, {
                                        id: data?.id,
                                      })}>
                                      <i className="bx bx-link-external fs-4" />
                                    </Link>
                                  </span>
                                )}
                              </td>
                              <td>{data?.address?.country}</td>
                              <td>{data?.address?.region}</td>
                              <td>{data?.address?.city}</td>
                              <td>{data?.address?.subArea}</td>
                              <td>{printDateTimeTwoLines(data?.createdDate)}</td>
                              <td>
                                <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                  {data?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                  {data?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop " tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleActiveHealthCareFacility(data)}>
                                      <i
                                        className={`fas  ${
                                          data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                        }   m-1 pe-3`}
                                      />
                                      {data?.active ? 'Disable' : 'Enable'}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleDeleteHealthCareFacility(data)}>
                                      <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                      {data?.deleted ? 'Undelete' : 'Delete'}
                                    </DropdownItem>
                                    <Divider className="my-2" />

                                    <Link
                                      to={buildURL(SMKLinks.EDIT_HEALTH_CARE, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </Link>

                                    <Link
                                      to={buildURL(SMKLinks.HEALTH_CARE_DETAILS, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-eye text-primary m-1 pe-3 " />
                                        Details
                                      </DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={healthCarefacilityPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default HealthCareFacilityList;
