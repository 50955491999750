import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';

const MiniCards = (props) => {
  const { title, text, iconClass, textColor, iconBgColor, mdCol, removeIcon = false } = props;
  return (
    <>
      <Col md={`${mdCol ? mdCol : '4'}`}>
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted font-weight-medium">{title}</p>
                <h4 className={`mb-0 ${textColor}`}>{text || '-'}</h4>
              </div>
              {!removeIcon && (
                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                  <span className={`avatar-title rounded-circle  ${iconBgColor ? iconBgColor : 'bg-primary'}`}>
                    <i className={`bx ${iconClass} font-size-24`} />
                  </span>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

MiniCards.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  mdCol: PropTypes.number,
};

export default MiniCards;
