import { isEmpty } from 'lodash';
import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { getFormattedDate, getImageUrl, IMAGE_SIZES } from 'utils/helper';

const Comment = ({
  comment,
  nestedComments,
  toggleModal,
  handleDeleteComment,
  commentCounts,
  onLoadMoreNested,
  setUpdatedText,
  isNested = false,
}) => {
  const repliesCount = commentCounts?.count - nestedComments?.length;

  return (
    <Card className={`${isNested ? 'shadow-none border-top border-secondary rounded-0 mb-0' : 'mb-3'}`}>
      <CardBody>
        <div className="d-md-flex align-items-center justify-content-between mb-3">
          <div>
            <div className="d-flex">
              {!isEmpty(comment?.createdBy?.profileImage) ? (
                <img
                  src={getImageUrl(comment?.createdBy?.profileImage, IMAGE_SIZES.thumbnailSmall)}
                  alt={comment?.createdBy?.displayName}
                  className="d-flex align-items-center justify-content-center border border-secondary rounded-circle mb-0"
                  style={{ width: '40px', height: '40px' }}
                />
              ) : (
                <figure
                  className="d-flex align-items-center justify-content-center border border-secondary rounded-circle mb-0"
                  style={{ width: '40px', height: '40px' }}>
                  <span>{comment?.createdBy?.displayName?.slice(0, 2).toUpperCase()}</span>
                </figure>
              )}
              <div className="ms-3">
                <h6>{comment?.createdBy?.displayName}</h6>
                <p>{getFormattedDate(comment?.createdDate)}</p>
              </div>
            </div>
          </div>
          <div>
            <Button
              size="sm"
              color="light"
              className="me-3"
              onClick={() => {
                setUpdatedText(comment?.content);
                toggleModal(comment);
              }}>
              Edit
            </Button>
            <Button size="sm" outline color="danger" onClick={() => handleDeleteComment(comment)}>
              Delete
            </Button>
          </div>
        </div>

        <div className="mb-0 comment_html_content p" dangerouslySetInnerHTML={{ __html: comment?.content }} />

        <div className="ms-4 mt-3">
          {!isEmpty(nestedComments) &&
            nestedComments
              ?.sort((a, b) => new Date(b?.createdDate) - new Date(a?.createdDate))
              ?.map((reply) => (
                <div className="border-start border-secondary">
                  <Comment
                    key={reply?.id}
                    isNested={true}
                    comment={reply}
                    replies={reply?.nestedComments}
                    toggleModal={toggleModal}
                    setUpdatedText={setUpdatedText}
                    handleDeleteComment={handleDeleteComment}
                    onLoadMoreNested={() => {
                      onLoadMoreNested(1);
                    }}
                  />
                </div>
              ))}

          {repliesCount > 0 && (
            <div className="mt-3">
              <button onClick={() => onLoadMoreNested()} className="btn btn-link p-0 load-more-link text-primary">
                Load previous {repliesCount == '1' ? 'reply' : 'replies'} ( {repliesCount} )
              </button>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default Comment;
