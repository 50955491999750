import 'flatpickr/dist/themes/material_blue.css';
import React from 'react';
import Flatpickr from 'react-flatpickr';

const TimingsTableRows = ({ rows, tableRowRemove, addRowInTimings, setAddRowInTimings, weekDay }) => {
  return rows?.map((rowData, index) => (
    <tr key={index}>
      <td colSpan={3}></td>
      <td className="bg-light" style={{ verticalAlign: 'middle' }}>
        Morning
      </td>
      <td className="bg-light">
        <Flatpickr
          className="form-control d-block w-100"
          placeholder="Select time"
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
            time_24hr: false,
          }}
          value={rowData.startTime}
          onChange={(e) => {
            const updatedRows = [...rows];
            updatedRows[index].startTime = e[0]; // Save start time
            setAddRowInTimings({
              ...addRowInTimings,
              [weekDay]: updatedRows,
            });
          }}
        />
      </td>
      <td className="bg-light" style={{ verticalAlign: 'middle' }}>
        To
      </td>
      <td className="bg-light">
        <Flatpickr
          className="form-control d-block w-100"
          placeholder="Select time"
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
            time_24hr: false,
          }}
          value={rowData.endTime}
          onChange={(e) => {
            const updatedRows = [...rows];
            updatedRows[index].endTime = e[0]; // Save end time
            setAddRowInTimings({
              ...addRowInTimings,
              [weekDay]: updatedRows,
            });
          }}
        />
      </td>
      <td className="bg-light" style={{ verticalAlign: 'middle' }}>
        <button onClick={() => tableRowRemove(index, weekDay)} className="btn bg-white rounded-circle">
          <i className="bx bx-minus" />
        </button>
      </td>
    </tr>
  ));
};
export default TimingsTableRows;
