import DynamicSelect from 'components/DynamicSelect';
import React from 'react';
import { Button, Card, CardBody, Form } from 'reactstrap';

const UpdateDoctorFields = ({ doctorDetails, formik, keyName, label }) => {
  return (
    <Card className="mb-5">
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <DynamicSelect formik={formik} keyName={keyName} label={label} doctorDetails={doctorDetails} />
          <div className="mt-5">
            <Button type="submit" color="primary">
              Update Doctor
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};
export default UpdateDoctorFields;
