import { useState } from 'react';
import { getCurrentUserData } from 'utils/helper';
import config from '../config/config';
import { api } from '../utils/axios';
import { isEmpty } from 'lodash';

const useRequest = ({ method, url, headers, body: data, onSuccess, onError }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const doRequest = async (overrideOptions) => {
    try {
      setLoading(true);

      const { apiKey } = await getCurrentUserData();
      const healthcareAPIKey = (overrideOptions?.url || url)?.includes('/healthcare')
        ? process.env.REACT_APP_HEALTHCARE_API_KEY
        : '';

      const axiosConfig = {
        method,
        data,
        ...overrideOptions,
        url: `${config.apiEndpoint}${overrideOptions?.url || url}`,
      };

      axiosConfig.headers = {
        ...overrideOptions?.headers,
        'x-api-key': !isEmpty(healthcareAPIKey) ? healthcareAPIKey : apiKey,
        ...headers,
      };

      if (overrideOptions?.body) {
        axiosConfig.data = overrideOptions.body;
      }

      const response = await api(axiosConfig, axiosConfig?.headers);

      // if (url.indexOf('/v1/') < 0) {
      //   response = response?.data;
      // }

      // console.log({ response });

      if (response.success === false) {
        console.log('Error found!');
        if (onError) {
          onError(response);
        }
      } else if (onSuccess) {
        onSuccess(response);
      }

      return response;
    } catch (err) {
      console.log({ err });
      const { response, message } = err;

      if (response) {
        setError(response);
        if (onError) {
          onError(response);
        }
      } else {
        setError({ message });
        if (onError) {
          onError({ message });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return [doRequest, { loading, error }];
};

export default useRequest;
