import { useLazyQuery } from '@apollo/client';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import MiniCards from 'components/MiniCard/mini-card';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { showImage } from 'utils/helper';
import { COMMUNITY_GROUPS_GET_BY_ID } from '../graphql/community.query';
import ContributorsList from './contributors';
import PostsList from './posts';
import ReportedPostsList from './posts/reportedPostsList';

const CommunityGroupDetails = () => {
  const { query } = useRouter();
  toast.configure();

  const tabsList = ['Contributors', 'Post', 'Reported Posts'];

  const [activeTab, setActiveTab] = useState('1');
  const [groupDetails, setGroupDetails] = useState({});

  const [getCommunityDetails, { loading: getCommunityDetailsLoading }] = useLazyQuery(COMMUNITY_GROUPS_GET_BY_ID, {
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_community_group_getById)) {
        setGroupDetails(data?.admin_community_group_getById);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const miniCards = [
    {
      title: 'Posts',
      text: `${groupDetails?.stats?.postCount} `,
    },
    {
      title: 'Comments',
      text: `${groupDetails?.stats?.commentCount}`,
    },
    {
      title: 'Reactions',
      text: `${groupDetails?.stats?.reactionCount}`,
    },
    {
      title: 'isActive',
      text: `${groupDetails?.isActive ? 'Yes' : 'No'} `,
      textColor: `${groupDetails?.isActive ? 'text-success' : 'text-danger'}`,
    },
  ];

  useEffect(() => {
    getCommunityDetails({
      variables: {
        groupId: query?.id,
      },
    });
  }, []);

  return (
    <div className="page-content">
      <Loader isActive={getCommunityDetailsLoading} />

      <Container fluid>
        <Breadcrumbs title="Community Group" breadcrumbItem="Community Group Details" />

        <Row>
          <Col xl="4">
            <Card className="overflow-hidden">
              <CardBody className="p-3">
                <Row className="align-items-center">
                  <Col sm="2">
                    <div className="avatar-md profile-user-wid m-auto d-flex align-items-center justify-content-center">
                      {!isEmpty(groupDetails?.image) ? (
                        <img src={showImage(groupDetails?.image?.original)} className="img-thumbnail rounded-circle" />
                      ) : (
                        <i className="bx bxs-message-square-detail" style={{ fontSize: '50px', color: '#3460BB' }} />
                      )}
                    </div>
                  </Col>
                  <Col sm="9" className="ms-3">
                    <h5 className="font-size-15 text-truncate mb-1">{groupDetails?.name}</h5>
                    <p className="text-muted mb-0 text-truncate">{groupDetails?.slug}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xl="8">
            <Row>
              {map(miniCards, (card, key) => (
                <MiniCards
                  mdCol={3}
                  title={card?.title}
                  text={card?.text}
                  textColor={card?.textColor}
                  iconClass={card?.iconClass}
                  key={`_card_${key}`}
                  removeIcon={true}
                />
              ))}
            </Row>
          </Col>

          <div>
            <Nav tabs>
              {tabsList?.map((tab, index) => (
                <NavItem key={`_test_${index}`}>
                  <NavLink
                    className={activeTab === (index + 1).toString() ? 'active' : ''}
                    onClick={() => {
                      setActiveTab((index + 1).toString());
                    }}>
                    {tab}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <ContributorsList />
              </TabPane>
              <TabPane tabId="2">
                <PostsList />
              </TabPane>
              <TabPane tabId="3">
                <ReportedPostsList />
              </TabPane>
            </TabContent>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default CommunityGroupDetails;
